import { useState } from 'react'
import WeeklyPredictionBannerSvg from '../images/WeeklyPrediction/weekly-prediction-banner.svg'
import { Link } from 'react-router-dom'
import {
  Category,
  Test,
  Status,
  QuestionModel,
  SkillName,
} from '../models/QuestionModels'

const categories: Category[] = [
  {
    name: SkillName.Reading,
    questionModels: [
      QuestionModel.ReadAloud,
      QuestionModel.RepeatSentences,
      QuestionModel.DescribeImages,
    ],
  },
  {
    name: SkillName.Listening,
    questionModels: [
      QuestionModel.SummarizeWrittenText,
      QuestionModel.WriteEssay,
    ],
  },
  {
    name: SkillName.Speaking,
    questionModels: [
      QuestionModel.MultipleChoiceChooseSingleAnswer,
      QuestionModel.MultipleChoiceChooseMultipleAnswers,
      QuestionModel.ReorderParagraphs,
      QuestionModel.ReadingFillInTheBlanks,
      QuestionModel.ReadingAndWritingFillInTheBlanks,
    ],
  },
  {
    name: SkillName.Writing,
    questionModels: [
      QuestionModel.SummarizeSpokenText,
      QuestionModel.MultipleChoiceChooseMultipleAnswers,
      QuestionModel.FillInTheBlanks,
      QuestionModel.HighlightCorrectSummary,
      QuestionModel.MultipleChoiceChooseSingleAnswer,
      QuestionModel.SelectMissingWord,
      QuestionModel.HighlightIncorrectWords,
      QuestionModel.WriteFromDictation,
    ],
  },
]

const speakingTests: Test[] = [
  {
    name: QuestionModel.ReadAloud,
    zone: SkillName.Speaking,
    amount: 10,
    status: Status.TokenRequired,
  },
  {
    name: QuestionModel.RepeatSentences,
    zone: SkillName.Speaking,
    amount: 5,
    status: Status.FreeCourse,
  },
  {
    name: QuestionModel.DescribeImages,
    zone: SkillName.Speaking,
    amount: 8,
    status: Status.SoldOut,
  },
  {
    name: QuestionModel.RetellLectures,
    zone: SkillName.Speaking,
    amount: 12,
    status: Status.Available,
  },
  {
    name: QuestionModel.AnswerShortQuestions,
    zone: SkillName.Speaking,
    amount: 15,
    status: Status.Available,
  },
  {
    name: QuestionModel.ReadAloud,
    zone: SkillName.Speaking,
    amount: 10,
    status: Status.TokenRequired,
  },
  {
    name: QuestionModel.RepeatSentences,
    zone: SkillName.Speaking,
    amount: 5,
    status: Status.FreeCourse,
  },
  {
    name: QuestionModel.DescribeImages,
    zone: SkillName.Speaking,
    amount: 8,
    status: Status.SoldOut,
  },
  {
    name: QuestionModel.RetellLectures,
    zone: SkillName.Speaking,
    amount: 12,
    status: Status.FreeCourse,
  },
  {
    name: QuestionModel.AnswerShortQuestions,
    zone: SkillName.Speaking,
    amount: 15,
    status: Status.Available,
  },
  {
    name: QuestionModel.ReadAloud,
    zone: SkillName.Speaking,
    amount: 10,
    status: Status.Available,
  },
  {
    name: QuestionModel.RepeatSentences,
    zone: SkillName.Speaking,
    amount: 5,
    status: Status.TokenRequired,
  },
  {
    name: QuestionModel.DescribeImages,
    zone: SkillName.Speaking,
    amount: 8,
    status: Status.TokenRequired,
  },
  {
    name: QuestionModel.RetellLectures,
    zone: SkillName.Speaking,
    amount: 12,
    status: Status.FreeCourse,
  },
  {
    name: QuestionModel.AnswerShortQuestions,
    zone: SkillName.Speaking,
    amount: 15,
    status: Status.FreeCourse,
  },
]

const readingTests: Test[] = [
  {
    name: QuestionModel.ReadingMultipleChoiceChooseSingleAnswer,
    zone: SkillName.Reading,
    amount: 10,
    status: Status.TokenRequired,
  },
  {
    name: QuestionModel.ReadingMultipleChoiceChooseMultipleAnswers,
    zone: SkillName.Reading,
    amount: 5,
    status: Status.FreeCourse,
  },
  {
    name: QuestionModel.ReorderParagraphs,
    zone: SkillName.Reading,
    amount: 8,
    status: Status.SoldOut,
  },
  {
    name: QuestionModel.ReadingFillInTheBlanks,
    zone: SkillName.Reading,
    amount: 12,
    status: Status.Available,
  },
  {
    name: QuestionModel.ReadingAndWritingFillInTheBlanks,
    zone: SkillName.Reading,
    amount: 15,
    status: Status.Available,
  },
  {
    name: QuestionModel.ReadingMultipleChoiceChooseSingleAnswer,
    zone: SkillName.Reading,
    amount: 10,
    status: Status.TokenRequired,
  },
  {
    name: QuestionModel.ReadingMultipleChoiceChooseMultipleAnswers,
    zone: SkillName.Reading,
    amount: 5,
    status: Status.FreeCourse,
  },
  {
    name: QuestionModel.ReorderParagraphs,
    zone: SkillName.Reading,
    amount: 8,
    status: Status.SoldOut,
  },
  {
    name: QuestionModel.ReadingFillInTheBlanks,
    zone: SkillName.Reading,
    amount: 12,
    status: Status.FreeCourse,
  },
  {
    name: QuestionModel.ReadingAndWritingFillInTheBlanks,
    zone: SkillName.Reading,
    amount: 15,
    status: Status.Available,
  },
  {
    name: QuestionModel.ReadingMultipleChoiceChooseSingleAnswer,
    zone: SkillName.Reading,
    amount: 10,
    status: Status.Available,
  },
  {
    name: QuestionModel.ReadingMultipleChoiceChooseMultipleAnswers,
    zone: SkillName.Reading,
    amount: 5,
    status: Status.TokenRequired,
  },
  {
    name: QuestionModel.ReorderParagraphs,
    zone: SkillName.Reading,
    amount: 8,
    status: Status.TokenRequired,
  },
  {
    name: QuestionModel.ReadingFillInTheBlanks,
    zone: SkillName.Reading,
    amount: 12,
    status: Status.FreeCourse,
  },
]

const listeningTests: Test[] = [
  {
    name: QuestionModel.SummarizeSpokenText,
    zone: SkillName.Listening,
    amount: 8,
    status: Status.Available,
  },
  {
    name: QuestionModel.MultipleChoiceChooseMultipleAnswers,
    zone: SkillName.Listening,
    amount: 6,
    status: Status.TokenRequired,
  },
  {
    name: QuestionModel.FillInTheBlanks,
    zone: SkillName.Listening,
    amount: 10,
    status: Status.FreeCourse,
  },
  {
    name: QuestionModel.HighlightIncorrectWords,
    zone: SkillName.Listening,
    amount: 7,
    status: Status.SoldOut,
  },
  {
    name: QuestionModel.SelectMissingWord,
    zone: SkillName.Listening,
    amount: 5,
    status: Status.Available,
  },
  {
    name: QuestionModel.WriteFromDictation,
    zone: SkillName.Listening,
    amount: 12,
    status: Status.FreeCourse,
  },
]

const writingTests: Test[] = [
  {
    name: QuestionModel.SummarizeWrittenText,
    zone: SkillName.Writing,
    amount: 6,
    status: Status.Available,
  },
  {
    name: QuestionModel.WriteEssay,
    zone: SkillName.Writing,
    amount: 4,
    status: Status.TokenRequired,
  },
  {
    name: QuestionModel.FillInTheBlanks,
    zone: SkillName.Writing,
    amount: 9,
    status: Status.FreeCourse,
  },
  {
    name: QuestionModel.HighlightCorrectSummary,
    zone: SkillName.Writing,
    amount: 7,
    status: Status.SoldOut,
  },
  {
    name: QuestionModel.MultipleChoiceChooseSingleAnswer,
    zone: SkillName.Writing,
    amount: 5,
    status: Status.Available,
  },
  {
    name: QuestionModel.MultipleChoiceChooseMultipleAnswers,
    zone: SkillName.Writing,
    amount: 10,
    status: Status.FreeCourse,
  },
]

const MobileCourseList = () => {
  const itemsPerPage = 5

  const [activeCategory, setActiveCategory] = useState('All')
  const [currentPage, setCurrentPage] = useState(1)
  let currentItems = []

  switch (activeCategory) {
    case SkillName.Speaking:
      currentItems = speakingTests
      break
    case SkillName.Reading:
      currentItems = readingTests
      break
    case SkillName.Listening:
      currentItems = listeningTests
      break
    case SkillName.Writing:
      currentItems = writingTests
      break
    default:
      currentItems = [
        ...speakingTests,
        ...readingTests,
        ...listeningTests,
        ...writingTests,
      ]
      break
  }
  const totalPages = Math.ceil(currentItems.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  currentItems = currentItems.slice(startIndex, startIndex + itemsPerPage)

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  return (
    <div className="">
      <div className="relative bg-primary-9 min-h-[320px] md:min-h-[400px] text-center rounded-t-lg">
        <p className="text-h1 text-primary-1 pt-12">Practice Test</p>
        <p className="text-bodyr text-primary-2 mx-4 lg:mx-[32%] pt-4">
          Mattis rhoncus urna neque viverra justo. Ullamcorper dignissim cras
          tincidunt lobortis feugiat vivamus. Neque vitae tempus quam
          pellentesque nec. Mi bibendum neque egestas congue quisque egestas
          diam in.
        </p>

        <div className="absolute inset-0 flex justify-center items-center top-[100%] mx-4 lg:m-0">
          <img src={WeeklyPredictionBannerSvg} alt="Speaking Banner" />
        </div>
      </div>

      <div className="mt-[20vw] sm:mt-48 mr-4">
        <p className="text-h5m uppercase">Zone</p>
        <div className="mt-4">
          <select
            className="p-2 rounded-lg border border-[1px]-[#DFE3E6] w-[80%] sm:w-[50%]"
            onChange={(e) => setActiveCategory(e.target.value)}
          >
            <option value="all">All</option>
            {categories.map((category, index) => (
              <option key={index} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {currentItems.map((test, index) => (
        <div
          key={index}
          className={`flex justify-between border border-[1px]-[#DFE3E6] rounded-lg py-4 mr-4 text-center items-center my-8  ${
            index % 5 === 0
              ? 'shadow-[6px_6px_0_rgba(255,186,104,1)]'
              : index % 5 === 1
                ? 'shadow-[6px_6px_0_rgba(36,69,244,1)]'
                : index % 5 === 2
                  ? 'shadow-[6px_6px_0_rgba(52,244,36,1)]'
                  : index % 5 === 3
                    ? 'shadow-[6px_6px_0_rgba(244,36,36,1)]'
                    : index % 5 === 4
                      ? 'shadow-[6px_6px_0_rgba(178,36,244,1)]'
                      : ''
          }`}
        >
          <div className="flex flex-col gap-2 pl-2">
            <p className="col-span-1 w-[90%] sm:w-full text-h5b uppercase text-left">
              {test.name}
            </p>
            <Link to="/weekly-prediction/course" className='flex'>
              <button
                className="col-span-1 text-bodyr text-neutrals-1 uppercase border border-[1px]-[#D9D9D9] rounded-lg p-2 w-fit hover:scale-105 self-start"
                onClick={() => {
                  localStorage.setItem('questionTypePTE', test.name)
                }}
              >
                Practice Now
              </button>
            </Link>
          </div>
          <div className="flex flex-col gap-2 pr-2">
            <p
              className={`col-span-1 text-footnote px-2 py-1 w-[100px] rounded-full
        ${
          test.status === Status.TokenRequired
            ? 'bg-[#F5D90A] text-black'
            : test.status === Status.FreeCourse
              ? 'bg-[#52C41A] text-white'
              : test.status === Status.SoldOut
                ? 'bg-[#FF4D4F] text-white'
                : test.status === Status.Available
                  ? 'bg-[#1890FF] text-white'
                  : 'bg-blue-500 text-white'
        }
        `}
            >
              {test.status}
            </p>
            <p className="col-span-1 text-footnote text-[#858585] uppercase text-left">
              {test.zone}
            </p>
            <p className="col-span-1 text-footnote text-[#858585] uppercase text-left">
              {test.amount} Quesions
            </p>
          </div>
        </div>
      ))}
      <div className="flex w-[50%] md:w-[20%] m-auto justify-evenly items-center my-4">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="hover:text-info"
        >
          {'<'}
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`${currentPage === index + 1 ? 'text-info' : 'text-gray-700'}`}
            onClick={() => goToPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="hover:text-info"
        >
          {'>'}
        </button>
      </div>
    </div>
  )
}

export default MobileCourseList
