import { ReactComponent as MyTestPerformanceIcon } from '../../images/MyPerformance/mp-my-test-performance-icon.svg'
import MyPerformance from '../../models/myPerformance.model'

interface TestPerformanceProps {
  data: MyPerformance[]
}

const TestPerformanceSection: React.FC<TestPerformanceProps> = ({ data }) => {
  const totalAttempt = data.length
  const sumOverallTotal = data.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.overalltotal)
  }, 0)
  const overallScore = Math.round(sumOverallTotal / totalAttempt)

  const trending = (): string => {
    const firstMockTest = Math.round(
      parseFloat(data[totalAttempt - 1].overalltotal),
    )

    if (totalAttempt > 0) {
      if (overallScore > firstMockTest) {
        return 'Improving'
      } else if (overallScore < firstMockTest) {
        return 'Not Improving'
      } else if (overallScore === firstMockTest) {
        return 'Normal'
      }
    } else {
      return 'N/A yet'
    }

    return 'Undefined'
  }

  const recentTrending = (): string => {
    if (totalAttempt >= 3) {
      const firstMocktest2 = Math.round(
        parseFloat(data[totalAttempt - 1].overalltotal),
      )

      let sum = 0
      for (let i = 0; i <= 2; i++) {
        sum += parseFloat(data[i].overalltotal)
      }
      const overallRecentScore = Math.round(sum / 3)
      if (overallRecentScore > firstMocktest2) {
        return 'Improving'
      } else if (overallRecentScore < firstMocktest2) {
        return 'Not Improving'
      } else if (overallRecentScore === firstMocktest2) {
        return 'Normal'
      }
    } else {
      return 'N/A yet'
    }

    return 'Undefined'
  }
  const sumReadingScore = data.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.score_reading_section)
  }, 0)
  const sumSpeakingScore = data.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.score_speakinig_section)
  }, 0)
  const sumListeningScore = data.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.score_listening_section)
  }, 0)
  const sumWritingScore = data.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.score_writing_section)
  }, 0)

  const overallReadingScore = Math.round(sumReadingScore / totalAttempt)
  const overallListeningScore = Math.round(sumListeningScore / totalAttempt)
  const overallSpeakingScore = Math.round(sumSpeakingScore / totalAttempt)
  const overallWritingScore = Math.round(sumWritingScore / totalAttempt)

  const testPerformanceData = [
    {
      title: 'Attempts',
      value: totalAttempt,
    },
    {
      title: 'All-time trend',
      value: trending(),
    },
    {
      title: 'Recent-test trend',
      value: recentTrending(),
    },
    {
      title: 'Overall score',
      value: overallScore,
    },
    {
      title: 'Reading score',
      value: overallReadingScore,
    },
    {
      title: 'Writing score',
      value: overallWritingScore,
    },
    {
      title: 'Listening score',
      value: overallListeningScore,
    },
    {
      title: 'Speaking score',
      value: overallSpeakingScore,
    },
  ]

  return (
    <div className="my-performance__test-performance-section w-full font-landingPrimary lg:mt-[4rem] mt-[2rem]">
      <div className="test-performance-section__title flex gap-2 items-center mb-[2rem]">
        <MyTestPerformanceIcon />
        <p className="text-h4m">My Test Performance</p>
      </div>
      {/* PC screen */}
      <div className="hidden lg:block test-performance-section__details--pc w-full mt-[1rem]">
        <div className="test-performance-section__details__title--lg w-full h-18 mt-4 grid grid-cols-8 gap-2">
          {testPerformanceData.map((test) => (
            <div
              key={test.title}
              className="details__title__item--lg text-h5r text-neutrals-2 text-center flex items-center justify-center"
            >
              {test.title}
            </div>
          ))}
        </div>
        <div className="test-performance-section__details__value--lg w-full h-14 mt-[1rem] bg-gray-100 border border-gray-200 rounded-xl grid grid-cols-8 gap-2">
          {testPerformanceData.map((test) => (
            <div
              key={test.title}
              className="details__value__item--lg text-bodyr text-neutrals-1 text-center flex items-center justify-center"
            >
              {test.value}
            </div>
          ))}
        </div>
      </div>
      {/* Mobile screen*/}
      <div className="block test-performance-section__details--mb lg:hidden w-full mt-[1rem]">
        <div className="grid grid-cols-2 gap-4">
          {testPerformanceData.map((test) => (
            <div
              key={test.title}
              className="bg-gray-100 border border-gray-200 rounded-xl p-4 flex flex-col items-center gap-1"
            >
              <div className="text-h5r text-neutrals-2">{test.title}</div>
              <div className="text-bodyr text-neutrals-1">{test.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TestPerformanceSection
