const sampleData = [
  {
    id: 1,
    test: 'Read Aloud',
    answer:
      'The researchers enlisted real mothers and infants and quantified their interactions, which fell into four categories. One: babies wanted to maximise the amount of time smiling at their mothers. Two: they wanted to maximise the time the mothers smiled at them. Three: they wanted to experience simultaneous smiling, and four: no smiling at all.',
    yourAnswer:
      "The researcher insulates real mother and infant. And what did they find? Their interaction, which fell into four categories. One, baby wanted to maximize the amount of time smiling at their mother to they wanted to maximize the time the mother's mind at them. Three, they wanted to.Experience the simulating a smiling and fall, not smiling at all.",
    fluency: '0/5',
    pronunciation: '3.45/5',
    content: '3.15/5',
    overallScore: '6.6/15',
  },
  {
    id: 2,
    test: 'Read Aloud',
    answer:
      'The initial thud comes from when those dorsal bursae collide. And the reverberation results from the vibrations that linger when the tissues pull apart. But Thode the younger says the bursae have to be somewhat sticky for the clapping together and snapping apart to produce a noise with the correct loudness and pitch. That stickiness comes courtesy of the mucus.',
    yourAnswer:
      'The initial tests come from window, those particularly and the reverberation result for the vibration. That language, the dysfunctional part pushed all the younger say the person had to be somewhat sticky for the club together and snapping apart to reduce.And now with the correct loudness and pitch. Thus thickness come courtesy of the Mascus.',
    fluency: '0/5',
    pronunciation: '3.09/5',
    content: '2.92/5',
    overallScore: '6.01/15',
  },
  {
    id: 3,
    test: 'Repeat Sentence',
    answer: 'You can get a student card at the reception',
    yourAnswer: 'You can get student car at the exception.',
    fluency: '0.30/5',
    pronunciation: '3.75/5',
    content: '2/3',
    overallScore: '6.05/13',
  },
  {
    id: 4,
    test: 'Describe Image',
    answer:
      'annual, average, temperature, southeastern, united, states, 1895, 2005, 61, 65,',
    yourAnswer: `This checks so the <b style='background-color: aqua;'>temperature</b> up at the end of every <b style='background-color: aqua;'>temperature</b> in the southern <b style='background-color: aqua;'>United</b> <b style='background-color: aqua;'>States</b> and it showed that the the lowest <b style='background-color: aqua;'>temperature</b> is around <b style='background-color: aqua;'>6061</b>° at a higher is.From <b style='background-color: aqua;'>65</b>° pole.`,
    fluency: '0/5',
    pronunciation: '1.25/5',
    content: '2.50/5',
    overallScore: '3.75/15',
  },
  {
    id: 5,
    test: 'Re-tell Lecture',
    answer:
      'knee, cracking, regular, medical, flexing evaluation, sensation, doctor, clinical, symptoms, arthritis, patellar, motion,',
    yourAnswer: 'I have no idea.',
    fluency: '0/5',
    pronunciation: '0/5',
    content: '0/5',
    overallScore: '0/15',
  },
  {
    id: 6,
    test: 'Answer Short Question',
    answer: 'green light,',
    yourAnswer: 'Green light.',
    fluency: '0',
    pronunciation: '0',
    content: '1/1',
    overallScore: '1/1',
  },
]

const HistoryDetailsSpeakingMock = () => {
  // Function to create markup for dangerouslySetInnerHTML
  const createMarkup = (html: string) => {
    return { __html: html }
  }

  return (
    <div className="history-details-speaking w-full h-full mt-[1rem] p-2 overflow-x-auto font-landingPrimary">
      <div className="min-w-full inline-block align-middle overflow-hidden">
        <table className="min-w-full table-fixed">
          <thead>
            <tr className="text-bodyr text-neutrals-2 h-[3.75rem]">
              <th className="w-[8rem] p-2" align="left">
                Test
              </th>
              <th className="w-[20rem] p-2" align="left">
                Answer
              </th>
              <th className="w-[20rem] p-2" align="left">
                Your Answer
              </th>
              <th className="w-[5.2rem] p-2" align="left">
                Fluency
              </th>
              <th className="w-[5.2rem] p-2" align="left">
                Pronunciation
              </th>
              <th className="w-[5.2rem] p-2" align="left">
                Content
              </th>
              <th className="w-[5.2rem] p-2" align="left">
                Overall Score
              </th>
            </tr>
          </thead>
          <tbody>
            {sampleData.map((data, index) => (
              <tr
                key={index}
                className={`text-bodyr ${index % 2 === 0 ? 'bg-[#FAFAFA]' : ''}`}
              >
                <td className="p-2 text-bodym rounded-l-xl">{data.test}</td>
                <td className="p-2 text-bodyr">{data.answer}</td>
                <td
                  className="p-2 text-bodyr"
                  dangerouslySetInnerHTML={createMarkup(data.yourAnswer)}
                ></td>
                <td className="p-2 text-bodyr">{data.fluency} </td>
                <td className="p-2 text-bodyr">{data.pronunciation} </td>
                <td className="p-2 text-bodyr"> {data.content} </td>
                <td className="p-2 text-bodyr rounded-r-xl">
                  {data.overallScore}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default HistoryDetailsSpeakingMock
