import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  ReactNode,
  useCallback,
} from 'react'
import { useAuth } from './AuthProvider'
import { v4 as uuidv4 } from 'uuid'
import { toastError } from '../utils/utils'

// Define the shape of the WebSocket context
interface WebSocketContextType {
  sendUniqueCode: (userId: number, uniqueCode: string) => void
  publicMessages: any[] // Store messages received from the WebSocket
  setPublicMessages: (messages: any[]) => void
  sendPublicMessage: (message: string, image?: string) => void
  singleMessages: any[] // Store messages received from the WebSocket
  setSingleMessages: (messages: any[]) => void
  sendSingleMessage: (
    message: string,
    receiverId: number,
    image?: string,
  ) => void
}

export enum ActionType {
  UserOnline = 'UserOnline',
  UserOffline = 'UserOffline', // received when a user goes offline
  SingleMessage = 'SingleMessage',
  PTEMessageGroup = 'PTEMessageGroup',
  PublicMessage = 'PublicMessage',
  GroupMessage = 'GroupMessage',
  UniqueCode = 'UniqueCode',
  SessionMessage = 'SessionMessage',
  PTEAdminMessage = 'PTEAdminMessage',
  PTEMessage = 'PTEMessage',
}

// Create the WebSocket context with default values
const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined,
)

// WebSocketProvider component to manage the WebSocket connection and actions
interface WebSocketProviderProps {
  url: string // WebSocket server URL
  children: ReactNode // Components that can consume the WebSocket context
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  url,
  children,
}) => {
  const socketRef = React.useRef<WebSocket | null>(null)
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [publicMessages, setPublicMessages] = useState<any[]>([]) // Store incoming messages
  const [singleMessages, setSingleMessages] = useState<any[]>([]) // Store incoming messages
  const { userProfile, token, logout } = useAuth()

  const sendUserOnline = useCallback(() => {
    if (!socketRef.current) {
      console.log('Socket is not connected')
      return
    }

    if (socketRef.current.readyState !== WebSocket.OPEN) {
      console.log('Socket is not open')
      return
    }

    if (userProfile && userProfile.userId) {
      const data = {
        action: 'live-chat',
        actionType: ActionType.UserOnline,
        userId: userProfile.userId,
      }
      console.log('Sending user online:', data)
      socketRef.current.send(JSON.stringify(data))
    } else {
      console.log('userId is missing')
    }
  }, [userProfile])

  // Send public message
  const sendPublicMessage = (message: string, image?: string) => {
    if (!socketRef.current) {
      console.log('Socket is not connected')
      return
    }

    if (socketRef.current.readyState !== WebSocket.OPEN) {
      console.log('Socket is not open')
      return
    }

    if (message.trim() && userProfile) {
      const data = {
        action: 'live-chat',
        actionType: ActionType.PublicMessage,
        userFrom: userProfile.userId,
        message,
        userFromName: userProfile.name,
        userFromImage: userProfile.image,
        type: 0,
        image: image || '',
        groupId: 0,
      }
      console.log('Sending message:', data)
      socketRef.current.send(JSON.stringify(data))
    }
  }

  const sendSingleMessage = (
    message: string,
    receiverId: number,
    image?: string,
  ) => {
    if (!socketRef.current) {
      console.log('Socket is not connected')
      return
    }

    if (socketRef.current.readyState !== WebSocket.OPEN) {
      console.log('Socket is not open')
      return
    }

    if (message.trim() && userProfile) {
      const data = {
        action: 'live-chat',
        actionType: ActionType.SingleMessage,
        userFrom: userProfile.userId,
        message,
        userFromName: userProfile.name,
        userFromImage: userProfile.image,
        type: 0,
        image: image || '',
        groupId: 0,
        userTo: receiverId,
      }
      console.log('Sending message:', data)
      socketRef.current.send(JSON.stringify(data))
    }
  }

  // Send unique code
  const sendUniqueCode = useCallback((userId: number, uniqueCode: string) => {
    // console.log('sendUniqueCode')
    if (!socketRef.current) {
      console.log('Socket is not connected')
      return
    }

    if (socketRef.current.readyState !== WebSocket.OPEN) {
      console.log('Socket is not open')
      return
    }

    if (uniqueCode.trim()) {
      const data = {
        action: 'live-chat',
        actionType: ActionType.UniqueCode,
        userId,
        uniqueCode,
      }
      console.log('Sending unique code:', data)
      socketRef.current.send(JSON.stringify(data))
    }
  }, [])

  const initSocket = useCallback(() => {
    if (!url) {
      console.log('WebSocket URL is missing')
      return
    }

    if (!userProfile || !userProfile.userId) {
      console.log('User profile is missing')
      return
    }

    // reset socketRef if it's already connected
    if (socketRef.current) {
      // console.log('it is already exist')
      return
    }

    console.log('Connecting to WebSocket server:', url)
    const socketInstance = new WebSocket(url)
    socketRef.current = socketInstance

    // Connection opened
    socketInstance.onopen = () => {
      console.log('Connected to WebSocket server')
      setIsConnected(true)
      sendUserOnline()
    }

    // Listen for messages
    socketInstance.onmessage = (event) => {
      const data = JSON.parse(event.data)
      console.log('Message from server:', data)
      const { actionType } = data
      // Append the new message to the messages state
      switch (actionType) {
        case ActionType.PublicMessage:
          // add uuid as chatid to data
          setPublicMessages((prevMessages) => [
            ...prevMessages,
            { ...data, chatid: uuidv4() },
          ])
          break
        case ActionType.SingleMessage:
          // add uuid as chatid to data
          setSingleMessages((prevMessages) => [
            ...prevMessages,
            { ...data, chatid: uuidv4() },
          ])
          break
        case ActionType.UniqueCode:
          console.log('Unique code:', data)
          const { uniqueCode } = data
          if (uniqueCode !== token) {
            console.log('Unique code does not match, kick out the user')
            toastError('Another user has logged in your account.')
            logout()
            return
          }
          break
        default:
          console.log('Unknown action type:', actionType)
          break
      }
    }

    // Handle connection close
    socketInstance.onclose = () => {
      console.log('WebSocket connection closed')
      setIsConnected(false)
      // Try to reconnect after 3 seconds
      setTimeout(() => {
        console.log('Reconnecting to WebSocket server...')
        initSocket()
      }, 5000)
    }

    // Handle errors
    socketInstance.onerror = (error) => {
      console.log('WebSocket error:', error)
    }
  }, [logout, sendUserOnline, token, url, userProfile])

  useEffect(() => {
    if (!isConnected) {
      console.log('WebSocket is not connected')
      return
    }

    if (token) {
      console.log('Token is available')
      if (userProfile?.userId) {
        const userId = userProfile.userId
        sendUniqueCode(userId, token)
      } else {
        console.log('userId is missing')
      }
    } else {
      console.log('Token is missing')
    }
  }, [isConnected, sendUniqueCode, token, userProfile])

  useEffect(() => {
    // console.log('Initializing WebSocket connection')
    initSocket()
    // Cleanup when the component unmounts
    // return () => {
    //   console.log('Closing WebSocket connection')
    //   socketRef.current?.close()
    // }
  }, [initSocket])

  // debug
  // useEffect(() => {
  //   toastError('Another user has logged in your account.')
  // }, [])

  // Provide socket and action methods to the children components
  return (
    <WebSocketContext.Provider
      value={{
        sendUniqueCode,
        publicMessages,
        setPublicMessages,
        sendPublicMessage,
        singleMessages,
        setSingleMessages,
        sendSingleMessage,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  )
}

// Custom hook to use the WebSocketContext in child components
export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext)
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider')
  }
  return context
}
