import React, { useState } from 'react'
import { ReactComponent as ShopTickIcon } from '../../images/ShopPage/shop-tick-icon.svg'
import { ReactComponent as ShopArrowRightIcon } from '../../images/ShopPage/shop-right-arrow-icon.svg'
import { ReactComponent as ShopCartIcon } from '../../images/ShopPage/shop-cart-icon.svg'
import TokenPacksMock from '../../models/tokenPacksMock.model'
import { useAuth } from '../../providers/AuthProvider'
import { addToCart } from '../../services/cart.services'
import SuccessMessageToast from '../Items/SuccessMessageToast'
import ErrorMessageToast from '../Items/ErrorMessageToast'

interface AIMockTestSectionProps {
  tokenPacksMock: TokenPacksMock
}

const AIMockTestSection: React.FC<AIMockTestSectionProps> = ({
  tokenPacksMock,
}) => {
  const { userProfile } = useAuth()
  const [selectedPackage, setSelectedPackage] = useState<number>(
    tokenPacksMock.package[0]?.tkPkgId,
  )
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')

  const selectedPack = tokenPacksMock.package.find(
    (pack) => pack.tkPkgId === selectedPackage,
  )

  const handleAddToCart = async () => {
    if (userProfile && userProfile.userId && selectedPack) {
      try {
        const response = await addToCart(userProfile.userId, selectedPack.tkPkgId, 4)
        if (response.data.success) {
          setSuccessMsg(response.data.message)
        } else {
          setErrorMsg(response.data.message)
        }
      } catch (error: any) {
        if (error instanceof TypeError) {
          setErrorMsg(error.message)
        } else {
          setErrorMsg('Something wrong!')
        }
      } finally {
        setTimeout(() => {
          setErrorMsg('')
          setSuccessMsg('')
        }, 2000)
      }
    } else {
      console.log(userProfile)
      setErrorMsg('Something wrong!')
      setTimeout(() => {
        setErrorMsg('')
      }, 2000)
    }
  }

  return (
    <div className="ai-mocktest-section lg:w-1/2 md:w-1/2 w-full lg:h-[33.375rem] h-full flex flex-wrap gap-y-[2rem] lg:flex-col lg:gap-y-0 lg:gap-x-[1.6rem]">
      <div className="ai-mocktest-section__card bg-primary-6 lg:w-[20.375rem] w-full border border-2 border-[#0000000F] rounded-3xl p-4">
        <div className="card__title flex justify-between items-center">
          <p className="text-h3r text-white font-landingPrimary">Mock Test</p>
          <p className="text-bodyr font-landingPrimary px-[7px] rounded-[7px] bg-secondary-6 relative bottom-[6px]">
            Popular
          </p>
        </div>
        <div className="card__price mt-[1rem]">
          <p className="text-h1 font-landingPrimary text-white flex items-center gap-x-4">
            AUD{' '}
            {selectedPack?.tkPkgDiscount &&
              (
                (Number(selectedPack?.tkPkgPrice) *
                  (100 - selectedPack?.tkPkgDiscount)) /
                100
              ).toFixed(2)}
            <span className="text-xs text-[#AAAAAA] line-through font-medium font-landingPrimary mt-[0.4rem]">
              AUD {Number(selectedPack?.tkPkgPrice).toFixed(2)}
            </span>
          </p>
        </div>
        <div className="card__feature-options w-full mt-[1rem] relative">
          <select
            className="text-bodyr font-landingPrimary border border-2 border-transparent rounded-md w-full h-[2.625rem] px-[12px] appearance-none select-none"
            value={selectedPackage}
            onChange={(e) => setSelectedPackage(Number(e.target.value))}
          >
            {tokenPacksMock.package.map((pack) => (
              <option
                key={pack.tkPkgId}
                value={pack.tkPkgId}
                className="text-bodyr"
              >
                {pack.tkPkgName}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
            <svg
              className="h-4 w-4 text-[#00000040] fill-current"
              viewBox="0 0 20 20"
            >
              <path d="M10 12.586l-6.293-6.293a1 1 0 011.414-1.414L10 10.586l5.293-5.293a1 1 0 111.414 1.414L10 12.586z" />
            </svg>
          </div>
        </div>
        <div className="card__feature-details text-white font-landingPrimary my-[1rem]">
          <p className="text-bodym">Features</p>

          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="white" />
            <p className="text-bodyr">Fully graded scorecard</p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="white" />
            <p className="text-bodyr">Instance complete time of delivery</p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="white" />
            <p className="text-bodyr">Detailed analysis</p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="white" />
            <p className="text-bodyr">
              Authentic Pearson PTE scoring algorithms
            </p>
          </div>
        </div>
        <hr className="my-[0.4rem] border border-1 border-[#FFFFFF4D]" />
        <div className="card__purchase w-full mt-[1rem] flex flex-col gap-y-2">
          <button className="w-full px-[15px] py-[6.4px] border border-info hover:border-transparent bg-info hover:bg-[#1890FF] text-white text-h5r rounded-lg flex justify-center items-center gap-x-4">
            <ShopArrowRightIcon fill="white" />
            Buy Now
          </button>
          <button
            className="w-full px-[15px] py-[6.4px] text-white text-h5r hover:bg-[#002C5C] border border-white rounded-lg flex justify-center items-center gap-x-4"
            onClick={handleAddToCart}
          >
            <ShopCartIcon fill="white" />
            Add To Cart
          </button>
        </div>
      </div>
      { successMsg !== '' && <SuccessMessageToast message={successMsg} /> }
      { errorMsg !== '' && <ErrorMessageToast message={errorMsg}/>}
    </div>
  )
}

export default AIMockTestSection
