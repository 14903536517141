import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import ProtectedRoute from './routes/ProtectedRoute'
import Path from './routes/Path'
import LandingPage from './pages/LandingPage/LandingPage'
import LoginPage from './pages/LandingPage/LoginPage'
import RegisterPage from './pages/LandingPage/RegisterPage'
import HomePage from './pages/HomePage'
import ShopPage from './pages/ShopPage'
import MyUnlockedTestsPage from './pages/MyUnlockedTestsPage'
import LastMinuteStudyPage from './pages/LastMinuteStudyPage'
import PTEVideosPage from './pages/PTEVideosPage'
import SRSectionalSpeakingPage from './pages/SystemRequirement/SectionalTest/SRSectionalSpeakingPage'
import SRSectionalListeningPage from './pages/SystemRequirement/SectionalTest/SRSectionalListeningPage'
import SRSectionaWritingPage from './pages/SystemRequirement/SectionalTest/SRSectionalWritingPage'
import SRSectionalReadingPage from './pages/SystemRequirement/SectionalTest/SRSectionalReadingPage'
import SRMockTestPage from './pages/SystemRequirement/MockTest/SRMockTestPage'
import PurchasePage from './pages/PurchasePage'
import CartPage from './pages/CartPage'
import MyPerformancePage from './pages/MyPerformancePage'
import PracticePerformancePage from './pages/PracticePerformancePage'
// import LiveChatPage from './pages/LiveChatPage'
import SupportPage from './pages/SupportPage'
import UserProfilePage from './pages/UserProfilePage'
import { ApiProvider } from './providers/ApiProvider'
import { AuthProvider, useAuth } from './providers/AuthProvider'
import RedirectIfAuthenticated from './routes/RedirectIfAuthenticated'
import Layout from './components/Layout'
import LandingPageLayout from './components/LandingPage/LandingPageLayout'
import WeeklyPredictionCourse from './pages/WeeklyPrediction/WeeklyPredictionCourse'
import WeeklyPrediction from './pages/WeeklyPrediction/WeeklyPrediction'
import ReadAloud from './pages/WeeklyPrediction/ReadAloud'
import ReTellLecture from './pages/WeeklyPrediction/RetellLecture'
import RepeatSentence from './pages/WeeklyPrediction/RepeatSentence'
import AnswerShort from './pages/WeeklyPrediction/AnswerShort'
import DescribeImage from './pages/WeeklyPrediction/DescribeImage'
import SingleAnswerMC from './pages/WeeklyPrediction/SingleAnswerMC'
import MultipleAnswerMC from './pages/WeeklyPrediction/MultipleAnswerMC'
import ReorderParagraph from './pages/WeeklyPrediction/ReorderParagraph'
import SectionalReadAloud from './pages/SectionalTest/Speaking/ReadAloud'
import SectionalRepeatSentence from './pages/SectionalTest/Speaking/RepeatSentence'
import SectionalDescribeImage from './pages/SectionalTest/Speaking/DescribeImage'
import SectionalAnswerShort from './pages/SectionalTest/Speaking/AnswerShort'
import SectionalReTellLecture from './pages/SectionalTest/Speaking/RetellLecture'
import SummarizeWrittenTextSectional from './pages/SectionalTest/Writing/SummarizeWrittenTextSectional'
import WriteEssaySectional from './pages/SectionalTest/Writing/WriteEssaySectional'
import FillInTheBlank from './pages/WeeklyPrediction/FillInTheBlank'
import ReadingFillInTheBlank from './pages/WeeklyPrediction/ReadingFillInTheBlank'
import SummarizeSpokenTextSectional from './pages/SectionalTest/Listening/SummarizeSpokenTextSectional'
import MultipleChoiceChooseMultipleAnswersSectional from './pages/SectionalTest/Listening/MultipleChoiceChooseMultipleAnswersSectional'
import FillInTheBlanksSectional from './pages/SectionalTest/Listening/FillInTheBlanksSectional'
import HighlightCorrectSummarySectional from './pages/SectionalTest/Listening/HighlightCorrectSummarySectional'
import MultipleChoiceChooseSingleAnswerSectional from './pages/SectionalTest/Listening/MultipleChoiceChooseSingleAnswerSectional'
import SelectMissingWordSectional from './pages/SectionalTest/Listening/SelectMissingWordSectional'
import HighlightIncorrectWordsSectional from './pages/SectionalTest/Listening/HighlightIncorrectWordsSectional'
import WriteFromDictationSectional from './pages/SectionalTest/Listening/WriteFromDictationSectional'
import SummarizeWrittenTextMock from './pages/MockTest/Writing/SummarizeWrittenTextMock'
import WriteEssayMock from './pages/MockTest/Writing/WriteEssayMock'
import SummarizeSpokenTextMock from './pages/MockTest/Listening/SummarizeSpokenTextMock'
import MultipleChoiceChooseMultipleAnswersMock from './pages/MockTest/Listening/MultipleChoiceChooseMultipleAnswersMock'
import FillInTheBlanksMock from './pages/MockTest/Listening/FillInTheBlanksMock'
import HighlightCorrectSummaryMock from './pages/MockTest/Listening/HighlightCorrectSummaryMock'
import MultipleChoiceChooseSingleAnswerMock from './pages/MockTest/Listening/MultipleChoiceChooseSingleAnswerMock'
import SelectMissingWordMock from './pages/MockTest/Listening/SelectMissingWordMock'
import HighlightIncorrectWordsMock from './pages/MockTest/Listening/HighlightIncorrectWordsMock'
import WriteFromDictationMock from './pages/MockTest/Listening/WriteFromDictationMock'
import HistoryDetailsMockPage from './pages/MockTest/HistoryDetailsMockPage'
import SectionalMultipleAnswerMC from './pages/SectionalTest/Reading/MultipleAnswerMC'
import SectionalSingleAnswerMC from './pages/SectionalTest/Reading/SingleAnswerMC'
import SectionalReorderParagraph from './pages/SectionalTest/Reading/ReorderParagraph'
import SectionalFillInTheBlank from './pages/SectionalTest/Reading/FillInTheBlank'
import SectionalReadingFillInTheBlank from './pages/SectionalTest/Reading/ReadingFillInTheBlank'
import TestHistoryMockPage from './pages/MockTest/TestHistoryMockPage'
import TestHistorySectionalPage from './pages/SectionalTest/TestHistorySectionalPage'
import MockReadAloudQuestion from './components/MockTest/Speaking/ReadAloudQuestion'
import MockRepeatSentenceQuestion from './components/MockTest/Speaking/RepeatSentenceQuestion'
import MockDescribeImageQuestion from './components/MockTest/Speaking/DescribeImageQuestion'
import MockReTellLectureQuestion from './components/MockTest/Speaking/ReTellLectureQuestion'
import MockAnswerShortQuestion from './components/MockTest/Speaking/AnswerShortQuestion'
import MockMultipleMCQuestion from './components/MockTest/Reading/MultipleMCQuestion'
import MockSingleMCQuestion from './components/MockTest/Reading/SingleMCQuestion'
import MockReorderParagraphQuestion from './components/MockTest/Reading/ReOrderParagraphQuestion'
import MockFillInTheBlankQuesiton from './components/MockTest/Reading/FillInTheBlankQuestion'
import MockReadingFillInTheBlanksQuestion from './components/MockTest/Reading/ReadingFillInTheBlankQuestion'
//Sectional Test - Report History
import ReportHistorySpeakingSectionalPage from './pages/SectionalTest/ReportHistory/ReportHistorySpeakingSectionalPage'
import ReportHistoryWritingSectionalPage from './pages/SectionalTest/ReportHistory/ReportHistoryWritingSectionalPage'
import ReportHistoryListeningSectionalPage from './pages/SectionalTest/ReportHistory/ReportHistoryListeningSectionalPage'
import ReportHistoryReadingSectionalPage from './pages/SectionalTest/ReportHistory/ReportHistoryReadingSectionalPage'
//Weekly Prediction - Writing Skill
import SummarizeWrittenText from './pages/WeeklyPrediction/SummarizeWrittenText'
import WriteEssay from './pages/WeeklyPrediction/WriteEssay'
//Weekly Prediction - Listening Skill
import SummarizeSpokenText from './pages/WeeklyPrediction/Listening/SummarizeSpokenText'
import FillInTheBlanks from './pages/WeeklyPrediction/Listening/FillInTheBlanks'
import HighlightCorrectSummary from './pages/WeeklyPrediction/Listening/HighlightCorrectSummary'
import HighlightIncorrectWords from './pages/WeeklyPrediction/Listening/HighlightIncorrectWords'
import MultipleChoiceChooseMultipleAnswers from './pages/WeeklyPrediction/Listening/MultipleChoiceChooseMultipleAnswers'
import MultipleChoiceChooseSingleAnswer from './pages/WeeklyPrediction/Listening/MultipleChoiceChooseSingleAnswer'
import SelectMissingWord from './pages/WeeklyPrediction/Listening/SelectMissingWord'
import WriteFromDictation from './pages/WeeklyPrediction/Listening/WriteFromDictation'
//Activate Account
import ActivateAccountPage from './pages/LandingPage/ActtivateAccountPage'
import PurchaseSucceedPage from './pages/PurchaseSucceedPage'
import { WebSocketProvider } from './providers/WebSocketProvider'
// Toastify
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface LayoutSwitcherProps {
  children: React.ReactNode
}

const RedirectToHomeOrLanding = () => {
  const { userProfile } = useAuth()

  const hasProfile = userProfile && Object.keys(userProfile).length > 0

  if (hasProfile) {
    return <Navigate to="/home" />
  }

  return <Navigate to="/" />
}

const LayoutSwitcher: React.FC<LayoutSwitcherProps> = ({ children }) => {
  const location = useLocation()

  const landingPageRoutes = ['/', '/login', '/register', '/activate-account']

  const useLandingPageLayout = landingPageRoutes.includes(location.pathname)

  if (useLandingPageLayout) {
    return <LandingPageLayout>{children}</LandingPageLayout>
  } else {
    return <Layout>{children}</Layout>
  }
}

function App() {
  return (
    <>
      <BrowserRouter>
        <ApiProvider>
          <AuthProvider>
            <WebSocketProvider url={process.env.REACT_APP_WEB_SOCKET_URL || ''}>
              <LayoutSwitcher>
                <Routes>
                  <Route
                    path={Path.landing.main.path}
                    element={
                      <RedirectIfAuthenticated>
                        <LandingPage />
                      </RedirectIfAuthenticated>
                    }
                  />
                  <Route
                    path={Path.landing.login.path}
                    element={
                      <RedirectIfAuthenticated>
                        <LoginPage />
                      </RedirectIfAuthenticated>
                    }
                  />
                  <Route
                    path={Path.landing.register.path}
                    element={
                      <RedirectIfAuthenticated>
                        <RegisterPage />
                      </RedirectIfAuthenticated>
                    }
                  />
                  <Route
                    path={Path.landing.activateAccount.path}
                    element={
                      <RedirectIfAuthenticated>
                        <ActivateAccountPage />
                      </RedirectIfAuthenticated>
                    }
                  />
                  <Route
                    path={Path.home.path}
                    element={<ProtectedRoute component={HomePage} />}
                  />
                  <Route
                    path={Path.weeklyPrediction.path}
                    element={<ProtectedRoute component={WeeklyPrediction} />}
                  />
                  <Route
                    path={Path.weeklyPredictionCourse.path}
                    element={
                      <ProtectedRoute component={WeeklyPredictionCourse} />
                    }
                  />
                  <Route
                    path={Path.readAloud.path}
                    element={<ProtectedRoute component={ReadAloud} />}
                  />
                  <Route
                    path={Path.retellLecture.path}
                    element={<ProtectedRoute component={ReTellLecture} />}
                  />
                  <Route
                    path={Path.repeatSentence.path}
                    element={<ProtectedRoute component={RepeatSentence} />}
                  />
                  <Route
                    path={Path.describeImage.path}
                    element={<ProtectedRoute component={DescribeImage} />}
                  />
                  <Route
                    path={Path.answerShortQuestion.path}
                    element={<ProtectedRoute component={AnswerShort} />}
                  />
                  <Route
                    path={Path.multipleChoiceSingleAnswer.path}
                    element={<ProtectedRoute component={SingleAnswerMC} />}
                  />
                  <Route
                    path={Path.multipleChoiceMultipleAnswer.path}
                    element={<ProtectedRoute component={MultipleAnswerMC} />}
                  />
                  <Route
                    path={Path.reorderParagraph.path}
                    element={<ProtectedRoute component={ReorderParagraph} />}
                  />
                  <Route
                    path={Path.fillInTheBlank.path}
                    element={<ProtectedRoute component={FillInTheBlank} />}
                  />
                  <Route
                    path={Path.readingFillInTheBlank.path}
                    element={
                      <ProtectedRoute component={ReadingFillInTheBlank} />
                    }
                  />
                  <Route
                    path={Path.summarizeWrittenText.path}
                    element={
                      <ProtectedRoute component={SummarizeWrittenText} />
                    }
                  />
                  <Route
                    path={Path.writeEssay.path}
                    element={<ProtectedRoute component={WriteEssay} />}
                  />
                  <Route
                    path={Path.summarizeSpokenText.path}
                    element={<ProtectedRoute component={SummarizeSpokenText} />}
                  />
                  <Route
                    path={Path.fillInTheBlanks.path}
                    element={<ProtectedRoute component={FillInTheBlanks} />}
                  />
                  <Route
                    path={Path.highlightCorrectSummary.path}
                    element={
                      <ProtectedRoute component={HighlightCorrectSummary} />
                    }
                  />
                  <Route
                    path={Path.highlightIncorrectWords.path}
                    element={
                      <ProtectedRoute component={HighlightIncorrectWords} />
                    }
                  />
                  <Route
                    path={Path.multipleChoiceChooseMultipleAnswers.path}
                    element={
                      <ProtectedRoute
                        component={MultipleChoiceChooseMultipleAnswers}
                      />
                    }
                  />
                  <Route
                    path={Path.multipleChoiceChooseSingleAnswer.path}
                    element={
                      <ProtectedRoute
                        component={MultipleChoiceChooseSingleAnswer}
                      />
                    }
                  />
                  <Route
                    path={Path.selectMissingWord.path}
                    element={<ProtectedRoute component={SelectMissingWord} />}
                  />
                  <Route
                    path={Path.writeFromDictation.path}
                    element={<ProtectedRoute component={WriteFromDictation} />}
                  />
                  <Route
                    path={Path.sectionalTest.readAloud.path}
                    element={<ProtectedRoute component={SectionalReadAloud} />}
                  />
                  <Route
                    path={Path.sectionalTest.repeatSentence.path}
                    element={
                      <ProtectedRoute component={SectionalRepeatSentence} />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.describeImage.path}
                    element={
                      <ProtectedRoute component={SectionalDescribeImage} />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.answerShortQuestion.path}
                    element={
                      <ProtectedRoute component={SectionalAnswerShort} />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.retellLecture.path}
                    element={
                      <ProtectedRoute component={SectionalReTellLecture} />
                    }
                  />
                  <Route
                    path={
                      Path.sectionalTest.readingMultipleChoiceMultipleAnswer
                        .path
                    }
                    element={
                      <ProtectedRoute component={SectionalMultipleAnswerMC} />
                    }
                  />
                  <Route
                    path={
                      Path.sectionalTest.readingMultipleChoiceSingleAnswer.path
                    }
                    element={
                      <ProtectedRoute component={SectionalSingleAnswerMC} />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.reorderParagraph.path}
                    element={
                      <ProtectedRoute component={SectionalReorderParagraph} />
                    }
                  />
                  <Route
                    path={
                      Path.sectionalTest.readingAndWritingFillInTheBlanks.path
                    }
                    element={
                      <ProtectedRoute component={SectionalFillInTheBlank} />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.readingFillInTheBlanks.path}
                    element={
                      <ProtectedRoute
                        component={SectionalReadingFillInTheBlank}
                      />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.summarizeWrittenText.path}
                    element={
                      <ProtectedRoute
                        component={SummarizeWrittenTextSectional}
                      />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.writeEssay.path}
                    element={<ProtectedRoute component={WriteEssaySectional} />}
                  />
                  <Route
                    path={Path.sectionalTest.summarizeSpokenText.path}
                    element={
                      <ProtectedRoute
                        component={SummarizeSpokenTextSectional}
                      />
                    }
                  />
                  <Route
                    path={
                      Path.sectionalTest.multipleChoiceChooseMultipleAnswers
                        .path
                    }
                    element={
                      <ProtectedRoute
                        component={MultipleChoiceChooseMultipleAnswersSectional}
                      />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.fillInTheBlanks.path}
                    element={
                      <ProtectedRoute component={FillInTheBlanksSectional} />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.highlightCorrectSummary.path}
                    element={
                      <ProtectedRoute
                        component={HighlightCorrectSummarySectional}
                      />
                    }
                  />
                  <Route
                    path={
                      Path.sectionalTest.multipleChoiceChooseSingleAnswer.path
                    }
                    element={
                      <ProtectedRoute
                        component={MultipleChoiceChooseSingleAnswerSectional}
                      />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.selectMissingWord.path}
                    element={
                      <ProtectedRoute component={SelectMissingWordSectional} />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.highlightIncorrectWords.path}
                    element={
                      <ProtectedRoute
                        component={HighlightIncorrectWordsSectional}
                      />
                    }
                  />
                  <Route
                    path={Path.sectionalTest.writeFromDictation.path}
                    element={
                      <ProtectedRoute component={WriteFromDictationSectional} />
                    }
                  />
                  <Route
                    path={Path.shop.path}
                    element={<ProtectedRoute component={ShopPage} />}
                  />
                  <Route
                    path={Path.myUnlockedTests.path}
                    element={<ProtectedRoute component={MyUnlockedTestsPage} />}
                  />
                  <Route
                    path={Path.lastMinuteStudy.path}
                    element={<ProtectedRoute component={LastMinuteStudyPage} />}
                  />
                  <Route
                    path={Path.community.pteVideos.path}
                    element={<ProtectedRoute component={PTEVideosPage} />}
                  />
                  <Route
                    path={Path.systemRequirement.sectional.speaking.path}
                    element={
                      <ProtectedRoute component={SRSectionalSpeakingPage} />
                    }
                  />
                  <Route
                    path={Path.systemRequirement.sectional.writing.path}
                    element={
                      <ProtectedRoute component={SRSectionaWritingPage} />
                    }
                  />
                  <Route
                    path={Path.systemRequirement.sectional.reading.path}
                    element={
                      <ProtectedRoute component={SRSectionalReadingPage} />
                    }
                  />
                  <Route
                    path={Path.systemRequirement.sectional.listening.path}
                    element={
                      <ProtectedRoute component={SRSectionalListeningPage} />
                    }
                  />
                  <Route
                    path={Path.systemRequirement.mock.path}
                    element={<ProtectedRoute component={SRMockTestPage} />}
                  />
                  <Route
                    path={Path.yourPurchase.path}
                    element={<ProtectedRoute component={PurchasePage} />}
                  />
                  <Route
                    path={Path.purchaseSucceed.path}
                    element={<ProtectedRoute component={PurchaseSucceedPage} />}
                  />
                  <Route
                    path={Path.cart.path}
                    element={<ProtectedRoute component={CartPage} />}
                  />
                  <Route
                    path={Path.myPerformance.path}
                    element={<ProtectedRoute component={MyPerformancePage} />}
                  />
                  <Route
                    path={Path.practicePerformance.path}
                    element={
                      <ProtectedRoute component={PracticePerformancePage} />
                    }
                  />
                  {/* <Route
                  path={Path.liveChat.path}
                  element={<ProtectedRoute component={LiveChatPage} />}
                /> */}
                  <Route
                    path={Path.support.path}
                    element={<ProtectedRoute component={SupportPage} />}
                  />
                  <Route
                    path={Path.userProfile.path}
                    element={<ProtectedRoute component={UserProfilePage} />}
                  />
                  <Route
                    path={Path.mockTest.summarizeWrittenText.path}
                    element={
                      <ProtectedRoute component={SummarizeWrittenTextMock} />
                    }
                  />
                  <Route
                    path={Path.mockTest.writeEssay.path}
                    element={<ProtectedRoute component={WriteEssayMock} />}
                  />
                  <Route
                    path={Path.mockTest.summarizeSpokenText.path}
                    element={
                      <ProtectedRoute component={SummarizeSpokenTextMock} />
                    }
                  />
                  <Route
                    path={
                      Path.mockTest.multipleChoiceChooseMultipleAnswers.path
                    }
                    element={
                      <ProtectedRoute
                        component={MultipleChoiceChooseMultipleAnswersMock}
                      />
                    }
                  />
                  <Route
                    path={Path.mockTest.fillInTheBlanks.path}
                    element={<ProtectedRoute component={FillInTheBlanksMock} />}
                  />
                  <Route
                    path={Path.mockTest.highlightCorrectSummary.path}
                    element={
                      <ProtectedRoute component={HighlightCorrectSummaryMock} />
                    }
                  />
                  <Route
                    path={Path.mockTest.multipleChoiceChooseSingleAnswer.path}
                    element={
                      <ProtectedRoute
                        component={MultipleChoiceChooseSingleAnswerMock}
                      />
                    }
                  />
                  <Route
                    path={Path.mockTest.selectMissingWord.path}
                    element={
                      <ProtectedRoute component={SelectMissingWordMock} />
                    }
                  />
                  <Route
                    path={Path.mockTest.highlightIncorrectWords.path}
                    element={
                      <ProtectedRoute component={HighlightIncorrectWordsMock} />
                    }
                  />
                  <Route
                    path={Path.mockTest.writeFromDictation.path}
                    element={
                      <ProtectedRoute component={WriteFromDictationMock} />
                    }
                  />
                  <Route
                    path={Path.mockTest.readAloud.path}
                    element={
                      <ProtectedRoute component={MockReadAloudQuestion} />
                    }
                  />
                  <Route
                    path={Path.mockTest.repeatSentence.path}
                    element={
                      <ProtectedRoute component={MockRepeatSentenceQuestion} />
                    }
                  />
                  <Route
                    path={Path.mockTest.describeImage.path}
                    element={
                      <ProtectedRoute component={MockDescribeImageQuestion} />
                    }
                  />
                  <Route
                    path={Path.mockTest.retellLecture.path}
                    element={
                      <ProtectedRoute component={MockReTellLectureQuestion} />
                    }
                  />
                  <Route
                    path={Path.mockTest.answerShortQuestion.path}
                    element={
                      <ProtectedRoute component={MockAnswerShortQuestion} />
                    }
                  />
                  <Route
                    path={Path.mockTest.readingMultipleChoiceSingleAnswer.path}
                    element={
                      <ProtectedRoute component={MockSingleMCQuestion} />
                    }
                  />
                  <Route
                    path={
                      Path.mockTest.readingMultipleChoiceMultipleAnswer.path
                    }
                    element={
                      <ProtectedRoute component={MockMultipleMCQuestion} />
                    }
                  />
                  <Route
                    path={Path.mockTest.reorderParagraph.path}
                    element={
                      <ProtectedRoute
                        component={MockReorderParagraphQuestion}
                      />
                    }
                  />
                  <Route
                    path={Path.mockTest.readingAndWritingFillInTheBlanks.path}
                    element={
                      <ProtectedRoute component={MockFillInTheBlankQuesiton} />
                    }
                  />
                  <Route
                    path={Path.mockTest.readingFillInTheBlanks.path}
                    element={
                      <ProtectedRoute
                        component={MockReadingFillInTheBlanksQuestion}
                      />
                    }
                  />
                  <Route
                    path={Path.historyDetailsMock.path}
                    element={
                      <ProtectedRoute component={HistoryDetailsMockPage} />
                    }
                  />
                  <Route
                    path={Path.testHistoryMock.path}
                    element={<ProtectedRoute component={TestHistoryMockPage} />}
                  />
                  <Route
                    path={Path.testHistorySectional.path}
                    element={
                      <ProtectedRoute component={TestHistorySectionalPage} />
                    }
                  />
                  <Route
                    path={Path.reportHistorySectional.speaking.path}
                    element={
                      <ProtectedRoute
                        component={ReportHistorySpeakingSectionalPage}
                      />
                    }
                  />
                  <Route
                    path={Path.reportHistorySectional.writing.path}
                    element={
                      <ProtectedRoute
                        component={ReportHistoryWritingSectionalPage}
                      />
                    }
                  />
                  <Route
                    path={Path.reportHistorySectional.listening.path}
                    element={
                      <ProtectedRoute
                        component={ReportHistoryListeningSectionalPage}
                      />
                    }
                  />
                  <Route
                    path={Path.reportHistorySectional.reading.path}
                    element={
                      <ProtectedRoute
                        component={ReportHistoryReadingSectionalPage}
                      />
                    }
                  />
                  <Route path="*" element={<RedirectToHomeOrLanding />} />
                </Routes>
              </LayoutSwitcher>
            </WebSocketProvider>
          </AuthProvider>
        </ApiProvider>
      </BrowserRouter>
      <ToastContainer />
    </>
  )
}

export default App
