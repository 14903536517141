import React, { useState } from 'react'
import QuestionModalAccordion from '../../components/QuestionModalAccordion'
import SpeakingQuestionList from '../../components/SpeakingCourse/SpeakingQuestionList'
import { QuestionModel } from '../../models/QuestionModels'
import AnswerShortQuestionList from '../../components/SpeakingCourse/AnswerShortQuestionList'
import DescribeImageList from '../../components/SpeakingCourse/DescribeImageList'

const WeeklyPredictionCourse = () => {
  let questionTypePTE = localStorage.getItem('questionTypePTE')

  const [questionType, setQuestionType] = useState<QuestionModel>(
    (questionTypePTE as QuestionModel) || QuestionModel.ReadAloud,
  )
  const [practiceSelection, setPracticeSelection] = useState('')
  const [typeSelection, setTypeSelection] = useState('')

  const handlePracticeChange = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setPracticeSelection(event.target.value)
    localStorage.setItem('ptePracticeQuestionAttempt', practiceSelection)
  }

  const handleTypeChange = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setTypeSelection(event.target.value)
    localStorage.setItem('ptePracticeQuestionLabel', typeSelection)
  }

  return (
    <div>
      <div className="bg-primary-9 h-[320px] content-center text-center">
        <p className="text-h1 text-primary-1">Speaking Test</p>
        <p className="text-bodyr text-primary-2 pt-8 lg:mx-[32%]">
          Mattis rhoncus urna neque viverra justo. Ullamcorper dignissim cras
          tincidunt lobortis feugiat vivamus. Neque vitae tempus quam
          pellentesque nec. Mi bibendum neque egestas congue quisque egestas
          diam in.
        </p>
      </div>
      <div className="p-4 xl:p-10 grid grid-cols-8 gap-8">
        <div className="col-span-8 lg:col-span-2 flex flex-col-reverse lg:flex-col">
          <div className="lg:border-b border-[2px]-[#BFBFBF] pb-2 pt-2 lg:pt-0">
            <p className="text-h5m uppercase">Practice</p>
            <div className="flex items-center my-2">
              <input
                type="radio"
                id="p-all-questions"
                className="h-4 w-4"
                name="practice"
                value="allQuestions"
                checked={practiceSelection === 'allQuestions'}
                onChange={handlePracticeChange}
              />
              <label htmlFor="p-all-questions" className="text-bodyr text-neutrals-1 pl-2">All questions</label>
            </div>

            <div className="flex items-center my-2">
              <input
                type="radio"
                id="p-attempted-questions"
                className="h-4 w-4 text-neutrals-1 rounded-full"
                name="practice"
                value="attemptedQuestions"
                checked={practiceSelection === 'attemptedQuestions'}
                onChange={handlePracticeChange}
              />
              <label htmlFor="p-attempted-questions" className="text-bodyr text-neutrals-1 pl-2">
                Attempted questions
              </label>
            </div>
            <div className="flex items-center my-2">
              <input
                type="radio"
                id="p-not-attempted-questions"
                className="h-4 w-4 text-primary-1"
                name="practice"
                value="notAttemptedQuestions"
                checked={practiceSelection === 'notAttemptedQuestions'}
                onChange={handlePracticeChange}
              />
              <label htmlFor="p-not-attempted-questions" className="text-bodyr text-neutrals-1 pl-2">
                Not attempted questions
              </label>
            </div>
          </div>
          <div className="border-b border-[2px]-[#BFBFBF] py-2">
            <p className="text-h5m uppercase">Type</p>
            <div className="flex items-center my-2">
              <input
                type="radio"
                id="t-all"
                className="h-4 w-4"
                name="type"
                value="all"
                checked={typeSelection === 'all'}
                onChange={handleTypeChange}
              />
              <label htmlFor="t-all" className="text-bodyr text-neutrals-1 pl-2">All</label>
            </div>

            <div className="flex items-center my-2">
              <input
                type="radio"
                id="t-easy"
                className="h-4 w-4 text-neutrals-1 rounded-full"
                name="type"
                value="easy"
                checked={typeSelection === 'easy'}
                onChange={handleTypeChange}
              />
              <label htmlFor="t-easy" className="text-bodyr text-neutrals-1 pl-2">Easy</label>
            </div>
            <div className="flex items-center my-2">
              <input
                type="radio"
                id="t-normal"
                className="h-4 w-4 text-primary-1"
                name="type"
                value="normal"
                checked={typeSelection === 'normal'}
                onChange={handleTypeChange}
              />
              <label htmlFor="t-normal" className="text-bodyr text-neutrals-1 pl-2">Normal</label>
            </div>

            <div className="flex items-center my-2">
              <input
                type="radio"
                id="t-hard"
                className="h-4 w-4 text-primary-1"
                name="type"
                value="hard"
                checked={typeSelection === 'hard'}
                onChange={handleTypeChange}
              />
              <label htmlFor="t-hard" className="text-bodyr text-neutrals-1 pl-2">Hard</label>
            </div>
          </div>
          <div>
            <p className="text-h5m uppercase pt-2">Zone</p>
            <QuestionModalAccordion setQuestionType={setQuestionType} />
          </div>
        </div>

        <div className="col-span-8 lg:col-span-6">
          {questionType === QuestionModel.AnswerShortQuestions ? (
            <AnswerShortQuestionList />
          ) : questionType === QuestionModel.DescribeImages ? (
            <DescribeImageList />
          ) : (
            <SpeakingQuestionList questionType={questionType} />
          )}
        </div>
      </div>
    </div>
  )
}

export default WeeklyPredictionCourse
