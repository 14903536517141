import YoutubeVideo from '../../models/youtubeVideo.model'

const PTEVideoCard: React.FC<{ youtubeVideo: YoutubeVideo }> = ({
  youtubeVideo,
}) => {
  return (
    <div className="youtube-videos-section__list__item w-[19.625rem] lg:min-h-[14.875rem] h-full rounded-lg">
      <iframe
        className="youtube-videos-section__list__video w-full lg:h-[11.875rem] h-full rounded-lg"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        src={youtubeVideo.url}
        title={youtubeVideo.title}
      ></iframe>
      <div className="youtube-videos-section__list__title w-full flex gap-2 items-center mt-[0.625rem]">
        <p className="text-h5m font-landingPrimary">{youtubeVideo.title}</p>
      </div>
    </div>
  )
}

export default PTEVideoCard
