import { useState, useRef, useEffect } from 'react'
import NextArrowSvg from '../../../images/Speaking/next-arrow.svg'
import Path from '../../../routes/Path'
import { useNavigate } from 'react-router-dom'
import { ExtendedQuestion } from '../../../models/question.model'
import Option from '../../../models/option.model'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import { useAuth } from '../../../providers/AuthProvider'
import { addResultReadingSectional, doTest4Update } from '../../../services/sectionalTest.services'

const SectionalSingleMCQuestion = () => {
  const navigate = useNavigate()
  const [timer, setTimer] = useState({ minutes: 40, seconds: 0 })
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(undefined)
  const [options, setOptions] = useState<Option[]>([])
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined)
  const [isCorrectAnswer, setIsCorrectAnswer] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { userProfile } = useAuth()

  // Ref to store the elapsed time in seconds
  const elapsedTimeRef = useRef(0)

  const handleCheckboxChange = (option: Option, index: number) => {
    setSelectedOption(option)
    if (option.correct === 1) {
      setIsCorrectAnswer(1)
    } else {
      setIsCorrectAnswer(0)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const reseultData = localStorage.getItem('resultSectionalRPTE')
      if (reseultData) {
        const parsedResultData = JSON.parse(reseultData)
        setQuestion(parsedResultData.mockqestion[0])
        setQuestionNumber(parsedResultData.row);
        setTotalQuestion(Number(parsedResultData.allcount))
        setOptions(parsedResultData.option);
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [])

  // Function to start the timer countdown
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (timer.minutes === 0 && timer.seconds === 0) {
      // Timer has reached 00:00, stop the timer
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        elapsedTimeRef.current += 1

        if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 })
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 })
        }
      }, 1000)
    }

    // Cleanup function to clear the interval when component unmounts or timer stops
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timer])

  
  const formattedTime = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`

  // Function to calculate the total elapsed time in seconds
  const calculateElapsedTime = () => {
    return elapsedTimeRef.current
  }


  const handleSubmitAnswer = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    // const buyId = Number(localStorage.getItem('buyIdRPTESectional')) || 0
    // const testId = Number(localStorage.getItem('testIdRPTESectional')) || 0

    if (question && selectedOption && userProfile && userProfile.userId) {
      try {
        const addResultResponse = await addResultReadingSectional(
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          question.buyId,
          String(isCorrectAnswer),
          selectedOption.options
        )
      
        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest4Update(
            question.buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            3,
            String(elapsedTime),
            String(elapsedTime),
            totalQuestion,
            'Skip'
          )
      
          if (getQuestionResponse.data.success) {
            localStorage.setItem('resultSectionalRPTE', JSON.stringify(getQuestionResponse.data.result))
      
            if (getQuestionResponse.data.category_id === '12') {
              navigate(0) // Reload the same page
            } 
            if (getQuestionResponse.data.category_id === '13') {
              navigate(Path.sectionalTest.readingMultipleChoiceMultipleAnswer.path)
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        if (error instanceof TypeError) {
          console.error(error.message)
        } else {
          console.error('Something went wrong!')
        }
      }
    } else {
      console.error('Userprofile or question or selected option not found!')
    }
  }

  const handleSkip = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (question && userProfile && userProfile.userId) {
      try {
          const getQuestionResponse = await doTest4Update(
            question.buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            3,
            String(elapsedTime),
            String(elapsedTime),
            totalQuestion,
            'Skip'
          )
      
          if (getQuestionResponse.data.success) {
            localStorage.setItem('resultSectionalRPTE', JSON.stringify(getQuestionResponse.data.result))
      
            if (getQuestionResponse.data.category_id === '12') {
              navigate(0) // Reload the same page
            } 
            if (getQuestionResponse.data.category_id === '13') {
              navigate(Path.sectionalTest.readingMultipleChoiceMultipleAnswer.path)
            }

            if (getQuestionResponse.data.category_id === '14') {
              navigate(Path.sectionalTest.reorderParagraph.path)
            }
  
            if (getQuestionResponse.data.category_id === '15') {
              navigate(Path.sectionalTest.readingFillInTheBlanks.path)
            }
  
            if (getQuestionResponse.data.category_id === '16') {
              navigate(Path.sectionalTest.readingAndWritingFillInTheBlanks.path)
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } 
      catch (error) {
        if (error instanceof TypeError) {
          console.error(error.message)
        } else {
          console.error('Something went wrong!')
        }
      }
    } else {
      console.error('Userprofile or question not found!')
    }
  }

  return (
    <div className='min-h-[80vh]'>
      {
        isLoading ? (
          <LoadingMessage message='Loading question...'/> 
        ) : errorMsg ? (
          <ErrorMessage message={errorMsg} />
        ) : (
          <div>    
            <div className="p-8 rounded-xl shadow mt-8">
              <div className="flex justify-between p-4 mb-4 bg-info rounded-lg text-white text-h5r">
                <p>Reading Section - Multiple-choice, choose single answer (#{question?.id})</p>
                <p>({questionNumber + 1} Out of 16)</p>
              </div>
              <div className="w-[6.25rem] h-[3.5rem] bg-primary-2 mb-6 rounded-lg flex justify-center items-center mx-auto">
                <p className="text-h2 text-white">{formattedTime}</p>
              </div>
              <p className="text-h4m text-neutrals-1">
                { question?.shortTitle }
              </p>
              <p className="text-h5r text-neutrals-1 pt-4">
                { question?.description }
              </p>
          
              <div className="w-[90%] lg:w-[60%] pt-8 mx-auto">
                <p className="text-h5m">{question?.question}</p>
                {options.map((option: Option, index) => (
                  <div key={index} className="flex items-center mt-4">
                    <p className="text-footnote py-[2px] px-[8px] rounded-full bg-info text-white">
                      {String.fromCharCode(65 + index)}
                    </p>
                    <input
                      type="radio"
                      name="options"
                      className="mx-2"
                      id={String(option.id)}
                      onChange={() => handleCheckboxChange(option, index)}
                    />
                    <label htmlFor={String(option.id)} className="text-bodyr">{option.options}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex w-full justify-center mt-4">
              <button 
                className={`flex text-bodyr text-white items-center justify-centers gap-2 py-2 px-6 rounded-lg mr-2 
                  ${(!selectedOption || isSubmitted) ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                onClick={() => handleSubmitAnswer()}
                disabled={!selectedOption || isSubmitted}
              >
                Save and Next
                <img src={NextArrowSvg} alt="next" className="md:ml-2" />
              </button>

              <button 
                className={`flex items-center px-4 py-2 rounded-xl 
                  ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
                disabled={isSubmitted}
                onClick={() => handleSkip()}
              >
                <p className="text-bodyr text-white">Skip</p>
              </button>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default SectionalSingleMCQuestion
