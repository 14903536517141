import { useState } from 'react'
import PointSvg from '../../../images/Speaking/point.svg'
import ArrowDown from '../../../images/Speaking/arrow-down.svg'
import SummarizeSpokenTextQuestion from '../../../components/ListeningCourse/SummarizeSpokenTextQuestion'

interface TopAnswers {
  admin_upload: string;
  prac_content: number | string;
  prac_date: string;
  prac_fluency: string;
  prac_form: number | string;
  prac_grammar: number | string;
  prac_pronoun: string;
  prac_result_audio: string;
  prac_result_category_id: string;
  prac_result_id: string;
  prac_result_marks: string;
  prac_result_question_id: string;
  prac_result_user_id: string;
  prac_spelling: string | number;
  prac_transcript: string;
  prac_vocab: number | string;
  userimage: string;
  username: string;
  verified: string;
}

const topAnswers: TopAnswers[] = [
  {
    prac_result_id: "3607",
    prac_result_question_id: "5440",
    prac_result_category_id: "0",
    admin_upload: "",
    prac_content: 2,
    prac_date: "2024-01-23 23:36:32",
    prac_fluency: "",
    prac_form: 1,
    prac_grammar: 2,
    prac_pronoun: "",
    prac_result_audio: "https://ptefilesbucket-stg.s3.amazonaws.com/practice_audio/",
    prac_result_marks: "90",
    prac_result_user_id: "52619",
    prac_spelling: 2,
    prac_transcript: "abels manufacturers product buy shoppers consumer nutrients nutritious detailed labels manufacturers product buy shoppers consumer nutrients nutritious detailed labels manufacturers product buy shoppers consumer nutrients nutritious detailed labels manufacturers product buy shoppers consumer nutrients nutritious detailed labels manufacturers product buy shoppers consumer nutrients nutritious detailed",
    prac_vocab: 2,
    userimage: "https://ptefilesbucket-stg.s3.amazonaws.com/userprofile/5de588268be82.png",
    username: "Ady",
    verified: "1"
  },
  {
    prac_result_id: "3591",
    prac_result_question_id: "5440",
    prac_result_category_id: "0",
    admin_upload: "",
    prac_content: 2,
    prac_date: "2024-01-12 09:10:52",
    prac_fluency: "",
    prac_form: 1,
    prac_grammar: 2,
    prac_pronoun: "",
    prac_result_audio: "https://ptefilesbucket-stg.s3.amazonaws.com/practice_audio/",
    prac_result_marks: "90",
    prac_result_user_id: "52602",
    prac_spelling: 2,
    prac_transcript: "labels manufacturers product buy shoppers consumer nutrients nutritious detailed labels manufacturers product buy shoppers consumer nutrients nutritious detailed labels manufacturers product buy shoppers consumer nutrients nutritious detailed labels manufacturers product buy shoppers consumer nutrients nutritious detailed labels manufacturers product buy shoppers consumer nutrients nutritious detailed",
    prac_vocab: 2,
    userimage: "https://ptefilesbucket-stg.s3.amazonaws.com/userprofile/5de588268be82.png",
    username: "Amit Kumar",
    verified: "0"
  }
]



const SummarizeSpokenText = () => {
  const [activeCategory, setActiveCategory] = useState('Top Answers')
  const [showScoreBoard, setShowScoreBoard] = useState(false)
  const [detailsVisible, setDetailsVisible] = useState(
    Array(topAnswers.length).fill(false),
  )

  const toggleDetails = (index: number) => {
    const updatedVisibility = [...detailsVisible]
    updatedVisibility[index] = !updatedVisibility[index]
    setDetailsVisible(updatedVisibility)
  }

  return (
    <div>
      <SummarizeSpokenTextQuestion
        setShowScoreBoard={setShowScoreBoard}
        showScoreBoard={showScoreBoard}
      />
      {showScoreBoard && (
        <div className="mt-[3rem]">
          <div className="flex my-4 rounded-xl bg-gray-100 p-1 mx-auto w-[90%] xl:w-[60%]">
            <button
              className={`flex-1 p-2 text-center rounded-xl transition-colors duration-300 cursor-pointer ${
                activeCategory === 'Top Answers'
                  ? 'bg-primary-6 text-primary-1'
                  : 'bg-transparent text-neutrals-1'
              }`}
              onClick={() => setActiveCategory('Top Answers')}
            >
              Top Answers
            </button>
            <button
              className={`flex-1 p-2 text-center rounded-xl transition-colors duration-300 cursor-pointer ${
                activeCategory === 'Best Verified Answer'
                  ? 'bg-primary-6 text-primary-1'
                  : 'bg-transparent text-neutrals-1'
              }`}
              onClick={() => setActiveCategory('Best Verified Answer')}
            >
              Best Verified Answer{' '}
            </button>
          </div>
          {topAnswers.map((answer, index) => (
            <div
              key={index}
              className={`p-8 shadow my-8 border border-[1px]-[#DFE3E6] rounded-lg
            ${
              index % 3 === 0
                ? 'shadow-[6px_6px_0_#FFBA68]'
                : index % 3 === 1
                  ? 'shadow-[6px_6px_0_#14AE5C]'
                  : 'shadow-[6px_6px_0_#F42424]'
            }
            `}
            >
              <div className="flex justify-between">
                <div className="flex items-center">
                  <img src={answer.userimage} width={40} height={40} alt="avatar" className="mr-2 rounded-full" />

                  <div>
                    <p className="text-h5m">{answer.username}</p>
                    <p
                      className="flex text-bodyr text-neutrals-2 cursor-pointer"
                      onClick={() => toggleDetails(index)}
                    >
                      <img src={ArrowDown} alt="arrow-down" className="mr-2" />
                      Show Details
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <img src={PointSvg} alt="point" className="h-full mr-2" />
                  <div>
                    <p className="text-footnote">Points</p>
                    <p className="text-h2">{Math.floor(Number(answer.prac_result_marks))}</p>
                  </div>
                </div>
              </div>
              <div 
                className="mt-[1rem]"
                dangerouslySetInnerHTML={{ __html: answer.prac_transcript }}
              >
              </div>

              {detailsVisible[index] && (
                <>
                  <div className="w-full grid grid-cols-4 gap-4 items-center mt-[4rem]">
                    <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                      <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#FF7A45] drop-shadow-lg">
                        {answer.prac_grammar !== 'N/A' ? `${answer.prac_grammar}/2` : answer.prac_grammar}
                      </div>
                      <p className="text-bodyr text-neutrals-1 mt-2">Grammar</p>
                    </div>
                    <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                      <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#597EF7] drop-shadow-lg">
                        {answer.prac_content !== 'N/A' ? `${answer.prac_content}/2` : answer.prac_content}
                      </div>
                      <p className="text-bodyr text-neutrals-1 mt-2">
                        Content
                      </p>
                    </div>
                    <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                      <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#722ED1] drop-shadow-lg">
                        {answer.prac_form !== 'N/A' ? `${answer.prac_form}/1` : answer.prac_form}
                      </div>
                      <p className="text-bodyr text-neutrals-1 mt-2">
                        Form
                      </p>
                    </div>
                    <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                      <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#EB2F96] drop-shadow-lg">
                        {answer.prac_vocab !== 'N/A' ? `${answer.prac_vocab}/2` : answer.prac_vocab}
                      </div>
                      <p className="text-bodyr text-neutrals-1 mt-2">Vocalbulary</p>
                    </div>
                    <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                      <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#FF7A45] drop-shadow-lg">
                        {answer.prac_spelling !== 'N/A' ? `${answer.prac_spelling}/2` : answer.prac_spelling}
                      </div>
                      <p className="text-bodyr text-neutrals-1 mt-2">Spelling</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SummarizeSpokenText
