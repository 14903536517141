import React from 'react'

interface OverallScoreProps {
  score: number
}

const OverallScoreMock: React.FC<OverallScoreProps> = ({ score }) => {
  const radius = 72
  const circumference = 2 * Math.PI * radius
  const strokeVal = (score / 90) * circumference

  return (
    <div className="w-full flex justify-between items-center space-x-4 !place-content-center md:place-content-left">
      <div className="relative">
        <svg width="200" height="200" className="transform -rotate-90">
          <circle
            cx="100"
            cy="100"
            r="72"
            fill="none"
            stroke="#EEEEEE"
            strokeWidth="16"
          />
          <circle
            cx="100"
            cy="100"
            r="72"
            fill="none"
            stroke="#2054E9"
            strokeWidth="16"
            strokeDasharray={circumference}
            strokeDashoffset={circumference - strokeVal}
            strokeLinecap="round"
          />
        </svg>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <span className="text-h1 text-primary-6 font-semibold">{score}</span>
        </div>
      </div>
    </div>
  )
}

export default OverallScoreMock
