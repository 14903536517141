import { useState, useRef, useEffect } from 'react'
import NextArrowSvg from '../../../images/Speaking/next-arrow.svg'
import { useNavigate } from 'react-router-dom'
import { ExtendedQuestion } from '../../../models/question.model'
import Option from '../../../models/option.model'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import { useAuth } from '../../../providers/AuthProvider'
import { addResultReadingMock, doTest1Update } from '../../../services/mockTest.services'
import {
  handleExceptionError,
  navigateToMockTest,
} from '../../../utils/utils'

const MockMultipleMCQuestion = () => {
  const navigate = useNavigate()
  const [timer, setTimer] = useState({ minutes: 40, seconds: 0 })
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(undefined)
  const [options, setOptions] = useState<Option[]>([])
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [buyId, setBuyId] = useState<number | undefined>(undefined)
  const { userProfile } = useAuth()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  // Ref to store the elapsed time in seconds
  const elapsedTimeRef = useRef(0)

  useEffect(() => {
    setTimeout(() => {
      const reseultData = localStorage.getItem("resultMockPTE")
      const buyIdStorage = localStorage.getItem("buyIdPTEMock")
      if (buyIdStorage) {
        setBuyId(Number(buyIdStorage))
      }
    
      if (reseultData) {
        const parsedResultData = JSON.parse(reseultData)
        setQuestion(parsedResultData.mockqestion[0])
        setQuestionNumber(parsedResultData.row)
        setTotalQuestion(Number(parsedResultData.allcount))
        setOptions(parsedResultData.option)
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [])

  // Function to start the timer countdown
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (timer.minutes === 0 && timer.seconds === 0) {
      // Timer has reached 00:00, stop the timer
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        elapsedTimeRef.current += 1

        if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 })
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 })
        }
      }, 1000)
    }

    // Cleanup function to clear the interval when component unmounts or timer stops
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timer])

  const formattedTime = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`

  // Function to calculate the total elapsed time in seconds
  const calculateElapsedTime = () => {
    return elapsedTimeRef.current
  }

  const handleCheckboxChange = (option: Option) => {
    if (selectedOptions.includes(option.options)) {
      // Remove the option if it is already selected
      setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption !== option.options));
    } else {
      // Add the option if it is not selected
      setSelectedOptions([...selectedOptions, option.options]);
    }
  }

  const handleSubmitAnswer = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (question && selectedOptions.length !== 0 && userProfile && userProfile.userId && buyId) {
      try {
        const addResultResponse = await addResultReadingMock(
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          selectedOptions.join('<#>'),
          selectedOptions.join('<br/>')
        )
      
        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest1Update(
            buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            3,
            String(elapsedTime),
            String(elapsedTime),
            totalQuestion,
            'Skip'
          )
      
          if (getQuestionResponse.data.success) {
            localStorage.setItem(
              'resultMockPTE',
              JSON.stringify(getQuestionResponse.data.result),
            )

            if (getQuestionResponse.data.category_id === '13') {
              navigate(0) // Reload the same page
            } else {
              navigateToMockTest(
                navigate,
                getQuestionResponse.data.category_id,
              )
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question or selected options or buyId not found!')
    }
  }

  const handleSkip = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (question && userProfile && userProfile.userId && buyId) {
      try {
        const getQuestionResponse = await doTest1Update(
          buyId,
          question.testId,
          userProfile.userId,
          questionNumber,
          3,
          String(elapsedTime),
          String(elapsedTime),
          totalQuestion,
          'Skip'
        )
      
          if (getQuestionResponse.data.success) {
            localStorage.setItem(
              'resultMockPTE',
              JSON.stringify(getQuestionResponse.data.result),
            )
  
            if (getQuestionResponse.data.category_id === '13') {
              navigate(0) // Reload the same page
            } else {
              navigateToMockTest(
                navigate,
                getQuestionResponse.data.category_id,
              )
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } 
      catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question or buyId not found!')
    }
  }

  return (
    <div className='min-h-[80vh]'>
      {
         isLoading ? (
          <LoadingMessage message='Loading question...'/> 
        ) : errorMsg ? (
          <ErrorMessage message={errorMsg} />
        ) : (
          <div>
            <div className="p-8 rounded-xl shadow mt-8">
              <div className="flex justify-between p-4 mb-4 bg-info rounded-lg text-white text-h5r">
                <p>
                  Reading Section - Multiple-choice, choose multiple answer (#{question?.id})
                </p>
                <p>({questionNumber - 28} Out of 16)</p>
              </div>
              <div className="w-[6.25rem] h-[3.5rem] bg-primary-2 mb-6 rounded-lg flex justify-center items-center mx-auto">
                <p className="text-h2 text-white">{formattedTime}</p>
              </div>
              <p className="text-h4m text-neutrals-1">
                { question?.shortTitle } 
              </p>
              <p className="text-h5r text-neutrals-1 pt-4">
              { question?.description } 
              </p>

              <div className="w-[90%] lg:w-[60%] pt-8 mx-auto">
                <p className="text-h5m">{question?.question}</p>
                {options.map((option: Option, index) => (
                  <div key={index} className="flex items-center mt-4">
                    <p className="text-footnote py-[2px] px-[8px] rounded-full bg-info text-white">
                      {String.fromCharCode(65 + index)}
                    </p>
                    <input
                      type="checkbox"
                      className="mx-2"
                      onChange={() => handleCheckboxChange(option)}
                      checked={selectedOptions.includes(option.options)}
                    />
                    <p className="text-bodyr">{option.options}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex w-full justify-center mt-4">
             <button 
                className={`flex text-bodyr text-white items-center justify-centers gap-2 py-2 px-6 rounded-lg mr-2 
                  ${(selectedOptions.length === 0 || isSubmitted) ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                onClick={() => handleSubmitAnswer()}
                disabled={selectedOptions.length === 0 || isSubmitted}
              >
                Save and Next
                <img src={NextArrowSvg} alt="next" className="md:ml-2" />
              </button>

              <button 
                className={`flex items-center px-4 py-2 rounded-xl 
                  ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
                disabled={isSubmitted}
                onClick={() => handleSkip()}
              >
                <p className="text-bodyr text-white">Skip</p>
              </button>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default MockMultipleMCQuestion
