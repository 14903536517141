import axiosInstance from "../configs/axiosConfig"

export function getUserScoring(userId: number) { 
    const data = {
        user_id: userId
    }

    return axiosInstance.post(`/performance/test`, data)
}

export function progressTracker(userId: number) {
    const data = {
        user_id: userId
    }

    return axiosInstance.post(`/performance/practice`, data)
}