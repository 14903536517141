import { useState } from 'react'
import { useAuth } from '../../providers/AuthProvider'
import { applyStudentCode } from '../../services/user.services'
import SuccessMessageToast from '../Items/SuccessMessageToast'
import ErrorMessageToast from '../Items/ErrorMessageToast'

const ApplyStudentCodeSection = () => {
  const [studentCode, setStudentCode] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const { userProfile } = useAuth()

  const handleSuccessResponse = (message: string) => {
    setSuccessMsg(message)
    setTimeout(() => {
      setSuccessMsg('')
    }, 2000)
  }
  
  const handleErrorResponse = (message: string) => {
    setErrorMsg(message)
    setTimeout(() => {
      setErrorMsg('')
    }, 2000)
  }


  const handleApplyStudentCode = async() => {
    if (userProfile && userProfile.userId) {
      try {
        const response = await applyStudentCode(userProfile.userId, studentCode)
        if (response.data.success) {
          handleSuccessResponse(response.data.message)
        } else {
          handleErrorResponse(response.data.message)
        }
      } catch (error) {
        if (error instanceof Error) {
          handleErrorResponse(error.message)
        } else {
          // Handle other types of errors if necessary
          handleErrorResponse('An unknown error occurred!')
        }
      }
    }
  }

  return (
    <div className="user-profile-section__apply-student-code bg-[#F0F7FF] mx-auto rounded-lg shadow-apply-code-section lg:p-0 py-[1rem] px-2 lg:max-w-[49.5rem] w-full lg:h-[7.25rem] h-full flex flex-col gap-2 justify-center items-center">
      <div className="apply-student-code__detail flex flex-col gap-2 lg:w-[23.438rem] w-full">
        <label htmlFor="apply-code-input" className="text-bodym">
          Apply code for students
        </label>
        <div className="apply-student-code__detail__input-submit flex gap-2 h-[2.375rem]">
          <input
            type="text"
            id="apply-code-input"
            defaultValue={studentCode}
            onChange={(e) => setStudentCode(e.target.value)}
            placeholder="Enter your coupon"
            className="w-[19.5rem] h-full rounded-lg p-[7px] border border-2 border-[#0000000F]"
          />
          <button
            onClick={() => handleApplyStudentCode()}
            disabled={studentCode === ''}
            className={`w-[3.375rem] rounded-lg ${studentCode !== '' ? `bg-info hover:bg-[#1890FF] text-white` : 'bg-gray-200 text-gray-500 cursor-not-allowed'}`}
          >
            Save
          </button>
        </div>
      </div>
      { successMsg !== '' &&  <SuccessMessageToast message={successMsg} /> }
      { errorMsg !== '' &&  <ErrorMessageToast message={errorMsg} /> }
    </div>
  )
}

export default ApplyStudentCodeSection
