import { Link } from 'react-router-dom'
import HistoryDetailsMockSection from '../../components/MockTest/HistoryDetails/HistoryDetailsMockSection'
import Path from '../../routes/Path'

const HistoryDetailsMockPage = () => {
  return (
    <>
      <div className="navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Mock Test /{' '}
          <Link to={Path.testHistoryMock.path}>Test History & Analysis</Link> /
          History Details
        </p>
      </div>
      <div className="w-full lg:min-h-[56rem] h-fit lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <HistoryDetailsMockSection />
      </div>
    </>
  )
}

export default HistoryDetailsMockPage
