const sampleData = [
  {
    id: 1,
    test: 'Multiple-choice, choose multiple answer',
    answer:
      'Sleep is most refreshing before midnight.<br/>The morning exercise supplies the early riser with a reserve of energy that will last until the evening.<br/>',
    yourAnswer:
      'Sleep is most refreshing before midnight.<br/>The morning exercise supplies the early riser with a reserve of energy that will last until the evening.',
    grammar: '0',
    overallScore: '2/2',
  },
  {
    id: 2,
    test: 'Multiple-choice, choose single answer',
    answer: 'Life of immoderation is neither natural nor desirable.',
    yourAnswer: 'Life of immoderation is neither natural nor desirable.',
    grammar: '0',
    overallScore: '1/1',
  },
]

const HistoryDetailsReadingMock = () => {
  // Function to create markup for dangerouslySetInnerHTML
  const createMarkup = (html: string) => {
    return { __html: html }
  }

  return (
    <div className="history-details-reading w-full h-full mt-[1rem] p-2 overflow-x-auto font-landingPrimary">
      <div className="min-w-full inline-block align-middle overflow-hidden">
        <table className="min-w-full table-fixed">
          <thead>
            <tr className="text-bodyr text-neutrals-2 h-[3.75rem]">
              <th className="w-[10rem] p-2" align="left">
                Test
              </th>
              <th className="w-[20rem] p-2" align="left">
                Answer
              </th>
              <th className="w-[20rem] p-2" align="left">
                Your Answer
              </th>
              <th className="w-[5.2rem] p-2" align="left">
                Grammar
              </th>
              <th className="w-[5.2rem] p-2" align="left">
                Overall Score
              </th>
            </tr>
          </thead>
          <tbody>
            {sampleData.map((data, index) => (
              <tr
                key={index}
                className={`text-bodyr ${index % 2 === 0 ? 'bg-[#FAFAFA]' : ''}`}
              >
                <td className="p-2 text-bodym rounded-l-xl">{data.test}</td>
                <td
                  className="p-2 text-bodyr"
                  dangerouslySetInnerHTML={createMarkup(data.answer)}
                ></td>
                <td
                  className="p-2 text-bodyr"
                  dangerouslySetInnerHTML={createMarkup(data.yourAnswer)}
                ></td>
                <td className="p-2 text-bodyr">{data.grammar} </td>
                <td className="p-2 text-bodyr rounded-r-xl">
                  {data.overallScore}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default HistoryDetailsReadingMock
