import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import NextArrowSvg from '../../../images/Speaking/next-arrow.svg'
import { useNavigate } from 'react-router-dom'
import ProgressBar from '../../ProgressBar'
import { ExtendedQuestion } from '../../../models/question.model'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import {
  doTest2Update,
  addResultSpeakingSectional,
} from '../../../services/sectionalTest.services'
import { useAuth } from '../../../providers/AuthProvider'
import {
  handleExceptionError,
  navigateToSectionalTestSpeaking,
  calculateConfidenceMark,
} from '../../../utils/utils'

const SectionalReadAloudQuestion = () => {
  const { userProfile } = useAuth()
  const maxRecordingTime = 40
  const recordingTimeout = 40

  const navigate = useNavigate()
  const [remainingTime, setRemainingTime] = useState(recordingTimeout)
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [recordingTime, setRecordingTime] = useState(maxRecordingTime)
  const [recordingProgress, setRecordingProgress] = useState(0)
  const [transcribedText, setTranscribedText] = useState('') // State for transcribed text
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(
    undefined,
  )
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const recorderRef = useRef<MediaRecorder | null>(null)
  const audioUrlRef = useRef<string | null>(null)
  const myFluency = useRef(5) // Initialize fluency, you can adjust it based on your needs
  const t0 = useRef(0)
  const t1 = useRef(0)

  // Refs to hold the latest values of isRecording and recordingTime
  const isRecordingRef = useRef(isRecording)
  const recordingTimeRef = useRef(recordingTime)

  useEffect(() => {
    isRecordingRef.current = isRecording
  }, [isRecording])

  useEffect(() => {
    recordingTimeRef.current = recordingTime
  }, [recordingTime])

  // Initialize Web Speech API
  // Use useMemo to initialize SpeechRecognition
  const recognition = useMemo(() => {
    if (window.SpeechRecognition || window.webkitSpeechRecognition) {
      return new (window.SpeechRecognition || window.webkitSpeechRecognition)()
    } else {
      console.error('Speech recognition not supported in this browser.')
      return null
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const reseultData = localStorage.getItem('resultSectionalSPTE')
      if (reseultData) {
        const parsedResultData = JSON.parse(reseultData)
        setQuestion(parsedResultData.mockqestion[0])
        setQuestionNumber(parsedResultData.row)
        setTotalQuestion(Number(parsedResultData.allcount))
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [])

  const stopRecognition = useCallback(() => {
    if (recognition) {
      recognition.stop()
    }
  }, [recognition])

  const startRecording = useCallback(async () => {
    setIsRecording(true)
    setRecordingTime(maxRecordingTime) // Set recording time
    setTranscribedText('')

    const beep = new Audio('/sound-beep.mp3')
    beep.play()

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const newRecorder = new MediaRecorder(stream)
      recorderRef.current = newRecorder
      const chunks: BlobPart[] = []

      newRecorder.ondataavailable = (event) => {
        chunks.push(event.data)
      }

      newRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' })
        setAudioBlob(blob) // Update audioBlob state
        audioUrlRef.current = URL.createObjectURL(blob) // Create URL for audio
      }

      newRecorder.start()

      // Start speech recognition if available
      if (recognition) {
        recognition.interimResults = true // Enable interim results
        recognition.start()

        recognition.onresult = (event: any) => {
          for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcript = event.results[i][0].transcript

            // Check if the result is final
            if (event.results[i].isFinal) {
              setTranscribedText((prevText) => prevText + ' ' + transcript) // Append to existing text
            }
          }
          const now = performance.now()
          if (t0.current === 0) {
            // First recognized speech, set the initial timestamp
            t0.current = now
            t1.current = now
          } else {
            // Calculate the pause duration
            const pauseDuration = now - t1.current
            // Apply fluency calculation based on the pause duration
            if (pauseDuration >= 5000) {
              myFluency.current = 0 // Set fluency to 0 for long pauses
            } else {
              if (pauseDuration >= 450 && pauseDuration <= 550) {
                myFluency.current -= 0.1 // Reduce fluency slightly for short pauses
              } else if (pauseDuration >= 551 && pauseDuration <= 590) {
                myFluency.current -= 0.2
              } else if (pauseDuration >= 591 && pauseDuration <= 650) {
                myFluency.current -= 0.3
              } else if (pauseDuration >= 651 && pauseDuration <= 690) {
                myFluency.current -= 0.4
              } else if (pauseDuration >= 691 && pauseDuration <= 750) {
                myFluency.current -= 0.5
              } else if (pauseDuration >= 751) {
                myFluency.current = Math.max(0, myFluency.current - 1) // Maximum fluency reduction
              }
              // Ensure fluency does not go below 0
              myFluency.current = Math.max(0, myFluency.current)
            }

            // Update the timestamp for the next pause
            t1.current = now
          }
        }

        recognition.onerror = (event: any) => {
          console.error('Speech recognition error:', event.error)
          stopRecognition() // Stop recognition in case of an error
        }

        recognition.onend = () => {
          // Only restart recognition if recording is still ongoing
          if (isRecordingRef.current && recordingTimeRef.current > 0) {
            setTimeout(() => {
              if (isRecordingRef.current) {
                try {
                  recognition.start()
                } catch (error) {
                  console.error('Error restarting recognition:', error)
                }
              }
            }, 500) // Delay before restarting
          }
        }
      }
    } catch (error) {
      console.error('Error accessing microphone:', error)
      setIsRecording(false)
      isRecordingRef.current = false // Ensure the ref is updated
    }
  }, [recognition, stopRecognition])

  // Function to handle the submission of the answer
  const handleSubmitAnswer = useCallback(async () => {
    if (question && question.description && userProfile && userProfile.userId) {
      // Calculate confidence mark
      const confidenceMark = calculateConfidenceMark(
        question.description,
        transcribedText,
      )

      const fluencyMark = transcribedText !== '' ? myFluency.current : 0
      const speechDuration = maxRecordingTime - recordingTime

      try {
        const addResultResponse = await addResultSpeakingSectional(
          '132.wav',
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          question.buyId,
          speechDuration,
          transcribedText,
          confidenceMark,
          fluencyMark,
        )

        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest2Update(
            question.buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            1,
            String(speechDuration),
            String(speechDuration),
            totalQuestion,
            'Skip',
          )

          if (getQuestionResponse.data.success) {
            localStorage.setItem(
              'resultSectionalSPTE',
              JSON.stringify(getQuestionResponse.data.result),
            )

            if (getQuestionResponse.data.category_id === '5') {
              navigate(0) // Reload the same page
            } else {
              navigateToSectionalTestSpeaking(
                navigate,
                getQuestionResponse.data.category_id,
              )
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question not found!')
    }
  }, [
    navigate,
    question,
    questionNumber,
    recordingTime,
    totalQuestion,
    transcribedText,
    userProfile,
  ])

  const handleSkip = async () => {
    setIsSubmitted(true)

    if (question && userProfile && userProfile.userId) {
      try {
        const getQuestionResponse = await doTest2Update(
          question.buyId,
          question.testId,
          userProfile.userId,
          questionNumber,
          1,
          String(0),
          String(0),
          totalQuestion,
          'Skip',
        )

        if (getQuestionResponse.data.success) {
          localStorage.setItem(
            'resultSectionalSPTE',
            JSON.stringify(getQuestionResponse.data.result),
          )

          if (getQuestionResponse.data.category_id === '5') {
            navigate(0) // Reload the same page
          } else {
            navigateToSectionalTestSpeaking(
              navigate,
              getQuestionResponse.data.category_id,
            )
          }
        } else {
          console.error(getQuestionResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question not found!')
    }
  }

  // Stop recording function
  const stopRecording = useCallback(() => {
    if (recorderRef.current) {
      recorderRef.current.stop()
    }
    if (recognition) {
      recognition.stop() // Ensure speech recognition is also stopped
    }
    setIsRecording(false)
    setIsSubmitted(true)
    handleSubmitAnswer()
  }, [handleSubmitAnswer, recognition])

  const handleSkipToText = () => {
    setRemainingTime(0)
    if (!isRecording) {
      startRecording()
    }
  }

  // Update the useEffect for remaining time
  useEffect(() => {
    if (remainingTime > 0) {
      const timerId = setTimeout(() => {
        setRemainingTime((prevTime) => {
          if (prevTime === 1 && !isRecording) {
            // Ensure recording starts only once
            startRecording() // Start recording when time runs out
          }
          return prevTime - 1
        })
      }, 1000)
      return () => clearTimeout(timerId)
    }
  }, [remainingTime, startRecording, isRecording, recordingTime])

  useEffect(() => {
    if (isRecording && recordingTime > 0) {
      const intervalId = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime - 1
          setRecordingProgress(
            ((maxRecordingTime - newTime) / maxRecordingTime) * 100,
          )
          return newTime
        })
      }, 1000)
      return () => clearInterval(intervalId)
    } else if (isRecording && recordingTime === 0) {
      // Automatically stop the recording once the time reaches 0
      stopRecording() // Stop recording automatically
    }
  }, [isRecording, recordingTime, stopRecording])

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div>
          {!isRecording && !audioBlob && recordingTime !== 0 && (
            <div className="block md:flex justify-between">
              <div className="flex text-center items-center mb-2 md:mb-0">
                <p className="text-h5m text-neutrals-2 mr-2">Beginning in:</p>
                <p className="text-h3m text-neutrals-1">
                  {remainingTime} seconds
                </p>
              </div>
              <button
                onClick={handleSkipToText}
                className="text-bodyr text-neutrals-1 p-2 rounded-lg border border-[1px]-[#D9D9D9] hover:scale-105"
              >
                Skip to Test
              </button>
            </div>
          )}
          <div className="p-8 rounded-xl shadow mt-8">
            <div className="flex justify-between p-4 mb-4 bg-info rounded-lg text-white text-h5r">
              <p>Speaking Section - Read Aloud (#{question?.id})</p>
              <p>({questionNumber + 1} Out of 26)</p>
            </div>
            <p className="text-h4m text-neutrals-1">{question?.shortTitle}</p>
            <p className="text-h5r text-neutrals-1 pt-4">
              {question?.description}
            </p>
            {isRecording && (
              <div className="bg-[#F1EFF2] text-center p-4 rounded-xl w-[50%] mx-auto mt-4">
                <p className="text-h5r mt-4">Timer: {recordingTime} seconds</p>

                <ProgressBar progress={recordingProgress} />

                <button
                  onClick={stopRecording}
                  className={`text-bodyr text-white p-2 mt-2 rounded-lg hover:scale-105 
                    ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                  disabled={isSubmitted}
                >
                  Save and Next
                </button>
              </div>
            )}
          </div>
          <div className="flex justify-center mt-4">
            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
              onClick={handleSkip}
              disabled={isSubmitted}
            >
              <p className="hidden md:block text-bodyr text-white">Skip</p>
              <img src={NextArrowSvg} alt="next" className="md:ml-2" />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SectionalReadAloudQuestion
