import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Path from '../../../routes/Path'
import { ReactComponent as DropDownArrowIcon } from '../../../images/SectionalTest/st-dropdown-arrow-icon.svg'
import historyTestThumbnail from '../../../images/SectionalTest/history-test-thumbnail.svg'
import historyTestAnalytics from '../../../images/SectionalTest/history-test-analytics.svg'
import LoadingMessage from '../../Items/LoadingMessage'
import ErrorMessage from '../../Items/ErrorMessage'
import UserTestSectional from '../../../models/userTestSectional.model'
import { getTestHistorySectionl } from '../../../services/testHistory.services'
import { useAuth } from '../../../providers/AuthProvider'

const TestHistorySectionalSection = () => {
  const { userProfile } = useAuth()
  const navigate = useNavigate()
  const [testHistorySectional, setTestHistorySectional] = useState<UserTestSectional[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState(1)
  const [isOpen, setIsOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const fetchTestHistory = async () => {
      if (userProfile && userProfile.userId) {
        try {
          const response = await getTestHistorySectionl(userProfile.userId, selectedCategory)
          if (response.data.success) {
            setTestHistorySectional(response.data.test)
          }
        } catch (error) {
          console.error(error)
        } finally {
          setTimeout(() => {
            setIsLoading(false)
          }, 2000)
        }
      } else {
        console.error('User profile is missing or invalid!')
      }
    }

    fetchTestHistory()
  }, [userProfile, selectedCategory])

  const itemsPerPage = 3
  const totalPages = Math.ceil(testHistorySectional.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const currentItems = testHistorySectional.slice(startIndex, startIndex + itemsPerPage)

  const goToPage = (pageNumber: number) => setCurrentPage(pageNumber)

  const categories = [
    { name: 'Speaking', id: 1 },
    { name: 'Writing', id: 2 },
    { name: 'Reading', id: 3 },
    { name: 'Listening', id: 4 },
  ]

  const handleItemClick = (category: { id: number }) => {
    if (category.id !== selectedCategory) {
      setSelectedCategory(category.id)
      setIsLoading(true)
      setCurrentPage(1)
      setIsOpen(false)
    }
  }

  const handleSelectedTestHistory = (test: UserTestSectional) => {
    switch (selectedCategory) {
      case 1:
        navigate(Path.reportHistorySectional.speaking.path.replace(":buyId", String(test.id)))
        break
      case 2:
        navigate(Path.reportHistorySectional.writing.path.replace(":buyId", String(test.id)))
        break
      case 3:
        navigate(Path.reportHistorySectional.reading.path.replace(":buyId", String(test.id)))
        break
      case 4:
        navigate(Path.reportHistorySectional.listening.path.replace(":buyId", String(test.id)))
        break
    }
  }

  return (
    <div>
      <div className="w-full font-landingPrimary">
        <p className="text-h1 mb-[2rem]">Test History & Analysis</p>
      </div>

      {/* Dropdown for mobile view */}
      <div className="lg:hidden lg:mt-[2rem] mt-[1rem] md:w-full w-full relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full h-[2rem] py-[1.2rem] px-[1rem] bg-white border border-1 rounded-lg text-h5r font-landingPrimary flex justify-between items-center"
        >
          {categories.find((category) => category.id === selectedCategory)?.name ?? 'Select Category'}
          <DropDownArrowIcon fill="#00000040" />
        </button>
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white rounded-md shadow-lg">
            {categories.map((category) => (
              <li
                key={category.id}
                onClick={() => handleItemClick(category)}
                className="cursor-pointer px-[12px] py-[5px] text-h5r font-landingPrimary hover:bg-info hover:text-white"
              >
                {category.name}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Navigation tabs for desktop view */}
      <nav className="hidden lg:flex justify-between items-center bg-[#FAFAFA] mt-[1rem] lg:w-[28.188rem] w-full lg:h-[2.875rem] h-[4rem] lg:p-0 p-[0.4rem] rounded-lg text-center">
        <ul className="flex space-x-4 text-h5r font-landingPrimary text-neutrals-2 w-full h-full">
          {categories.map((category) => (
            <li
              key={category.id}
              onClick={() => handleItemClick(category)}
              className={`cursor-pointer flex items-center justify-center flex-grow rounded-lg ${
                selectedCategory === category.id ? 'text-h5r text-neutrals-4 bg-info' : ''
              }`}
            >
              {category.name}
            </li>
          ))}
        </ul>
      </nav>

      {/* Loading state */}
      {isLoading ? (
        <div className="mt-[3rem]">
          <LoadingMessage message="Loading test history sectional..." />
        </div>
      ) : (
        <div className="w-full font-landingPrimary mt-[3rem]">
          {testHistorySectional.length > 0 ? (
            <>
              <div className="grid grid-cols-5 text-h5m text-neutrals-2 my-4 text-center">
                <p>Number</p>
                <p>Thumbnail</p>
                <p>Title</p>
                <p>Created Date</p>
                <p>Actions</p>
              </div>
              <div className="w-full text-center">
                {currentItems.map((test: UserTestSectional, index) => (
                  <div
                    key={test.id}
                    className="grid grid-cols-5 gap-2 bg-white rounded-lg shadow-test-history-card py-4 px-2 mb-4 items-center"
                  >
                    <div className="text-bodyr text-neutrals-1">{index + 1}</div>
                    <div className="flex justify-center items-center">
                      <img src={historyTestThumbnail} alt="Test Thumbnail" className="object-cover" />
                    </div>
                    <div className="justify-center text-bodyr text-neutrals-1">
                      {`Test ${index + 1}`}
                    </div>
                    <div className="justify-center text-bodyr text-neutrals-1">
                      {test.completeDate}
                    </div>
                    <div className="flex justify-center">
                      <button
                        className="bg-info hover:scale-105 hover:cursor-pointer text-bodyr text-white py-2 px-4 rounded-lg"
                        onClick={() => handleSelectedTestHistory(test)}
                      >
                        <div className="flex">
                          <img
                            src={historyTestAnalytics}
                            alt="History Test Analytics"
                            className="mr-1"
                          />
                          <span className="lg:inline hidden">View analytics</span>
                        </div>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <ErrorMessage message='No records found!'/>
          )}

          {/* Pagination */}
          {testHistorySectional.length > 0 && (
            <div className="flex lg:gap-4 gap-2 justify-end items-center mt-4">
              <button
                onClick={() => goToPage(currentPage - 1)}
                disabled={currentPage === 1}
                className={`${currentPage === 1 ? 'text-[#D9D9D9]' : 'hover:text-info'}`}
              >
                {'<'}
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  className={`px-2 py-1 ${
                    currentPage === index + 1
                      ? 'text-[#1890FF] border border-1 border-[#1890FF]'
                      : 'text-gray-700'
                  }`}
                  onClick={() => goToPage(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => goToPage(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`${currentPage === totalPages ? 'text-[#D9D9D9]' : 'hover:text-info'}`}
              >
                {'>'}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default TestHistorySectionalSection
