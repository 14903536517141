import RegistrationForm from '../../components/LandingPage/Auth/RegistrationForm'

const RegisterPage: React.FC = () => {
  return (
    <div className="register-section lg:h-fit h-full bg-[#F5F5F5] flex flex-col justify-center items-center lg:py-[4rem] py-[2rem] lg:px-0 px-[1rem]">
      <p className="text-h1 font-landingLogo text-center mb-4">Sign up</p>
      <p className="text-bodyr lg:w-[18.25rem] font-landingLogo text-center mb-4">
        We provide detailed information about suppliers and distributors
      </p>
      <RegistrationForm />
    </div>
  )
}

export default RegisterPage
