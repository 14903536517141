import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import ErrorMessage from '../../components/Items/ErrorMessage'
import SuccessMessage from '../../components/Items/SuccessMessage'
import { activateAccount } from '../../services/auth.services'

// Custom hook to extract the token from URL query params
const useQueryParam = (key: string) => {
    const location = useLocation()
    return new URLSearchParams(location.search).get(key)
};

const ActivateAccountPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setSuccessMsg] = useState('')

    const token = useQueryParam('token') // Custom hook to get 'token'

    useEffect(() => {
        const activate = async () => {
            if (!token) {
                setErrorMsg('Activation token is missing.')
                setIsLoading(false)
                return
            }

            try {
                const response = await activateAccount(token)
                const { success, message } = response.data;

                if (success) {
                    setSuccessMsg(message)
                } else {
                    setErrorMsg(message)
                }
            } catch (error: any) {
                setErrorMsg(error.message || 'Something went wrong!')
            } finally {
                setIsLoading(false) // Ensure loading state is set to false after the call
            }
        }

        activate()
    }, [token])

    return (
        <div className="min-h-[80vh] w-full bg-[#F5F5F5] flex flex-col justify-center items-center gap-6">
            <p className="text-h1">Account Activation</p>
            {isLoading && <Spinner />}
            {!isLoading && errorMsg && <ErrorMessage message={errorMsg} />}
            {!isLoading && successMsg && <SuccessMessage message={successMsg} />}
        </div>
    );
};

export default ActivateAccountPage