import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PracticeSkillProgressOverallSection from './PracticeSkillProgressOverallSection'
import PracticeSpeakingScoreSection from './PracticeSpeakingScoreSection'
import PracticeWritingScoreSection from './PracticeWritingScoreSection'
import PracticeReadingScoreSection from './PracticeReadingScoreSection'
import PracticeListeningScoreSection from './PracticeListeningScoreSection'
import ProgressTracker from '../../models/progressTracker.model'
import { progressTracker } from '../../services/performance.services'
import { useAuth } from '../../providers/AuthProvider'
import LoadingMessage from '../Items/LoadingMessage'

const PracticePerformanceSection = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [selectedSkill, setSelectedSkill] = useState(1)
  const [progressTrackerInfo, setProgressTrackerInfo] = useState<ProgressTracker | undefined>(undefined)
  const { userProfile } = useAuth()

  useEffect(() => {
    if (userProfile && userProfile.userId) {
      try {
        progressTracker(userProfile.userId)
          .then((response) => {
            if (response.data.success) {
              setProgressTrackerInfo(response.data.result)
            } else {
              console.error(response.data.message)
            }
          })
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      }
    } else {
      console.error('Something wrong!')
    }
  }, [userProfile])

  return (
    <div className="practice-performance-section font-landingPrimary w-full">
      <div className="practice-performance__title w-full flex flex-col justify-center items-center gap-4">
        <p className="text-h1">Practice Performance</p>
        <hr className="w-[17.688rem]" />
        <Link
          to="/my-performance"
          className="w-[11.938rem] h-[1.75rem] text-footnote border border-2 border-info rounded-[6.25rem] flex justify-center items-center hover:scale-105"
        >
          Back To My Performance
        </Link>
      </div>
      {isLoading ? (
        <div className="mt-[2rem]">
          <LoadingMessage message="Loading Practice Performance information ..." />
        </div>
      ) : (
        progressTrackerInfo && (
          <>
            <PracticeSkillProgressOverallSection setSelectedSkill={setSelectedSkill} progressTrackerInfo={progressTrackerInfo} />
            {selectedSkill === 1 && <PracticeSpeakingScoreSection progressTrackerInfo={progressTrackerInfo} />}
            {selectedSkill === 2 && <PracticeWritingScoreSection progressTrackerInfo={progressTrackerInfo} />}
            {selectedSkill === 3 && <PracticeReadingScoreSection progressTrackerInfo={progressTrackerInfo} />}
            {selectedSkill === 4 && <PracticeListeningScoreSection progressTrackerInfo={progressTrackerInfo} />}
          </>
        )
      )}
    </div>
  )
}

export default PracticePerformanceSection
