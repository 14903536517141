import { useNavigate } from 'react-router-dom'
import Test from '../../models/test.model'
import { ReactComponent as MoneyIcon } from '../../images/MyUnlockedTests/myt-money-icon.svg'
import { ReactComponent as DurationIcon } from '../../images/MyUnlockedTests/myt-duration-icon.svg'
import { ReactComponent as QuestionIcon } from '../../images/MyUnlockedTests/myt-question-icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../images/MyUnlockedTests/myt-arrow-right-icon.svg'
import Path from '../../routes/Path'

const MyUnlockedTestCard: React.FC<{ test: Test }> = ({ test }) => {
  const navigate = useNavigate()
  //This will set the buyId and testId to local storage and redirect to startTestNow1
  const handleStartTest = (test: Test) => {
    localStorage.setItem('testIdPTEMock', String(test.id))
    if (test.userTest && typeof test.userTest !== "boolean") {
      localStorage.setItem('buyIdPTEMock', String(test.userTest.id));
    }

    navigate(Path.systemRequirement.mock.path)
  }

  return (
    <div className="test-list__card lg:w-[16.75rem] w-full lg:h-[26.75rem] h-fit lg:pb-0 pb-[1rem] rounded-lg shadow-md">
      <div className="card__image lg:h-[230px] w-full relative">
        <img
          src={test.image}
          alt={test.name}
          className="object-cover w-full h-full rounded-t-lg"
        />
        <div className="card__sale-off absolute top-4 right-4 rounded-[6.25rem] w-[5.25rem] h-[1.25rem] bg-[#A0D911]">
          <p className="text-footnote text-center">{test.discount}% Sale Off</p>
        </div>
      </div>
      <div className="card__information px-[1rem]">
        <p className="card__information__title text-h4m my-[0.6rem]">
          {test.name}
        </p>
        <div className="card__information__description text-bodyr text-neutrals-2 flex flex-col gap-y-2">
          <div className="description__price flex items-center gap-x-3">
            <MoneyIcon />
            <p>{test.price} AUD</p>
          </div>
          <div className="description__duration flex items-center gap-x-3">
            <DurationIcon />
            <p>{test.duration} hrs</p>
          </div>
          <div className="description__question flex items-center gap-x-3">
            <QuestionIcon />
            <p>{test.total_que} questions included</p>
          </div>
        </div>
      </div>
      <div className="card__start-test px-[1rem] mt-[1rem]">
        <button
          onClick={() => handleStartTest(test)}
          className="w-full h-[2.5rem] text-white bg-info hover:bg-[#1890FF] rounded-[7px] flex justify-center items-center gap-x-2"
        >
          <ArrowRightIcon fill="white" />
          Start test
        </button>
      </div>
    </div>
  )
}

export default MyUnlockedTestCard
