import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import ReadAloudSaveAsSvg from '../../images/Speaking/read-aloud-save-as.svg'
import ReadAloudEditSvg from '../../images/Speaking/read-aloud-edit.svg'
import ReadAloudMarkSvg from '../../images/Speaking/read-aloud-mark.svg'
import AudioSectional from '../SectionalTest/AudioSectional'

const question = {
  audio: 'https://ptefilesbucket-stg.s3.amazonaws.com/test_question/0/5bbf462811425.mp3',
  category_id: '18',
  created_at: '2023-04-02 17:50:29',
  description: '',
  dynamic_column_count: '0',
  frequency: '16310',
  highly_repeated: '0',
  id: '1842',
  image: '',
  material_type: '1',
  my_attampt_sort: '0',
  question: 'Which of the following options regarding career counselling are correct?',
  question_quality_sort: '0',
  question_type_sort: '0',
  random_order: '0',
  sequence_category_id: '0',
  short_title: '(Career Counselling) Listening to the recording and answer the question by selecting all the correct responses. You will need to choose more than one response.',
  test_id: '0',
}

const options = [
  {
    alt_option : "", 
    correct: "0", 
    id: "6021", 
    options: "Career counselling has always been under-rated by the parents",
    sequence: "0"
  },
  {
    alt_option : "", 
    correct: "1", 
    id: "6022", 
    options: "Career counselling if sought at the right time may bring unexpected growth in one's career",
    sequence: "0"
  },
  {
    alt_option : "", 
    correct: "1", 
    id: "6023", 
    options: "Career counselling helps gain control over one’s mind and have a clear view of the future.",
    sequence: "0"
  },
  {
    alt_option : "", 
    correct: "0", 
    id: "6024", 
    options: "Career counselling is not only meant for students but also for working professionals",
    sequence: "0"
  },
  {
    alt_option : "", 
    correct: "0", 
    id: "6025", 
    options: "Career and education will keep on having a very strong relationship",
    sequence: "0"
  },
]

const MultipleChoiceChooseMultipleAnswersQuestion = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const navigate = useNavigate();

  const handleReload = () => {
    navigate(0); // this reloads the current route
  };

  const handleToggleSubmitted = () => {
    setIsSubmitted(prevState => !prevState)
  }

  return (
    <div>
      <p className="text-bodym text-danger mt-2">
        * This question carries marks for listening only (1%) for each correct answer
      </p>
      <div className="p-8 rounded-xl shadow mt-8">
        <p className="text-h4m text-neutrals-1 mb-[2rem]">
          {question.short_title}
        </p>
        <AudioSectional audioUrl={question.audio} />
        <p className="text-h5m mx-auto mb-4 w-full px-[2rem]">
          {question.question}
        </p>
        <div className="mx-auto mb-6 w-full px-[2rem]">
          <div className="flex flex-col gap-2 mt-2">
            {options.map((option) => (
              <div
                key={option.id}
                className="options__item flex items-center gap-4"
              >
                <input
                  type="checkbox"
                  id={option.id}
                  name="hcm_options"
                  value={option.id}
                  className="accent-primary-6 w-4 h-4 flex-shrink-0"
                />
                <label 
                  htmlFor={option.id} 
                  className={`flex-1 text-bodyr ${option.correct === "1" && isSubmitted ? 'bg-green-400' : ''}`}
                >
                    {option.options}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center mb-4">
          <p className="text-bodym text-neutrals-1">My note:</p>
          <img
            src={ReadAloudSaveAsSvg}
            alt="save as"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudEditSvg}
            alt="edit"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudMarkSvg}
            alt="mark"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
        </div>
        <div className="w-[90%] lg:w-[60%] mb-4 mx-auto">
          <div className="flex place-content-center mt-8">
            <button 
              className="flex text-bodyr text-white items-center py-2 px-6 bg-success rounded-lg mr-2"
              onClick={handleToggleSubmitted}
            >
              <img
                src={WeeklyPredictionSubmitIcon}
                alt="submit"
                className="mr-2"
              />
              Submit
            </button>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
          <p className="hidden md:block text-bodyr text-white">Previous</p>
        </button>
        <button
          className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
          onClick={handleReload}
        >
          <p className="hidden md:block text-bodyr text-white">Try Again</p>
          <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
        </button>
        <div>
          <select className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y">
            {Array.from({ length: 20 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <p className="hidden md:block text-bodyr text-white">Next</p>
          <img src={NextArrowSvg} alt="next" className="md:ml-2" />
        </button>
      </div>
    </div>
  );
};

export default MultipleChoiceChooseMultipleAnswersQuestion
