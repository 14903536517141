import React, { useState, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import WeeklyPredictionKeywordIcon from '../../images/WeeklyPrediction/weekly-prediction-keyword.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import ReadAloudSaveAsSvg from '../../images/Speaking/read-aloud-save-as.svg'
import ReadAloudEditSvg from '../../images/Speaking/read-aloud-edit.svg'
import ReadAloudMarkSvg from '../../images/Speaking/read-aloud-mark.svg'
import AudioSectional from '../SectionalTest/AudioSectional'

const question = {
  audio: 'https://ptefilesbucket-stg.s3.amazonaws.com/test_question/0/643ab1a58617c.wav',
  category_id: '24',
  created_at: '2023-04-02 17:50:29',
  description: 'Renovation works have been undertaken throughout the building.',
  dynamic_column_count: '0',
  frequency: '60409',
  highly_repeated: '0',
  id: '3016',
  image: '',
  material_type: '1',
  my_attampt_sort: '0',
  question: '',
  question_quality_sort: '0',
  question_type_sort: '0',
  random_order: '0',
  sequence_category_id: '17',
  short_title: 'You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.',
  test_id: '0',
}

const WriteFromDictationQuestion = () => {
  const [wordsCount, setWordsCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleReload = () => {
    navigate(0); // this reloads the current route
  };

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const text = e.target.value;
    const wordCount = text.trim().split(/\s+/).filter(Boolean).length;
    setWordsCount(wordCount)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <div>
      <p className="text-bodym text-danger mt-2">
        * This question carries marks for Writing (~30%) and Listening (~27%)
      </p>
      <div className="p-8 rounded-xl shadow mt-8">
        <p className="text-h4m text-neutrals-1 mb-[2rem]">
          {question.short_title}
        </p>
        <AudioSectional audioUrl={question.audio} />
        <div className="flex flex-col gap-2 mb-4">
          <label htmlFor="swt-answer" className="text-h5b">
            Your answer
          </label>
          <textarea
            className="w-full h-[5rem] text-bodyr border border-1 border-[#D9D9D9] p-2 resize-none"
            placeholder="Write here..."
            onChange={handleTextChange}
          />
        </div>
        <p className="text-body mb-4">
          Words count: {wordsCount}
        </p>
        <div className="flex items-center mb-4">
          <p className="text-bodym text-neutrals-1">My note:</p>
          <img
            src={ReadAloudSaveAsSvg}
            alt="save as"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudEditSvg}
            alt="edit"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudMarkSvg}
            alt="mark"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
        </div>
        <div className="w-[90%] lg:w-[60%] mb-4 mx-auto">
          <div className="flex place-content-center mt-8">
            <button className="flex text-bodyr text-white items-center py-2 px-6 bg-success rounded-lg mr-2">
              <img
                src={WeeklyPredictionSubmitIcon}
                alt="submit"
                className="mr-2"
              />
              Submit
            </button>

            <button
              className="flex text-bodyr text-neutrals-2 items-center p-2 border border-1px-neutrals-2 rounded-lg"
              onClick={toggleModal}
            >
              <img
                src={WeeklyPredictionKeywordIcon}
                alt="keyword"
                className="mr-2"
              />
              Answers
            </button>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
          <p className="hidden md:block text-bodyr text-white">Previous</p>
        </button>
        <button
          className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
          onClick={handleReload}
        >
          <p className="hidden md:block text-bodyr text-white">Try Again</p>
          <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
        </button>
        <div>
          <select className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y">
            {Array.from({ length: 20 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <p className="hidden md:block text-bodyr text-white">Next</p>
          <img src={NextArrowSvg} alt="next" className="md:ml-2" />
        </button>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] md:w-[50%]">
            <h2 className="text-h3m mb-4">Correct Answer</h2>
            <p className="text-bodyr font-bold text-green-500">{question.description}</p>
            <button
              className="mt-4 px-4 py-2 bg-danger text-white rounded-lg"
              onClick={toggleModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default WriteFromDictationQuestion;
