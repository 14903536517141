import React from 'react'
import { ReactComponent as ShopTickIcon } from '../../images/ShopPage/shop-tick-icon.svg'
import { ReactComponent as ShopArrowRightIcon } from '../../images/ShopPage/shop-right-arrow-icon.svg'

interface PracticeMockTestBundleSectionProps {
  shopDeals: any
}

const PracticeMockTestBundleSection: React.FC<
  PracticeMockTestBundleSectionProps
> = ({ shopDeals }) => {
  return (
    <div className="practice-mocktest-bundle-section lg:w-full md:w-1/2 w-full flex flex-wrap lg:gap-y-[2rem] gap-y-[2rem] lg:gap-x-[1.6rem]">
      {shopDeals.packages.map((pack: any, index: number) => {
        const isLast = index === shopDeals.packages.length - 1
        return (
          <div
            key={pack.id}
            className={`lg:w-[20.375rem] w-full border border-2 ${isLast ? 'bg-primary-6 border-[#0000000F]' : 'border-[#0000000F]'} rounded-3xl p-4`}
          >
            <div className="title flex justify-between items-center">
              <p
                className={`text-h3r ${isLast ? 'text-white' : 'text-info'} font-landingPrimary`}
              >
                {pack.title}
              </p>
            </div>
            <div className="price mt-[1rem]">
              <p
                className={`text-h1 ${isLast ? 'text-white' : 'text-info'} font-landingPrimary flex items-center gap-x-4`}
              >
                AUD {pack.price}
              </p>
            </div>
            <div
              className={`feature-details ${isLast ? 'text-white' : ''} font-landingPrimary my-[1rem]`}
            >
              <p className="text-bodym">Features</p>
              <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
                <ShopTickIcon fill={isLast ? 'white' : 'black'} />
                <p className="text-bodyb">{pack.tokenArr[0].tkPkgName}</p>
              </div>
              <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
                <ShopTickIcon fill={isLast ? 'white' : 'black'} />
                <p className="text-bodyr">Scoring request</p>
              </div>
              <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
                <ShopTickIcon fill={isLast ? 'white' : 'black'} />
                <p className="text-bodyr">Download 90/90 templates</p>
              </div>
              <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
                <ShopTickIcon fill={isLast ? 'white' : 'black'} />
                <p className="text-bodyr">Up to 5 answers per question</p>
              </div>
              <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
                <ShopTickIcon fill={isLast ? 'white' : 'black'} />
                <p className="text-bodyr">Reveal keyword</p>
              </div>
              <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
                <ShopTickIcon fill={isLast ? 'white' : 'black'} />
                <p className="text-bodyr">Download prediction</p>
              </div>
              <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
                <ShopTickIcon fill={isLast ? 'white' : 'black'} />
                <p className="text-bodyr">Sectional Test</p>
              </div>
              <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
                <ShopTickIcon fill={isLast ? 'white' : 'black'} />
                <p className="text-bodyr">
                  {pack.mockCreditArr[0].tkPkgCredits} Mock Test Credits
                </p>
              </div>
              <div className="flex gap-x-2 w-full my-[0.4rem]">
                <div
                  className={`text-bodyb ${isLast ? 'text-white' : ''}`}
                  dangerouslySetInnerHTML={{ __html: pack.description }}
                />
              </div>
            </div>
            <hr
              className={`my-[0.4rem] border border-1 ${isLast ? 'border-[#FFFFFF4D]' : 'border-[#0000000F]'}`}
            />
            <div className="purchase w-full mt-[1rem] flex flex-col gap-y-2">
              {isLast ? (
                <button className="w-full px-[15px] py-[6.4px] border border-info hover:border-transparent bg-info hover:bg-[#1890FF] text-white text-h5r rounded-lg flex justify-center items-center gap-x-4">
                  <ShopArrowRightIcon fill="white" />
                  Buy Now
                </button>
              ) : (
                <button className="w-full px-[15px] py-[6.4px] border border-[#000000D9] hover:border-transparent hover:bg-info text-h5r hover:text-white rounded-lg flex justify-center items-center gap-x-4">
                  <ShopArrowRightIcon fill="currentColor" />
                  Buy Now
                </button>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PracticeMockTestBundleSection
