import logo from '../images/Footer/logo-svg.svg'
import facebookIcon from '../images/Footer/facebook-icon.svg'
import twitterIcon from '../images/Footer/twitter-icon.svg'
import instagramIcon from '../images/Footer/instagram-icon.svg'
import githubIcon from '../images/Footer/github-icon.svg'
const Footer = () => {
  return (
    <footer className="w-full bg-primary-6 text-white">
      <div className="flex gap-4 py-6 px-2 w-full sm:w-[90%] mx-auto justify-between items-center">
        <div>
          <img src={logo} alt="Logo" className="w-[170px] h-[70px]" />
          <div className="text-bodyr">
            <p>
              <b>Email:</b>
              <span> </span>
              admin@masterpte.com.au
            </p>
            <p>
              <b>Address:</b>
              <span> </span>
              Level 36 Gateway, 1 Macquarie Place
            </p>
            <p>Sydney NSW 2000 Sydney, Australia</p>
            <p>
              <b>Contact Number:</b> <span> </span>
              0411 703 756
            </p>
          </div>

          <div className="flex gap-4 pt-4">
            <img src={facebookIcon} alt="Facebook" />
            <img src={twitterIcon} alt="Twitter" />
            <img src={instagramIcon} alt="Instagram" />
            <img src={githubIcon} alt="Github" />
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          <p className="text-h5b">Company</p>
          <div className="text-bodyr flex flex-col gap-1">
            <p>About us</p>
            <p>Careers</p>
            <p>Support</p>
            <p>Testimonials</p>
            <p>Terms of Service</p>
            <p>Privacy Policy</p>
            <p>Cookie Policy</p>
          </div>
        </div>
      </div>

      <div className="bg-primary-9 text-white py-4">
        <div className="w-[90%] mx-auto flex flex-col sm:flex-row justify-between">
          <p>© 2024 PTE Master. All rights reserved.</p>
          <p>Contact us: admin@masterpte.com.au</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
