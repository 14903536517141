import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowRightIcon } from '../../images/Landingpage/arrow-right-icon.svg'
import PTEOnlineImage from '../../images/Landingpage/pte-online-image.svg'

const CTASection: React.FC = () => {
  const pteFeatureLists = [
    'Full PTE Academic practice tests',
    'Model Answers',
    'Real PTE Exam based Sample questions',
    'PTE Ebook',
    'PTE Practice Sectional Tests',
    'PTE Video tutorials',
    'Developed by PTE certified trainers',
    'Scored AI PTE Mock Test',
    'PTE Academic Study Plan',
  ]

  return (
    <div className="cta-section lg:px-[8rem] px-0 py-[2rem] lg:h-[43rem] h-full flex justify-between">
      <div className="cta-section__main w-full lg:h-[40rem] h-fit bg-[#E3EBFF] rounded-t-xl">
        <div className="cta-section__main__first lg:h-[17.75rem] h-fit lg:px-[4rem] px-[2rem] py-[2rem] bg-info rounded-xl grid grid-cols-2 gap-y-4">
          <div className="col-span-2 md:col-span-1 font-landingPrimary text-neutrals-4 mx-auto">
            <p className="text-footnote">PTE Master</p>
            <p className="text-h3m">ONLINE PTE PRACTICE</p>
            <p className="text-bodyr w-2/3 mb-[2rem]">
              One of the best PTE Academic Exam preparation tools to evaluate
              your performance
            </p>
            <Link
              to="/"
              className="px-4 py-2 block w-[8.438rem] text-bodyr text-info bg-neutrals-4 rounded-md hover:scale-105"
            >
              Get Started Now
            </Link>
            <Link
              to="/"
              className="text-neutrals-4 block text-bodyr block mt-[1rem] w-fit border-b border-white flex gap-x-4 hover:scale-105"
            >
              See our features
              <ArrowRightIcon fill="white" />
            </Link>
          </div>
          <div className="col-span-2 md:col-span-1">
            <img
              src={PTEOnlineImage}
              alt="PTE Online"
              className='mx-auto'
            />
          </div>
        </div>
        <div className="cta-section__main__second lg:h-[22.25rem] lg:pl-[3rem] py-[2rem] flex flex-wrap justify-between">
          <div className="lg:w-1/2 w-full text-neutrals-1 font-bodyr font-landingPrimary flex flex-wrap gap-y-2">
            {pteFeatureLists.map((feature, index) => (
              <div key={index} className="w-1/2 flex">
                <ArrowRightIcon fill="black" className="w-1/5" />
                <p className="w-4/5">{feature}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTASection
