import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { signInWithGoogle } from '../../../configs/firebase'
import { ReactComponent as GoogleIcon } from '../../../images/Landingpage/google-icon.svg'
import ErrorMessageToast from '../../Items/ErrorMessageToast'
import SuccessMessageToast from '../../Items/SuccessMessageToast'
import Path from '../../../routes/Path'
import { useAuth } from '../../../providers/AuthProvider'

interface GoogleLoginButtonProps {
  disabled: boolean
  setDisabled: (value: boolean) => void
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  disabled,
  setDisabled
}) => {
  const [displayMsg, setDisplayMsg] = useState(true)
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const { socialLogin } = useAuth()
  const navigate = useNavigate()

  const handleGoogleSignIn = async () => {
    try {
      const user = await signInWithGoogle()
  
      if (user.email && user.displayName && user.photoURL) {
        await handleSocialLogin(user.email, user.displayName, user.photoURL)
      }
    } catch (error) {
      setDisabled(false)
      console.error('Error during Google sign-in:', error)
    }
  };
  
  const handleSocialLogin = async (email: string, displayName: string, photoURL: string) => {
    try {
      await socialLogin(email, displayName, photoURL);
      setDisabled(true)
      setSuccessMsg('Login successful!')
      
      setTimeout(() => {
        setSuccessMsg('')
        navigate(Path.home.path)
      }, 3000)
    } catch (error: any) {
      setDisabled(false)
      setErrorMsg(error.message)
    } finally {
      setDisplayMsg(true)
      setTimeout(() => {
        setErrorMsg('')
        setSuccessMsg('')
        setDisplayMsg(false)
      }, 3000)
    }
  }

  return (
    <>
      <button
        className={`w-full text-bodyr font-landingPrimary px-[0.688rem] py-[0.938rem] border border-2 border-[#0000000F] flex justify-center items-center gap-x-4 rounded-lg ${disabled ? 'text-gray-100 bg-gray-300 cursor-not-allowed' : 'hover:bg-[#1890FF] hover:text-white'}`}
        onClick={handleGoogleSignIn}
        disabled={disabled}
      >
        <GoogleIcon /> Continue with Google
      </button>
      { displayMsg && successMsg !== '' &&  <SuccessMessageToast message={successMsg} /> }
      { displayMsg && errorMsg !== '' &&  <ErrorMessageToast message={errorMsg} /> } 
    </>
  )
}

export default GoogleLoginButton
