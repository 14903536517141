import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ReportProgressOverallSectionalSection from './ReportProgressOverallSectionalSection'
import { useAuth } from '../../../providers/AuthProvider'
import { progressTrackerSectionalReading } from '../../../services/testHistory.services'
import LoadingMessage from '../../Items/LoadingMessage'

const ReportHistoryReadingSectionalSection = () => {
  let { buyId } = useParams()
  const { userProfile } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [readingScoreOverall, setReadingScoreOverall] = useState(0)

  useEffect(() => {
    try {
      const fetchProgressTrackerSectionalReading = async () => {
        if (userProfile && userProfile.userId && !isNaN(Number(buyId))) {
          try {
            const response = await progressTrackerSectionalReading(userProfile.userId, Number(buyId))
            if (response.data.success) {
              let totalScore = response.data.total_score > 0 ? Math.round(response.data.total_score * 90 / 61) : 0
              const remainigMarks = 90 - totalScore

              if (remainigMarks > 10) {
                totalScore = totalScore + 10
              } else {
                totalScore = totalScore + remainigMarks
              }

              totalScore = totalScore > 90 ? 90 : Math.round(totalScore)
              setReadingScoreOverall(totalScore)
            }
          } catch (error) {
            console.error(error)
          } finally {
            setTimeout(() => {
              setIsLoading(false)
            }, 2000)
          }
        } else {
          console.error('User profile is missing or invalid or buy id is invalid!')
        }
      }
  
      fetchProgressTrackerSectionalReading()
    } catch (error) {
      console.error(error)
    }
  }, [userProfile, buyId])

  const overallScores = [
    {
      id: 1,
      skill: 'Overall Reading Score',
      score: readingScoreOverall,
    },
  ]

  return (
    <div className="report-history-reading-sectional-section">
      <p className="text-h1 font-landingPrimary mb-[0.4rem]">Report History</p>
      {
        isLoading ? (
          <div className='mt-[3rem]'>
            <LoadingMessage message='Loading report history sectional reading...'/>
          </div>
        ) : (
          <ReportProgressOverallSectionalSection overallScoreInfo={overallScores} />
        )
      }
    </div>
  )
}

export default ReportHistoryReadingSectionalSection
