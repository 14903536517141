import { Link } from 'react-router-dom'
import TestHistorySectionalSection from '../../components/SectionalTest/TestHistory/TestHistorySectionalSection'

const TestHistorySectionalPage = () => {
  return (
    <>
      <div className="navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Sectional Test / Test History & Analysis
        </p>
      </div>
      <div className="w-full min-h-[80vh] lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <TestHistorySectionalSection />
      </div>
    </>
  )
}

export default TestHistorySectionalPage