import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import NextArrowSvg from '../../../images/Speaking/next-arrow.svg'
import { useNavigate } from 'react-router-dom'
import ProgressBar from '../../ProgressBar'
import { ExtendedQuestion } from '../../../models/question.model'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import {
  doTest2Update,
  addResultSpeakingSectional,
} from '../../../services/sectionalTest.services'
import { useAuth } from '../../../providers/AuthProvider'
import {
  handleExceptionError,
  navigateToSectionalTestSpeaking,
  setupSpeechRecognition,
} from '../../../utils/utils'

const SectionalReTellLectureQuestion = () => {
  const { userProfile } = useAuth()
  const maxRecordingTime = 40
  const recordingTimeout = 6

  const navigate = useNavigate()
  const [initialDelay, setInitialDelay] = useState<null | number>(
    recordingTimeout,
  )
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [recordingTime, setRecordingTime] = useState(maxRecordingTime)
  const [audioProgress, setAudioProgress] = useState(0)
  const [recordingProgress, setRecordingProgress] = useState(0)
  const audioRef = useRef<HTMLAudioElement>(null)
  const recorderRef = useRef<MediaRecorder | null>(null)
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(
    undefined,
  )
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [transcribedText, setTranscribedText] = useState('') // Transcription state
  const [audioPlayError, setAudioPlayError] = useState(false)
  const [preRecordingCountdown, setPreRecordingCountdown] = useState<
    number | undefined
  >(undefined)

  const myFluency = useRef(5) // Initialize fluency, you can adjust it based on your needs
  const t0 = useRef(0)
  const t1 = useRef(0)

  const recordingTimeDurationRef = useRef<number>(0)

  useEffect(() => {
    setTimeout(() => {
      const reseultData = localStorage.getItem('resultSectionalSPTE')
      if (reseultData) {
        const parsedResultData = JSON.parse(reseultData)
        setQuestion(parsedResultData.mockqestion[0])
        setQuestionNumber(parsedResultData.row)
        setTotalQuestion(Number(parsedResultData.allcount))
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [])

  // Use useMemo to initialize SpeechRecognition
  // Use useMemo to initialize SpeechRecognition
  const recognition = useMemo(() => setupSpeechRecognition(), [])
  if (recognition) {
    recognition.onresult = (event: any) => {
      const interimTranscript = Array.from(event.results)
        .map((result: any) => result[0].transcript)
        .filter((text: string) => text.trim() !== '')
        .join(' ')
      // Throttle the transcription state update to avoid overloading the render cycle
      setTranscribedText((prevText) => {
        if (prevText !== interimTranscript) {
          return interimTranscript // Update only when transcript changes
        }
        return prevText
      })

      const now = performance.now()
      if (t0.current === 0) {
        // First recognized speech, set the initial timestamp
        t0.current = now
        t1.current = now
      } else {
        // Calculate the pause duration
        const pauseDuration = now - t1.current
        // Apply fluency calculation based on the pause duration
        if (pauseDuration >= 5000) {
          myFluency.current = 0 // Set fluency to 0 for long pauses
        } else {
          if (pauseDuration >= 450 && pauseDuration <= 550) {
            myFluency.current -= 0.1 // Reduce fluency slightly for short pauses
          } else if (pauseDuration >= 551 && pauseDuration <= 590) {
            myFluency.current -= 0.2
          } else if (pauseDuration >= 591 && pauseDuration <= 650) {
            myFluency.current -= 0.3
          } else if (pauseDuration >= 651 && pauseDuration <= 690) {
            myFluency.current -= 0.4
          } else if (pauseDuration >= 691 && pauseDuration <= 750) {
            myFluency.current -= 0.5
          } else if (pauseDuration >= 751) {
            myFluency.current = Math.max(0, myFluency.current - 1) // Maximum fluency reduction
          }
          // Ensure fluency does not go below 0
          myFluency.current = Math.max(0, myFluency.current)
        }

        // Update the timestamp for the next pause
        t1.current = now
      }
    }

    recognition.onerror = (event: any) => {
      console.error('Speech recognition error:', event.error)
    }
  }

  const startRecording = useCallback(() => {
    if (!isRecording && !audioBlob) {
      setIsRecording(true)
      setRecordingProgress(0)
      setTranscribedText('') // Clear transcript before starting

      const beep = new Audio('/sound-beep.mp3')
      beep.play()

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const newRecorder = new MediaRecorder(stream)
          recorderRef.current = newRecorder
          const chunks: BlobPart[] = []

          newRecorder.ondataavailable = (event) => chunks.push(event.data)
          newRecorder.onstop = () => {
            setAudioBlob(new Blob(chunks, { type: 'audio/wav' }))
            setRecordingProgress(100)
          }

          newRecorder.start()

          // Start SpeechRecognition for transcription
          if (recognition) {
            recognition.start()
          }
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error)
        })
    }
  }, [isRecording, audioBlob, recognition])

  // Function to handle the submission of the answer
  const handleSubmitAnswer = useCallback(async () => {
    setIsSubmitted(true)

    if (question && userProfile && userProfile.userId) {
      const fluencyMark = transcribedText !== '' ? myFluency.current : 0
      const speechDuration = recordingTimeDurationRef.current

      try {
        const addResultResponse = await addResultSpeakingSectional(
          '132.wav',
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          question.buyId,
          speechDuration,
          transcribedText,
          0,
          fluencyMark,
        )

        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest2Update(
            question.buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            1,
            String(speechDuration),
            String(speechDuration),
            totalQuestion,
            'Skip',
          )

          if (getQuestionResponse.data.success) {
            localStorage.setItem(
              'resultSectionalSPTE',
              JSON.stringify(getQuestionResponse.data.result),
            )

            if (getQuestionResponse.data.category_id === '8') {
              navigate(0) // Reload the same page
            } else {
              navigateToSectionalTestSpeaking(
                navigate,
                getQuestionResponse.data.category_id,
              )
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question not found!')
    }
  }, [
    navigate,
    question,
    questionNumber,
    totalQuestion,
    transcribedText,
    userProfile,
  ])

  const handleSkip = async () => {
    setIsSubmitted(true)

    if (question && userProfile && userProfile.userId) {
      try {
        const getQuestionResponse = await doTest2Update(
          question.buyId,
          question.testId,
          userProfile.userId,
          questionNumber,
          1,
          String(0),
          String(0),
          totalQuestion,
          'Skip',
        )

        if (getQuestionResponse.data.success) {
          localStorage.setItem(
            'resultSectionalSPTE',
            JSON.stringify(getQuestionResponse.data.result),
          )

          if (getQuestionResponse.data.category_id === '8') {
            navigate(0) // Reload the same page
          } else {
            navigateToSectionalTestSpeaking(
              navigate,
              getQuestionResponse.data.category_id,
            )
          }
        } else {
          console.error(getQuestionResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question not found!')
    }
  }

  // Stop recording and speech recognition
  const stopRecording = useCallback(() => {
    if (recorderRef.current) {
      recorderRef.current.stop()
    }
    if (recognition) {
      recognition.stop() // Stop transcription
    }
    setIsRecording(false)
    setRecordingTime(40)
    setRecordingProgress(100)
    handleSubmitAnswer()
  }, [handleSubmitAnswer, recognition])

  useEffect(() => {
    const audioElement = audioRef.current

    // Check if the audioRef is assigned correctly
    if (!audioElement) {
      // console.error("Audio element is not available");
      return
    }

    const handleAudioEnd = () => {
      setPreRecordingCountdown(10)
    }

    const updateProgress = () => {
      if (audioElement && audioElement.duration > 0) {
        const progress =
          (audioElement.currentTime / audioElement.duration) * 100
        setAudioProgress(progress)
      } else {
        console.log('Audio duration not available yet')
      }
    }

    const handleMetadataLoaded = () => {
      setAudioProgress(0) // Reset progress when the audio is loaded
      audioElement.addEventListener('timeupdate', updateProgress)
      audioElement.addEventListener('ended', handleAudioEnd)
    }

    audioElement.addEventListener('loadedmetadata', handleMetadataLoaded)

    return () => {
      audioElement.removeEventListener('loadedmetadata', handleMetadataLoaded)
      audioElement.removeEventListener('timeupdate', updateProgress)
      audioElement.removeEventListener('ended', handleAudioEnd)
    }
  }, [question, startRecording])

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (isRecording) {
      intervalId = setInterval(() => {
        setRecordingTime((prevTimer) => {
          if (prevTimer > 0) {
            recordingTimeDurationRef.current += 1
            const newTime = prevTimer - 1
            setRecordingProgress(
              ((maxRecordingTime - newTime) / maxRecordingTime) * 100,
            )
            return newTime
          } else {
            clearInterval(intervalId)
            stopRecording()
            return prevTimer
          }
        })
      }, 1000)
    }
    return () => clearInterval(intervalId)
  }, [isRecording, stopRecording])

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (initialDelay && initialDelay > 0) {
      timerId = setTimeout(() => setInitialDelay(initialDelay - 1), 1000)
    } else if (initialDelay === 0) {
      audioRef.current?.play().catch(() => {
        setAudioPlayError(true) // Set error state if play fails
      })
      setInitialDelay(null)
    }
    return () => clearTimeout(timerId)
  }, [initialDelay])

  useEffect(() => {
    let countdownTimer: NodeJS.Timeout
    if (preRecordingCountdown && preRecordingCountdown > 0) {
      countdownTimer = setTimeout(
        () => setPreRecordingCountdown(preRecordingCountdown - 1),
        1000,
      )
    } else if (preRecordingCountdown === 0) {
      setPreRecordingCountdown(undefined)
      startRecording() // Start recording when countdown reaches 0
    }
    return () => clearTimeout(countdownTimer)
  }, [preRecordingCountdown, startRecording])

  const handlePlayAudio = () => {
    audioRef.current?.play().catch(() => {
      setAudioPlayError(true) // Set error state if play fails
    })
  }

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <>
          <div className="p-8 rounded-xl shadow mt-8">
            <div className="flex justify-between p-4 mb-4 bg-info rounded-lg text-white text-h5r">
              <p>Speaking Section - Retell Lecture (#{question?.id})</p>
              <p>({questionNumber + 1} Out of 26)</p>
            </div>
            <p className="text-h4m text-neutrals-1">{question?.shortTitle}</p>
            {!isRecording && !audioBlob && (
              <div className="bg-[#F1EFF2] text-center p-4 rounded-xl w-[50%] mx-auto mt-4">
                {initialDelay ? (
                  <p className="text-h4m text-neutrals-1">
                    Audio will start in: {initialDelay} seconds
                  </p>
                ) : preRecordingCountdown ? (
                  <p className="text-h4m text-neutrals-1">
                    Recording will start in: {preRecordingCountdown} seconds
                  </p>
                ) : audioPlayError ? (
                  <p className="text-red-600 text-footnote w-full text-center">
                    Oops! It seems the audio didn't start automatically. No
                    worries—just click{' '}
                    <button
                      onClick={handlePlayAudio}
                      className="underline text-blue-600 bg-yellow-200 px-2 rounded-lg"
                    >
                      Here
                    </button>{' '}
                    to play it manually!
                  </p>
                ) : (
                  <p className="text-h4m text-neutrals-1">Original Audio</p>
                )}
                <audio
                  ref={audioRef}
                  src={question && question.audio ? question.audio : ''}
                  preload="auto"
                  className="my-4 w-full"
                ></audio>
                <ProgressBar progress={audioProgress} />
              </div>
            )}
            {isRecording && (
              <div className="bg-[#F1EFF2] text-center p-4 rounded-xl w-[50%] mx-auto mt-4">
                <p className="text-h5r mt-4">Timer: {recordingTime} seconds</p>

                <ProgressBar progress={recordingProgress} />

                <button
                  onClick={stopRecording}
                  className={`text-bodyr text-white p-2 mt-2 rounded-lg hover:scale-105 
                    ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                  disabled={isSubmitted}
                >
                  Save and Next
                </button>
              </div>
            )}
          </div>
          <div className="flex justify-center mt-4">
            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
              onClick={handleSkip}
              disabled={isSubmitted}
            >
              <p className="hidden md:block text-bodyr text-white">Skip</p>
              <img src={NextArrowSvg} alt="next" className="md:ml-2" />
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default SectionalReTellLectureQuestion
