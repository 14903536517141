import SectionalRepeatSentenceQuestion from '../../../components/SectionalTest/Speaking/RepeatSentenceQuestion'

const SectionalRepeatSentence = () => {
  return (
    <div>
      <SectionalRepeatSentenceQuestion />
    </div>
  )
}

export default SectionalRepeatSentence
