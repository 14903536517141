import { useForm } from 'react-hook-form'
import { useState } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import ErrorMessageToast from '../Items/ErrorMessageToast'
import { checkoutStripe } from '../../services/shop.services'
import { useAuth } from '../../providers/AuthProvider'
import Path from '../../routes/Path'
import { useNavigate } from 'react-router-dom'

interface Props {
  total: string
}

interface FormData {
  userEmail: string
}

const CheckoutForm: React.FC<Props> = ({ total }) => {
  const [errorMsg, setErrorMsg] = useState('')
  const stripe = useStripe()
  const elements = useElements()
  const { userProfile } = useAuth()
  const navigate = useNavigate()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>()

  // Customize the CardElement
  const ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        lineHeight: '22px',
        backgroundColor: 'white',
        '::placeholder': {
          color: '#00000073',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }

  const onSubmit = async (data: FormData) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      setErrorMsg("Stripe is not loaded yet, please try again later!")
      setTimeout(() => {
        setErrorMsg('')
      }, 2000)
      return
    }

    const cardNumberElement = elements.getElement(CardNumberElement)
    const cardExpiryElement = elements.getElement(CardExpiryElement)
    const cardCvcElement = elements.getElement(CardCvcElement)

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      setErrorMsg("Card Element not found!")
      setTimeout(() => {
        setErrorMsg('')
      }, 2000)
      return
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: userProfile?.name || 'Default username', //Will be changed based on username and email
        email: data.userEmail,
      },
    })

    if (error) {
      setErrorMsg(error.message || "An unknown error occurred. Please try again.")
      setTimeout(() => {
        setErrorMsg('')
      }, 2000)
    } else {
      console.log('[PaymentMethod]', paymentMethod)
      // Process paymentMethod further
      if (userProfile && userProfile.userId) {
        try {
          const response = await checkoutStripe(userProfile.userId, paymentMethod.id)
          if (response.data.success) {
            navigate(Path.purchaseSucceed.path)
          } else {
            setErrorMsg(response.data.message)
          }
        } catch (error) {
          if (error instanceof TypeError) {
            setErrorMsg(error.message)
          } else {
            setErrorMsg('Something wrong!')
          }
        } finally {
          setTimeout(() => {
            setErrorMsg('')
          }, 2000)
        }
      } else {
        console.log(userProfile)
        setErrorMsg('Something wrong!')
        setTimeout(() => {
          setErrorMsg('')
        }, 2000)
      }
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="checkout-form-details w-full lg:min-h-[23.625rem] h-full font-landingPrimary bg-[#F0F7FF] p-4 rounded-lg flex flex-col gap-4 relative"
      >
        <div className="checkout-form-details__title text-h3m">Payment Info</div>
        <div className="checkout-form-details__total-price flex justify-between items-center border-y-2 border-[#0000000F] w-full h-[2.875rem]">
          <p className="text-bodym text-neutrals-2">Total</p>
          <p className="text-bodyb">AUD ${total}</p>
        </div>
        <div className="checkout-form-details__customer-email flex flex-col gap-2">
          <label htmlFor="customer-email" className="text-bodym block">
            Card holder name
          </label>
          <input
            id="customer-email"
            type="text"
            placeholder="Email address"
            className={`rounded-lg shadow-cart-checkout-input h-[2.375rem] p-[0.438rem] ${errors.userEmail ? 'border border-1 border-red-500' : ''}`}
            {...register('userEmail', {
              required: 'Please enter your email address!',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address!',
              },
            })}
          />
          {errors.userEmail && (
            <div className="text-red-500 text-bodyr">
              {errors.userEmail.message}
            </div>
          )}
        </div>
        <div className="checkout-form-details__stripe-info flex gap-3">
          <div className="stripe-info__card-number lg:w-[18.25rem] w-full flex flex-col gap-2">
            <label htmlFor="stripe__card-number" className="text-bodym">
              Card number
            </label>
            <CardNumberElement
              id="stripe__card-number"
              options={ELEMENT_OPTIONS}
              className="text-bodyr bg-white shadow-cart-checkout-input p-2 border border-1 border-[#0000000F] rounded w-full mb-4"
            />
          </div>
          <div className="stripe-info__expired-date lg:w-[4.875rem] w-full flex flex-col gap-2">
            <label htmlFor="stripe__expired-date" className="text-bodym">
              Expired date
            </label>
            <CardExpiryElement
              id="stripe__expired-date"
              options={ELEMENT_OPTIONS}
              className="text-bodyr bg-white shadow-cart-checkout-input p-2 border border-1 border-[#0000000F] rounded w-full mb-4"
            />
          </div>
          <div className="stripe-info__cvc lg:w-[3.4rem] w-full flex flex-col gap-2">
            <label htmlFor="stripe__cvc" className="text-bodym">
              CVC
            </label>
            <CardCvcElement
              id="stripe__cvc"
              options={ELEMENT_OPTIONS}
              className="text-bodyr bg-white shadow-cart-checkout-input p-2 border border-1 border-[#0000000F] rounded w-full mb-4"
            />
          </div>
        </div>
        <div className="checkout-form-details__stripe-checkout flex justify-end w-full">
          <button
            type="submit"
            disabled={!stripe}
            className="bg-info hover:bg-[#1890FF] lg:w-[6.563rem] w-full h-[2.75rem] text-white text-bodyr rounded-lg shadow-cart-checkout-pay"
          >
            Check out
          </button>
        </div>
      </form>
      { errorMsg !== '' && <ErrorMessageToast message={errorMsg}/>}
    </>
  )
}

export default CheckoutForm
