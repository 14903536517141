import React from 'react'
import FeatureChartIcon from '../../images/Landingpage/feature-chart.png'
import FeatureQuestionIcon from '../../images/Landingpage/feature-question.png'
import FeatureMockIcon from '../../images/Landingpage/feature-mock.png'

const FeatureSection: React.FC = () => {
  const firstFeatureItems = [
    { number: '5500+', content: 'Question for practice' },
    { number: '20+', content: 'AI score mock tests' },
    { number: '60000+', content: 'Users' },
  ]

  const secondFeatureItems = [
    {
      icon: FeatureChartIcon,
      title: 'ANALYSE YOUR PTE ACADEMIC SCORE',
      description:
        'Complete the AI pte academic mock test and review it again in the analysis section, narrow down your weak areas and smash the exam.',
    },
    {
      icon: FeatureQuestionIcon,
      title: 'PTE PRACTICE QUESTIONS',
      description:
        'Practice on real PTE Academic exam questions updated weekly, 100% chance they will appear in the real exam',
    },
    {
      icon: FeatureMockIcon,
      title: 'MOCK EXAM, REAL RESULT',
      description:
        'The score you receive will be 90-100% similar to your real exam, if you can pass the pte academic mock test then you can also pass the real exam.',
    },
  ]

  return (
    <div className="feature-section">
      <div className="feature-section__first pb-8 font-landingPrimary px-8 xl:w-[90%] mx-auto flex md:flex-row flex-col lg:gap-[1rem] gap-[2rem] justify-between items-center">
        {firstFeatureItems.map((item, index) => (
          <div
            key={index}
            className="feature-section__first__items lg:px-[4rem] px-[2rem] lg:py-[4rem] py-[1rem] lg:w-[25rem] w-full bg-[#E6F7FF] rounded-[9.125rem] flex flex-col justify-center items-center"
          >
            <p className="text-info lg:text-[4rem] text-[2rem] font-extrabold">
              {item.number}
            </p>
            <h2 className="text-neutrals-2 text-footnote uppercase">
              {item.content}
            </h2>
          </div>
        ))}
      </div>
      <div className="feature-section__second xl:px-[5%] py-4 bg-[#F5F5F5] font-landingPrimary px-4 flex md:flex-row flex-col gap-4 justify-between items-center">
        {secondFeatureItems.map((item, index) => (
          <div
            key={index}
            className="feature-section__second__items lg:py-[2rem] py-[1rem] lg:w-[25rem] w-full flex flex-col justify-center items-center"
          >
            <img src={item.icon} alt="Feature chart icon" />
            <p className="text-info py-4 text-h4m text-center font-medium">
              {item.title}
            </p>
            <h2 className="text-neutrals-2 text-bodyr text-center px-8">
              {item.description}
            </h2>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FeatureSection
