import { displaySkillProgressColor } from '../../utils/utils'
import ProgressTracker from '../../models/progressTracker.model'

interface PracticeListeningScoreSectionProps {
  progressTrackerInfo: ProgressTracker
}

const PracticeListeningScoreSection: React.FC<PracticeListeningScoreSectionProps> = ({ progressTrackerInfo }) => {
  const listeningInfo = [
    {
      title: 'Summarize Spoken Text',
      score: progressTrackerInfo.listening_17prgrs,
    },
    {
      title: 'Fill in the blanks',
      score: progressTrackerInfo.listening_19prgrs,
    },
    {
      title: 'Highlight incorrect words',
      score: progressTrackerInfo.listening_23prgrs,
    },
    {
      title: 'Write from dictation',
      score: progressTrackerInfo.listening_24prgrs,
    },
  ]

  return (
    <div className="practice-performance__listening-skill-details font-landingPrimary w-full flex flex-col gap-8">
      <div className="listening-skill-details__listening lg:h-[21.5rem] h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Listening</p>
        <p className="text-h5m">Total</p>
        <div className="listening-skill-details__listening__detail w-full flex flex-col gap-2 mt-[1rem]">
          {listeningInfo.map((info, index) => (
            <div
              key={index}
              className={`listening-skill-details__listening__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${(info.score / 90) * 100}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PracticeListeningScoreSection
