import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import NextArrowSvg from '../../../images/Speaking/next-arrow.svg'
import { useNavigate } from 'react-router-dom'
import ProgressBar from '../../ProgressBar'
import { ExtendedQuestion } from '../../../models/question.model'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import {
  addResultSpeakingMock,
  doTest1Update,
} from '../../../services/mockTest.services'
import { useAuth } from '../../../providers/AuthProvider'
import {
  handleExceptionError,
  navigateToMockTest,
  setupSpeechRecognition
} from '../../../utils/utils'

const MockDescribeImageQuestion = () => {
  const maxRecordingTime = 40
  const recordingTimeout = 26

  const navigate = useNavigate()
  const { userProfile } = useAuth()
  const [remainingTime, setRemainingTime] = useState(recordingTimeout)
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [recordingTime, setRecordingTime] = useState(maxRecordingTime)
  const [recordingProgress, setRecordingProgress] = useState(0)
  const [buyId, setBuyId] = useState<number | undefined>(undefined)
  const recorderRef = useRef<MediaRecorder | null>(null)

  const [transcribedText, setTranscribedText] = useState('') // State for transcribed text
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(
    undefined,
  )
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const myFluency = useRef(5) // Initialize fluency, you can adjust it based on your needs
  const t0 = useRef(0)
  const t1 = useRef(0)

  const recordingTimeDurationRef = useRef<number>(0)

  useEffect(() => {
    setTimeout(() => {
      const reseultData = localStorage.getItem("resultMockPTE")
      const buyIdStorage = localStorage.getItem("buyIdPTEMock")
      if (buyIdStorage) {
        setBuyId(Number(buyIdStorage))
      }
  
      if (reseultData) {
        const parsedResultData = JSON.parse(reseultData)
        setQuestion(parsedResultData.mockqestion[0])
        setQuestionNumber(parsedResultData.row)
        setTotalQuestion(Number(parsedResultData.allcount))
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [])

  // Use useMemo to initialize SpeechRecognition
  // Use useMemo to initialize SpeechRecognition
  const recognition = useMemo(() => setupSpeechRecognition(), [])
  if (recognition) {
    recognition.onresult = (event: any) => {
      const interimTranscript = Array.from(event.results)
        .map((result: any) => result[0].transcript)
        .filter((text: string) => text.trim() !== '')
        .join(' ')
      // Throttle the transcription state update to avoid overloading the render cycle
      setTranscribedText((prevText) => {
        if (prevText !== interimTranscript) {
          return interimTranscript // Update only when transcript changes
        }
        return prevText
      })

      const now = performance.now()
      if (t0.current === 0) {
        // First recognized speech, set the initial timestamp
        t0.current = now
        t1.current = now
      } else {
        // Calculate the pause duration
        const pauseDuration = now - t1.current
        // Apply fluency calculation based on the pause duration
        if (pauseDuration >= 5000) {
          myFluency.current = 0 // Set fluency to 0 for long pauses
        } else {
          if (pauseDuration >= 450 && pauseDuration <= 550) {
            myFluency.current -= 0.1 // Reduce fluency slightly for short pauses
          } else if (pauseDuration >= 551 && pauseDuration <= 590) {
            myFluency.current -= 0.2
          } else if (pauseDuration >= 591 && pauseDuration <= 650) {
            myFluency.current -= 0.3
          } else if (pauseDuration >= 651 && pauseDuration <= 690) {
            myFluency.current -= 0.4
          } else if (pauseDuration >= 691 && pauseDuration <= 750) {
            myFluency.current -= 0.5
          } else if (pauseDuration >= 751) {
            myFluency.current = Math.max(0, myFluency.current - 1) // Maximum fluency reduction
          }
          // Ensure fluency does not go below 0
          myFluency.current = Math.max(0, myFluency.current)
        }

        // Update the timestamp for the next pause
        t1.current = now
      }
    }

    recognition.onerror = (event: any) => {
      console.error('Speech recognition error:', event.error)
    }
  }

  const startRecording = useCallback(
    (isUserInitiated: boolean = false) => {
      if (!isRecording && !audioBlob) {
        setIsRecording(true)
        setRecordingTime(maxRecordingTime)
        setRecordingProgress(0)
        setTranscribedText('') // Clear transcript before starting

        // Play beep only if the recording is initiated by the user
        if (isUserInitiated) {
          const beep = new Audio('/sound-beep.mp3')
          beep.play().catch((error) => {
            console.log('Beep sound play error:', error)
          })
        }

        // Start MediaRecorder for audio recording
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            const newRecorder = new MediaRecorder(stream)
            recorderRef.current = newRecorder
            const chunks: BlobPart[] = []

            newRecorder.ondataavailable = (event) => chunks.push(event.data)
            newRecorder.onstop = () => {
              setAudioBlob(new Blob(chunks, { type: 'audio/wav' }))
              setRecordingProgress(100)
            }

            newRecorder.start()

            // Start SpeechRecognition for transcription
            if (recognition) {
              recognition.start()
            }
          })
          .catch((error) => {
            console.error('Error accessing microphone:', error)
          })
      }
    },
    [isRecording, audioBlob, recognition],
  )

  // Function to handle the submission of the answer
  const handleSubmitAnswer = useCallback(async () => {
    setIsSubmitted(true)

    if (question && userProfile && userProfile.userId && buyId) {
      const fluencyMark = transcribedText !== '' ? myFluency.current : 0
      const speechDuration = recordingTimeDurationRef.current

      try {
        const addResultResponse = await addResultSpeakingMock(
          '132.wav',
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          speechDuration,
          transcribedText,
          0,
          fluencyMark,
        )

        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest1Update(
            buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            1,
            String(speechDuration),
            String(speechDuration),
            totalQuestion,
            'Skip',
          )

          if (getQuestionResponse.data.success) {
            localStorage.setItem(
              'resultMockPTE',
              JSON.stringify(getQuestionResponse.data.result),
            )

            if (getQuestionResponse.data.category_id === '7') {
              navigate(0) // Reload the same page
            } else {
              navigateToMockTest(
                navigate,
                getQuestionResponse.data.category_id,
              )
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question or buyId not found!')
    }
  }, [buyId, navigate, question, questionNumber, totalQuestion, transcribedText, userProfile])

  const handleSkip = async () => {
    setIsSubmitted(true)

    if (question && userProfile && userProfile.userId && buyId) {
      try {
        const getQuestionResponse = await doTest1Update(
          buyId,
          question.testId,
          userProfile.userId,
          questionNumber,
          1,
          String(0),
          String(0),
          totalQuestion,
          'Skip',
        )

        if (getQuestionResponse.data.success) {
          localStorage.setItem(
            'resultMockPTE',
            JSON.stringify(getQuestionResponse.data.result),
          )

          if (getQuestionResponse.data.category_id === '7') {
            navigate(0) // Reload the same page
          } else {
            navigateToMockTest(
              navigate,
              getQuestionResponse.data.category_id,
            )
          }
        } else {
          console.error(getQuestionResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question or buyId not found!')
    }
  }

  const stopRecording = useCallback(() => {
    if (!isRecording || !recorderRef.current) return // Ensure it's only stopped once

    recorderRef.current.stop() // Stop the audio recording
    recorderRef.current = null // Clear the recorder reference

    if (recognition) {
      recognition.stop() // Stop the speech recognition
    }

    setIsRecording(false)
    setRecordingTime(0) // Set to 0 to indicate recording has ended
    setRecordingProgress(100) // Complete the progress bar
    handleSubmitAnswer()
  }, [handleSubmitAnswer, isRecording, recognition])

  const handleSkipToTest = () => {
    if (!isRecording && remainingTime > 0) {
      startRecording(true)
      setRemainingTime(0)
    }
  }

  useEffect(() => {
    if (remainingTime > 0) {
      const timerId = setTimeout(() => {
        setRemainingTime((prevTime) => prevTime - 1)
      }, 1000)
      return () => clearTimeout(timerId)
    } else if (remainingTime === 0 && !isRecording && !isSubmitted) {
      startRecording(false)
    }
  }, [remainingTime, isRecording, startRecording, isSubmitted])

  useEffect(() => {
    if (isRecording && recordingTime > 0) {
      recordingTimeDurationRef.current += 1
      const intervalId = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime - 1
          setRecordingProgress(
            ((maxRecordingTime - newTime) / maxRecordingTime) * 100,
          )
          return newTime
        })
      }, 1000)
      return () => clearInterval(intervalId)
    } else if (recordingTime <= 0 && isRecording) {
      stopRecording() // Stop recording when time is up
    }
  }, [isRecording, recordingTime, stopRecording])

  const handleSaveAndNext = () => {
    if (!isSubmitted) {
      stopRecording() // Stop recording
    }
  }

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div>
          {!isRecording && !audioBlob && (
            <div className="block md:flex justify-between">
              <div className="flex text-center items-center mb-2 md:mb-0">
                <p className="text-h5m text-neutrals-2 mr-2">Beginning in:</p>
                <p className="text-h3m text-neutrals-1">
                  {remainingTime} seconds
                </p>
              </div>
              <button
                onClick={handleSkipToTest}
                className="text-bodyr text-neutrals-1 p-2 rounded-lg border border-[1px]-[#D9D9D9] hover:scale-105"
              >
                Skip to Test
              </button>
            </div>
          )}
          <div className="p-8 rounded-xl shadow mt-8">
            <div className="flex justify-between p-4 mb-4 bg-info rounded-lg text-white text-h5r">
              <p>Speaking Section - Describe Image (#{question?.id})</p>
              <p>({questionNumber + 1} Out of 26)</p>
            </div>
            <p className="text-h4m text-neutrals-1">{question?.shortTitle}</p>
            <div className="w-full">
              <img
                src={question && question.image ? question.image : ''}
                alt="Mock test speaking images"
                className="mx-auto my-4 w-[50vw] h-[50vh]"
              />
            </div>

            {isRecording && (
              <div className="bg-[#F1EFF2] text-center p-4 rounded-xl w-[50%] mx-auto mt-4">
                <p className="text-h5r mt-4">Timer: {recordingTime} seconds</p>

                <ProgressBar progress={recordingProgress} />

                <button
                  onClick={handleSaveAndNext}
                  className={`text-bodyr text-white p-2 mt-2 rounded-lg hover:scale-105 
                    ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                  disabled={isSubmitted}
                >
                  Save and Next
                </button>
              </div>
            )}
          </div>
          <div className="flex justify-center mt-4">
            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
              onClick={handleSkip}
              disabled={isSubmitted}
            >
              <p className="hidden md:block text-bodyr text-white">Skip</p>
              <img src={NextArrowSvg} alt="next" className="md:ml-2" />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default MockDescribeImageQuestion
