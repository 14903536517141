import axiosInstance from '../configs/axiosConfig'

export function login(email: string, password: string) {
    const data = {
        email: email,
        password: password
    }

    return axiosInstance.post(`/login`, data)
}

export function logout(userId: number) {
    const data = {
        userId: userId
    }

    return axiosInstance.post(`/logout`, data)
}

export function socialLogin(email: string, name: string, imageURL: string) {
    const data = {
        name: name,
        email: email,
        imageURL: imageURL
    }

    return axiosInstance.post(`/login/social-login`, data)
}

export function registerAccount(email: string, name: string, password: string, nationality: string, mobile: string) {
    const data = {
        email: email,
        name: name,
        password: password,
        nationality: nationality,
        mobile: mobile
    }

    return axiosInstance.post(`/register`, data)
}

export function activateAccount(token: string) {
    const data = {
        token: token
    }

    return axiosInstance.post(`/register/activate-account`, data)
}