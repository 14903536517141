import React, { useState } from 'react'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import WeeklyPredictionKeywordIcon from '../../images/WeeklyPrediction/weekly-prediction-keyword.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'

type ReadAloudQuestionProps = {
  setShowScoreBoard: (value: boolean) => void
  showScoreBoard: boolean
}

type MultipleChoiceQuestion = {
  question: string
  options: string[]
}

const question: MultipleChoiceQuestion = {
  question:
    'Which of the following options regarding career counselling are correct?',
  options: [
    'Career counselling has always been under-rated by the parents',
    'Career counselling if sought at the right time may bring unexpected growth in one’s career',
    'Career counselling helps gain control over one’s mind and have a clear view of the future.',
    'Career and education will keep on having a very strong relationship',
  ],
}

const MultipleMCQuestion: React.FC<ReadAloudQuestionProps> = ({
  setShowScoreBoard,
  showScoreBoard,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<number[]>([])

  const handleCheckboxChange = (index: number) => {
    if (selectedOptions.includes(index)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== index))
    } else {
      if (selectedOptions.length < 2) {
        setSelectedOptions([...selectedOptions, index])
      }
    }
  }

  return (
    <div>
      <p className="text-bodym text-danger mt-2 md:mt-8">
        * This question carries marks for Speaking (~25%) and Reading ( 24%)
      </p>

      <div className="p-8 rounded-xl shadow mt-8">
        <p className="text-h4m text-neutrals-1">
          Read the text and answer the question by selecting all the correct
          responses. More than one response is correct
        </p>
        <p className="text-h5r text-neutrals-1 pt-4">
          At the time Jane Austen’s novel were published between 1811 and 1818.
          English literature was not part of any academic core curriculum, In
          addition, fiction was under strenuous attack. Certain religious and
          political groups felt novels had the influence to make so called
          immoral characters so interesting that young readers would identify
          with them; these groups also considered novels to be of little
          practical use. Even Cole-ridge, certainly no literary reactionary,
          spoke for many when he stated that ‘novel- reading occasions the
          destruction of the mind’s power. These thoughts towards novels help
          explain why Austen received little as twentieth century criticism. In
          this attack in 1816 on novelistic portrayals ‘outside of ordinary
          experience for instance. Scott made an insightful remark about the
          merits of Austen’s fiction. Scott did not use the word “realistic
          probability in judging novels. The opponent whitely did not use the
          word pragmatism either, but he articulated agreement with Scott’s
          evaluation, and went on to suggest the possibilities for moral
          instruction in what we have called Austen’s realistic technique. Her
          characters, wrote whitely are were our own Moral instruction,
          explained Whitely, is more likely to be effective when conveyed
          through recognizably human and interesting characters then when
          imparted by a sermonizing storyteller.
        </p>

        <div className="w-[90%] lg:w-[60%] pt-8 mx-auto">
          <p className="text-h5m">{question.question}</p>
          {question.options.map((option, index) => (
            <div key={index} className="flex items-center mt-4">
              <p className="text-footnote py-[2px] px-[8px] rounded-full bg-info text-white">
                {String.fromCharCode(65 + index)}
              </p>
              <input
                type="checkbox"
                className="mx-2"
                onChange={() => handleCheckboxChange(index)}
                checked={selectedOptions.includes(index)}
                disabled={
                  selectedOptions.length === 2 &&
                  !selectedOptions.includes(index)
                }
              />
              <p className="text-bodyr">{option}</p>
            </div>
          ))}
          <div className="flex place-content-center	mt-8">
            <button className="flex text-bodyr text-white items-center py-2 px-6 bg-success rounded-lg mr-2">
              <img
                src={WeeklyPredictionSubmitIcon}
                alt="submit"
                className="mr-2"
              />
              Submit
            </button>

            <button className="flex text-bodyr text-neutrals-2 items-center p-2 border border-1px-neutrals-2 rounded-lg">
              <img
                src={WeeklyPredictionKeywordIcon}
                alt="keyword"
                className="mr-2"
              />
              Unlock Keywords
            </button>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
          <p className="hidden md:block text-bodyr text-white">Previous</p>
        </button>
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <p className="hidden md:block text-bodyr text-white">Try Again</p>
          <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
        </button>
        <div>
          <select className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y">
            {Array.from({ length: 20 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <p className="hidden md:block text-bodyr text-white">Next</p>
          <img src={NextArrowSvg} alt="next" className="md:ml-2" />
        </button>
      </div>
    </div>
  )
}

export default MultipleMCQuestion
