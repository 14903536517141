import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ReportProgressOverallSectionalSection from './ReportProgressOverallSectionalSection'
import RPListeningScoreDetailsSection from './RPListeningScoreDetailsSection'
import { useAuth } from '../../../providers/AuthProvider'
import { progressTrackerSectionalListening } from '../../../services/testHistory.services'
import LoadingMessage from '../../Items/LoadingMessage'
import { ProgessTrackerSectionalListening } from '../../../models/progressTrackerSectional.model'

const ReportHistoryListeningSectionalSection = () => {
  let { buyId } = useParams()
  const { userProfile } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [reportHistorySectionalListening, setReportHistorySectionalListening] = useState<ProgessTrackerSectionalListening>({
    spk_17_grammer: 0,
      spk_17_spell: 0,
      spk_17_form: 0,
      spk_17_contet: 0,
      spk_17_vocab: 0,
      spk_17_prgrs: 0,
      spk_17_grammer_pbgcls: '',
      spk_17_spell_pbgcls: '',
      spk_17_form_pbgcls: '',
      spk_17_contet_pbgcls: '',
      spk_17_vocab_pbgcls: '',
      spk_17_pbgcls_total: '',
      spk_18_prgrs: 0,
      spk_18_pbgcls_total: '',
      spk_19_spell: 0,
      spk_19_contet: 0,
      spk_19_vocab: 0,
      spk_19_prgrs: 0,
      spk_19_dtotal: '',
      spk_19_spell_pbgcls: '',
      spk_19_contet_pbgcls: '',
      spk_19_vocab_pbgcls: '',
      spk_19_pbgcls_total: '',
      spk_20_prgrs: 0,
      spk_20_pbgcls_total: '',
      spk_21_prgrs: 0,
      spk_21_pbgcls_total: '',
      spk_22_prgrs: 0,
      spk_22_pbgcls_total: '',
      spk_23_prgrs: 0,
      spk_23_dtotal: '',
      spk_23_pbgcls_total: '',
      spk_24_spell: 0,
      spk_24_contet: 0,
      spk_24_vocab: 0,
      spk_24_prgrs: 0,
      spk_24_spell_pbgcls: '',
      spk_24_contet_pbgcls: '',
      spk_24_vocab_pbgcls: '',
      spk_24_pbgcls_total: '',
      s_grammer: 0,
      s_spell: 0,
      s_form: 0,
      s_contet: 0,
      sprgrs: 0,
      sprgrs_pbgcls: '',
      sprgrs_pbgcls_total: '',
      s_grammer_pbgcls: '',
      s_spell_pbgcls: '',
      s_form_pbgcls: '',
      s_contet_pbgcls: '',
  })

  useEffect(() => {
    try {
      const fetchProgressTrackerSectionalListening = async () => {
        if (userProfile && userProfile.userId && !isNaN(Number(buyId))) {
          try {
            const response = await progressTrackerSectionalListening(userProfile.userId, Number(buyId))
            if (response.data.success) {
              setReportHistorySectionalListening(response.data.result)
            }
          } catch (error) {
            console.error(error)
          } finally {
            setTimeout(() => {
              setIsLoading(false)
            }, 2000)
          }
        } else {
          console.error('User profile is missing or invalid or buy id is invalid!')
        }
      }
  
      fetchProgressTrackerSectionalListening()
    } catch (error) {
      console.error(error)
    }
  }, [userProfile, buyId])

  const overallScores = [
    {
      id: 1,
      skill: 'Overall Listening Score',
      score: reportHistorySectionalListening.sprgrs,
    },
  ]

  return (
    <div className="report-history-listening-sectional-section">
      <p className="text-h1 font-landingPrimary mb-[0.4rem]">Report History</p>
      {
        isLoading ? ( 
          <div className='mt-[3rem]'>
            <LoadingMessage message='Loading report history sectional listening...'/>
          </div>
        ) : (
          <>
            <ReportProgressOverallSectionalSection overallScoreInfo={overallScores} />
            <RPListeningScoreDetailsSection resultListeningDetails={reportHistorySectionalListening}/>
          </>
        )
      }
    </div>
  )
}

export default ReportHistoryListeningSectionalSection
