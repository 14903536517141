import React from 'react'
import Test from '../../models/test.model'
import MyUnlockedTestCard from './MyUnlockedTestCard'
import ErrorMessage from '../Items/ErrorMessage'

const MyUnlockedTestsSection: React.FC<{
  tests: Test[]
  mockCredits: number
}> = ({ tests, mockCredits }) => {
  return (
    <div className="my-unlocked-tests-section font-landingPrimary">
      <p className="text-h1">My Unlocked Tests</p>
      <p className="text-bodyr text-primary-6 px-3 py-1 bg-[#F0F7FF] mt-[1rem] h-[1.875rem] rounded-xl">
        Mock credit balance: {mockCredits}
      </p>
      <div className="my-unlocked-tests-section__test-list mt-[2rem] w-full flex flex-wrap gap-6">
        {tests.length === 0 ? (
          <ErrorMessage message="No unlocked tests" />
        ) : (
          tests.map((test: Test, index) => (
            <MyUnlockedTestCard key={index} test={test} />
          ))
        )}
      </div>
    </div>
  )
}

export default MyUnlockedTestsSection
