import SectionalReadAloudQuestion from '../../../components/SectionalTest/Speaking/ReadAloudQuestion'

const SectionalReadAloud = () => {
  return (
    <div>
      <SectionalReadAloudQuestion />
    </div>
  )
}

export default SectionalReadAloud
