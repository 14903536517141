import { useState, useEffect } from 'react'
import LastMinuteStudySection from '../components/LastMinuteStudyPage/LastMinuteStudySection'
import { useAuth } from '../providers/AuthProvider'
import { getLastMinuteStudy } from '../services/user.services'

const LastMinuteStudyPage = () => {
  const { userProfile } = useAuth()

  const categories = [
    { name: '79+ Bands', id: 1 },
    { name: '65+ Bands', id: 2 },
    { name: '50+ Bands', id: 3 },
    { name: 'Useful Tools', id: 4 },
  ]

  const [selectedCategory, setSelectedCategory] = useState(categories[0].id)
  const [storageData, setStorageData] = useState<[]>([])

  const handleCategoryChange = (categoryId: number) => {
    setSelectedCategory(categoryId)
  }

  useEffect(() => {
    if (selectedCategory && userProfile?.userId) {
      getLastMinuteStudy(userProfile.userId, selectedCategory)
        .then((response) => {
          setStorageData(response.data.storage)
        })
        .catch((error) => {
          console.error('Error fetching last minute study data:', error)
        })
    }
  }, [selectedCategory, userProfile?.userId])

  return (
    <>
      <div className="navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          Last Minute Study /{' '}
          {categories.find((cat) => cat.id === selectedCategory)?.name}
        </p>
      </div>
      <div className="w-full lg:min-h-[56rem] h-fit lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <LastMinuteStudySection
          onSelectCategory={handleCategoryChange}
          categories={categories}
          storage={storageData}
        />
      </div>
    </>
  )
}

export default LastMinuteStudyPage
