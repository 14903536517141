import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import PteMasterLogo from '../../images/Landingpage/pte-master-logo.png'

const Header: React.FC = () => {
  const headerMenuItems = [
    { content: 'Practice Materials', direction: '/' },
    { content: 'Automated Machine Scoring Test', direction: '/' },
    { content: 'How It Works', direction: '/' },
    { content: 'Contact Us', direction: '/' },
    { content: 'Private Policy', direction: '/' },
  ]

  const mobileMenuRef = useRef<HTMLDivElement>(null)

  const openMenu = () => {
    if (mobileMenuRef.current) {
      mobileMenuRef.current.classList.toggle('hidden')
    }
  }

  return (
    <header className="w-full">
      <nav className="font-landingPrimary bg-white px-4 py-2.5">
        <div className="flex flex-wrap justify-between items-center mx-auto gap-y-2">
          <Link to="/" className="flex items-center">
            <img
              src={PteMasterLogo}
              alt="PTE-Master Logo"
              className="lg:w-[144px] md:w-[144px] w-[120px] h-[46px]"
            />
          </Link>
          <div className="flex items-center lg:order-2">
            <Link
              to="/register"
              className="text-primary-5 border border-primary-5 bg-neutrals-4 hover:bg-[#E6F7FF] rounded-xl px-4 lg:px-[2rem] py-2 lg:py-2.5 mr-2"
            >
              Register
            </Link>
            <Link
              to="/login"
              className="text-white bg-info hover:bg-[#1890FF] px-4 lg:px-[2rem] py-2 lg:py-2.5 rounded-xl"
            >
              Login
            </Link>
            <button
              onClick={openMenu}
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                className="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            ref={mobileMenuRef}
            className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1 lg:bg-white bg-[#E6F7FF]"
            id="mobile-menu-2"
          >
            <ul className="flex flex-col mt-4 font-bodyr lg:flex-row lg:space-x-10 lg:mt-0">
              {headerMenuItems.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.direction}
                    className="block py-2 pr-4 pl-3 rounded lg:p-0 text-neutrals-1 lg:hover:bg-transparent hover:bg-gray-200"
                  >
                    {item.content}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
