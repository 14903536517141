import { useState, useEffect, useRef } from 'react'
import { ReactComponent as ArrowRightIcon } from '../../../images/SectionalTest/st-arrow-right-icon.svg'
import AudioSectional from '../../../components/SectionalTest/AudioSectional'
import { useNavigate } from 'react-router-dom'
import Option from '../../../models/option.model'
import { ExtendedQuestion } from '../../../models/question.model'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import { doTest5Update, addResultListeningSectional } from '../../../services/sectionalTest.services'
import { useAuth } from '../../../providers/AuthProvider'
import { handleExceptionError, navigateToSectionalTestListening } from '../../../utils/utils'

const FillInTheBlanksSectional = () => {
  const navigate = useNavigate()

  // Function to calculate the number of input fields dynamically
  const countPlaceholders = (text: string) => {
    return (text.match(/\{\d+\}/g) || []).length
  }

  // Initialize state dynamically based on the number of placeholders
  const [answers, setAnswers] = useState<string[]>([])
  const [timer, setTimer] = useState({ minutes: 40, seconds: 0 })
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(undefined)
  const [originalOptions, setOriginalOptions] = useState<Option[]>([])
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { userProfile } = useAuth()

  // Ref to store the elapsed time in seconds
  const elapsedTimeRef = useRef(0)
  const containerParagraphRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setTimeout(() => {
      const reseultData = localStorage.getItem('resultSectionalLPTE')
      if (reseultData) {
        const parsedResultData = JSON.parse(reseultData)
        setQuestion(parsedResultData.mockqestion[0])
        setQuestionNumber(parsedResultData.row)
        setOriginalOptions(parsedResultData.option)
        setTotalQuestion(Number(parsedResultData.allcount))
        const inputCount = countPlaceholders(question?.beforUpadtedDescription ? question.beforUpadtedDescription : '')
        setAnswers(Array(inputCount).fill(''))


      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [question?.beforUpadtedDescription])

  const formattedTime = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`

  // Function to calculate the total elapsed time in seconds
  const calculateElapsedTime = () => {
   return elapsedTimeRef.current
 }

  // Function to start the timer countdown
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (timer.minutes === 0 && timer.seconds === 0) {
      // Timer has reached 00:00, stop the timer
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        elapsedTimeRef.current += 1

        if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 })
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 })
        }
      }, 1000)
    }

    // Cleanup function to clear the interval when component unmounts or timer stops
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timer])

  // Function to handle input changes
  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value
    setAnswers(newAnswers)
  }

  // Function to format the paragraph with input fields
  const formattedParagraph = () => {
    // Only proceed if the question and its description are available
    if (!question || !question.beforUpadtedDescription) {
      return null // Return null or a loading state
    }

    const parts = question.beforUpadtedDescription.split(/(\{\d+\})/)
    return parts.map((part, index) => {
      const match = part.match(/\{(\d+)\}/)
      if (match && match.length >= 2) {
        const placeholderIndex = parseInt(match[1], 10) - 1 // Get the index from the placeholder (e.g., {1} -> index 0)
        return (
          <span key={index} className="inline-flex items-center mx-1">
            <span className="w-5 h-5 flex items-center justify-center rounded-full bg-info text-white text-xs">
              {placeholderIndex + 1}
            </span>
            <input
              type="text"
              maxLength={20}
              className="inline-block w-[8rem] h-[2rem] mx-1 p-1 rounded-lg border border-2 border-[#D9D9D9]"
              value={answers[placeholderIndex] || ''} // Bind the value to the corresponding state value
              onChange={(e) => handleInputChange(placeholderIndex, e)} // Update the state on input change
            />
          </span>
        )
      }
      return <span key={index}>{part}</span>
    })
  }

  // Function to calculate the marks based on the provided answers
  const calculateMark = () => {
    let mark = 0

    // Loop through the answers and compare with the correct options
    answers.forEach((answer, index) => {
      // Compare both answers and options by trimming and converting to lowercase
      if (
        answer.trim().toLocaleLowerCase() ===
        originalOptions[index].options.trim().toLowerCase()
      ) {
        mark++
      }
    })

    return mark
  }

  const handleSubmitAnswer = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    // const buyId = Number(localStorage.getItem('buyIdWPTESectional')) || 0
    // const testId = Number(localStorage.getItem('testIdWPTESectional')) || 0

    if (question && question.beforUpadtedDescription && userProfile && userProfile.userId) {
      const totalMarks = calculateMark()
      // Replace {1}, {2}, etc., with the corresponding answer from the input fields
      let userAnswer = question.beforUpadtedDescription
      let userAnswerString = question.beforUpadtedDescription

      answers.forEach((answer, index) => {
        const placeholder = `{${index + 1}}`
        const answerWithSpan = `<span class='text-blue-500'>${answer.trim().toLowerCase()}</span>`
        userAnswer = userAnswer.replace(placeholder, answer.trim().toLowerCase())
        userAnswerString = userAnswerString.replace(placeholder, answerWithSpan)
      })

      try {
        const addResultResponse = await addResultListeningSectional(
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          question.buyId,
          userAnswer,
          userAnswerString,
          totalMarks,
          ''
        )
      
        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest5Update(
            question.buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            4,
            String(elapsedTime),
            String(elapsedTime),
            totalQuestion,
            'Skip'
          )
      
          if (getQuestionResponse.data.success) {
            localStorage.setItem('resultSectionalLPTE', JSON.stringify(getQuestionResponse.data.result))
      
            if (getQuestionResponse.data.category_id === '19') {
              navigate(0) // Reload the same page
            } else {
              navigateToSectionalTestListening(navigate, getQuestionResponse.data.category_id)
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question or beforUpadtedDescription not found!')
    }
  }

  const handleSkip = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (question && userProfile && userProfile.userId) {
      try {
        const getQuestionResponse = await doTest5Update(
          question.buyId,
          question.testId,
          userProfile.userId,
          questionNumber,
          4,
          String(elapsedTime),
          String(elapsedTime),
          totalQuestion,
          'Skip'
        )
    
        if (getQuestionResponse.data.success) {
          localStorage.setItem('resultSectionalLPTE', JSON.stringify(getQuestionResponse.data.result))
        
          if (getQuestionResponse.data.category_id === '19') {
            navigate(0) // Reload the same page
          } else {
            navigateToSectionalTestListening(navigate, getQuestionResponse.data.category_id)
          }

        } else {
          console.error(getQuestionResponse.data.message)
        }
      } 
      catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question not found!')
    }
  }

  const allAnswersAreFilled = answers.every(answer => answer.trim() !== '') 

  return (
    <div className='min-h-[80vh]'>
    {
      isLoading ? (
        <LoadingMessage message='Loading question...'/> 
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div className="st-fitb w-full pb-4 shadow-question-card mx-auto font-landingPrimary">
          <div className="st-fitb__header flex gap-2 justify-between p-4 mb-4 bg-info rounded-lg text-white text-h5r">
            <p className="st-fitb__header__title">
              Listening Section - Fill In The Blanks (#{question?.id})
            </p>
            <p className="st-fitb__header__number">({questionNumber + 1} Out of 18)</p>
          </div>
          <div className="st-sst__timer w-[6.25rem] h-[3.5rem] bg-primary-2 mb-6 rounded-lg flex justify-center items-center mx-auto">
            <p className="text-h2 text-white">{formattedTime}</p>
          </div>
          <div className="st-fitb__instruction text-h4m px-4 mb-4">
            {question?.shortTitle}
          </div>
          <AudioSectional audioUrl={question && question.audio ? question.audio : ''} />
          <div className="st-fitb__paragraph text-bodyr px-4 mb-4" ref={containerParagraphRef}>
            {formattedParagraph()}
          </div>
          <div className='w-full flex justify-center gap-4'>
            <button 
              className={`flex text-bodyr text-white items-center justify-centers gap-2 py-2 px-6 rounded-lg 
                ${(!allAnswersAreFilled || isSubmitted) ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
              onClick={handleSubmitAnswer}
              disabled={!allAnswersAreFilled || isSubmitted}
            >
              Save and Next
              <ArrowRightIcon />
            </button>
            <button 
              className={`flex items-center py-2 px-6 rounded-lg 
                ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
              disabled={isSubmitted}
              onClick={handleSkip}
            >
              <p className="text-bodyr text-white">Skip</p>
            </button>
          </div>
        </div>
      )
    }  
    </div>
  )
}

export default FillInTheBlanksSectional
