import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'
import Path from '../routes/Path'
import { isTokenValid } from '../utils/utils'

type RedirectIfAuthenticatedProps = {
  children: React.ReactNode
};

const RedirectIfAuthenticated: React.FC<RedirectIfAuthenticatedProps> = ({ children }) => {
  const { token } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    if (isTokenValid(token)) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
    setIsLoading(false)
  }, [token])

  if (isLoading) {
    return <div className="h-[100vh] flex justify-center items-center">Loading...</div>
  }

  if (isAuthenticated) {
    return <Navigate to={Path.home.path} replace />
  }

  return <>{children}</>
};

export default RedirectIfAuthenticated