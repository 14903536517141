import { useState } from 'react'
import {
  PracticeType,
  QuestionModel,
  SpeakingQuestion,
  Type,
} from '../../models/QuestionModels'
import { Link } from 'react-router-dom'
import WeeklyPredictionSaveAsSvg from '../../images/WeeklyPrediction/weekly-prediction-save-as.svg'
import WeeklyPredictionEditSvg from '../../images/WeeklyPrediction/weekly-prediction-edit.svg'
import WeeklyPredictionMarkSvg from '../../images/WeeklyPrediction/weekly-prediction-mark.svg'

const questions: SpeakingQuestion[] = [
  {
    id: '#0001',
    name: 'Modern buildings have to achieve certain performance requirements, at least to satisfy those of building codes, to provide a safe, healthy, and comfortable environment. However, these conditioned environments demand resources in energy and materials, which are both limited in supply, to build and operate',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.AllQuestions,
    type: Type.Easy,
  },
  {
    id: '#0002',
    name: 'Modern buildings have to achieve certain performance requirements, at least to satisfy those of building codes, to provide a safe, healthy, and comfortable environment. However, these conditioned environments demand resources in energy and materials, which are both limited in supply, to build and operate',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.AttemptedQuestions,
    type: Type.Medium,
  },
  {
    id: '#0003',
    name: 'Modern buildings have to achieve certain performance requirements, at least to satisfy those of building codes, to provide a safe, healthy, and comfortable environment. However, these conditioned environments demand resources in energy and materials, which are both limited in supply, to build and operate',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.NotAttemptedQuestions,
    type: Type.Hard,
  },
  {
    id: '#0004',
    name: 'Modern buildings have to achieve certain performance requirements, at least to satisfy those of building codes, to provide a safe, healthy, and comfortable environment. However, these conditioned environments demand resources in energy and materials, which are both limited in supply, to build and operate',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.AllQuestions,
    type: Type.Hard,
  },
  {
    id: '#0005',
    name: 'Modern buildings have to achieve certain performance requirements, at least to satisfy those of building codes, to provide a safe, healthy, and comfortable environment. However, these conditioned environments demand resources in energy and materials, which are both limited in supply, to build and operate',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.AttemptedQuestions,
    type: Type.Easy,
  },
]

type SpeakingQuestionListProps = {
  questionType: QuestionModel
}

const SpeakingQuestionList: React.FC<SpeakingQuestionListProps> = ({
  questionType,
}) => {
  const [visibleQuestions, setVisibleQuestions] = useState(3)

  const showMoreQuestions = () => {
    setVisibleQuestions((prev) => prev + 8)
  }

  const getUrl = (type: QuestionModel) => {
    switch (type) {
      case 'Read Aloud':
        return '/weekly-prediction/speaking/read-aloud'
      case 'Re-tell Lectures':
        return '/weekly-prediction/speaking/retell-lecture'
      case 'Repeat Sentences':
        return '/weekly-prediction/speaking/repeat-sentence'
      case 'Reading: Multiple-choice, choose multiple answers':
        return '/weekly-prediction/reading/multiple-choice-multiple-answer'
      case 'Reading: Multiple-choice, choose single answer':
        return '/weekly-prediction/reading/multiple-choice-single-answer'
      case 'Re-order paragraphs':
        return '/weekly-prediction/reading/reorder-paragraph'
      case 'Reading and writing: Fill in the blanks':
        return '/weekly-prediction/reading/fill-in-the-blanks'
      case 'Reading: Fill in the blanks':
        return '/weekly-prediction/reading/reading-fill-in-the-blanks'
      default:
        return '/weekly-prediction/speaking'
    }
  }

  return (
    <div>
      {questions.slice(0, visibleQuestions).map((question, index) => (
        <Link to={getUrl(questionType)} key={index}>
          <div className="border border-[1px]-[#D9D9D9] p-4 mb-4 rounded-lg cursor-pointer">
            <p className="text-h4m">{question.name}</p>
            <div className="flex text-footnote pt-2">
              <p className=" text-white bg-[#1890FF] px-2 mr-2 rounded-full">
                {question.practiceType}
              </p>
              <p className="text-white bg-danger px-2 mr-2 rounded-full">
                {question.type}
              </p>
              <p className="text-neutrals-2 bg-[#F0F0F0] px-2 mr-2 rounded-full">
                Sort By
              </p>
              <p className="text-neutrals-2">{question.id}</p>
            </div>
            <p className="text-footnote text-[#8C8C8C] pt-2">
              {question.description}
            </p>
            <div className="flex justify-end mt-2">
              <img
                src={WeeklyPredictionSaveAsSvg}
                alt="save"
                className="mr-2 hover:scale-105"
              />
              <img
                src={WeeklyPredictionEditSvg}
                alt="edit"
                className="mr-2 hover:scale-105"
              />
              <img
                src={WeeklyPredictionMarkSvg}
                alt="mark"
                className="hover:scale-105"
              />
            </div>
          </div>
        </Link>
      ))}
      {visibleQuestions < questions.length && (
        <button
          onClick={showMoreQuestions}
          className="w-full text-bodyr text-white py-2 rounded-sm"
          style={{
            background: 'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
          }}
        >
          Show More...
        </button>
      )}
    </div>
  )
}

export default SpeakingQuestionList
