import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ReportProgressOverallSectionalSection from './ReportProgressOverallSectionalSection'
import RPWritingScoreDetailsSection from './RPWritingScoreDetailsSection'
import { useAuth } from '../../../providers/AuthProvider'
import { progressTrackerSectionalWriting } from '../../../services/testHistory.services'
import LoadingMessage from '../../Items/LoadingMessage'
import { ProgessTrackerSectionalWriting } from '../../../models/progressTrackerSectional.model'

const ReportHistoryWritingSectionalSection = () => {
  let { buyId } = useParams()
  const { userProfile } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [reportHistorySectionalWriting, setReportHistorySectionalWriting] = useState<ProgessTrackerSectionalWriting>({
    spk_10_grammer: 0,
      spk_10_form: 0,
      spk_10_contet: 0,
      spk_10_prgrs: 0,
      spk_10_grammer_pbgcls: '',
      spk_10_form_pbgcls: '',
      spk_10_contet_pbgcls: '',
      spk_10_pbgcls_total: '',
      spk_11_grammer: 0,
      spk_11_spell: 0,
      spk_11_form: 0,
      spk_11_contet: 0,
      spk_11_vocab: 0,
      spk_11_prgrs: 0,
      spk_11_grammer_pbgcls: '',
      spk_11_spell_pbgcls: '',
      spk_11_form_pbgcls: '',
      spk_11_contet_pbgcls: '',
      spk_11_vocab_pbgcls: '',
      spk_11_pbgcls_total: '',
      s_grammer: 0,
      s_form: 0,
      s_contet: 0,
      sprgrs: 0,
      sprgrs_pbgcls: '',
      sprgrs_pbgcls_total: '',
      s_grammer_pbgcls: '',
      s_form_pbgcls: '',
      s_contet_pbgcls: '',
  })

  useEffect(() => {
    try {
      const fetchProgressTrackerSectionalWriting = async () => {
        if (userProfile && userProfile.userId && !isNaN(Number(buyId))) {
          try {
            const response = await progressTrackerSectionalWriting(userProfile.userId, Number(buyId))
            if (response.data.success) {
              setReportHistorySectionalWriting(response.data.result)
            }
          } catch (error) {
            console.error(error)
          } finally {
            setTimeout(() => {
              setIsLoading(false)
            }, 2000)
          }
        } else {
          console.error('User profile is missing or invalid or buy id is invalid!')
        }
      }
  
      fetchProgressTrackerSectionalWriting()
    } catch (error) {
      console.error(error)
    }
  }, [userProfile, buyId])

  const overallScores = [
    {
      id: 1,
      skill: 'Overall Grammar',
      score: reportHistorySectionalWriting.s_grammer,
    },
    {
      id: 2,
      skill: 'Overall Form',
      score: reportHistorySectionalWriting.s_form,
    },
    {
      id: 3,
      skill: 'Overall Content',
      score: reportHistorySectionalWriting.s_contet,
    },
    {
      id: 4,
      skill: 'Overall Writing Score',
      score: reportHistorySectionalWriting.sprgrs,
    },
  ]

  return (
    <div className="report-history-writing-sectional-section">
      <p className="text-h1 font-landingPrimary mb-[0.4rem]">Report History</p>
      {
        isLoading ? (
          <div className='mt-[3rem]'>
            <LoadingMessage message='Loading report history sectional writing...'/>
          </div> ) 
          : (
            <>
              <ReportProgressOverallSectionalSection overallScoreInfo={overallScores} />
              <RPWritingScoreDetailsSection resultWritingDetails={reportHistorySectionalWriting}/>
            </>
          )
      }
    </div>
  )
}

export default ReportHistoryWritingSectionalSection
