import SectionalReTellLectureQuestion from '../../../components/SectionalTest/Speaking/ReTellLectureQuestion'

const SectionalReTellLecture = () => {
  return (
    <div>
      <SectionalReTellLectureQuestion />
    </div>
  )
}

export default SectionalReTellLecture
