import { useEffect, useState } from 'react'
import MobileCourseList from './MobileCourseList'
import DesktopCourseList from './DesktopCourseList'

const CourseList = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return(

  isMobile ? <MobileCourseList /> : <DesktopCourseList />
)
}
export default CourseList
