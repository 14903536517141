import SectionalDescribeImageQuestion from '../../../components/SectionalTest/Speaking/DescribeImageQuestion'

const SectionalDescribeImage = () => {
  return (
    <div>
      <SectionalDescribeImageQuestion />
    </div>
  )
}

export default SectionalDescribeImage
