import { ReactNode, createContext, useContext } from 'react'
import axios from 'axios'

type Props = {
  children: ReactNode
}

interface ApiValue {
  ping: () => Promise<void>
}

export const ApiContext = createContext<ApiValue | null>(null)

export function useApi(): ApiValue {
  const state = useContext(ApiContext)
  if (!state) {
    throw new Error('useApi must be used within ApiProvider')
  }
  return state
}

export function ApiProvider({ children }: Props) {
  const headers = { 'Content-Type': 'application/json' }
  const host = process.env.REACT_APP_API_URL

  const ping = async () => {
    await axios.get(`${host}/ping`, { headers })
  }

  const providerValue = {
    ping,
  }

  return (
    <ApiContext.Provider value={providerValue}>{children}</ApiContext.Provider>
  )
}
