import React, { useState, useRef } from 'react'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import WeeklyPredictionKeywordIcon from '../../images/WeeklyPrediction/weekly-prediction-keyword.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'

interface Item {
  id: string
  content: string
}

interface SentencePart {
  type: 'text' | 'blank'
  content: string
  id?: string
  itemId?: string
}

const initialItems: Item[] = [
  {
    id: 'item-1',
    content: 'Option 1',
  },
  {
    id: 'item-2',
    content: 'Option 2',
  },
  {
    id: 'item-3',
    content: 'Option 3',
  },
  {
    id: 'item-4',
    content: 'Option 4',
  },
]

const initialSentence: SentencePart[] = [
  {
    type: 'text',
    content:
      "Legal deposit has existed in English law since 1662. It helps to ensure that the nation's published output (and thereby its record and future published heritage) is collected systematically, to",
  },
  { type: 'blank', id: 'blank-1', content: '' },
  {
    type: 'text',
    content:
      'within the designated legal deposit libraries. The Legal Deposit Libraries are the British Library, the National Library of Scotland, the',
  },
  { type: 'blank', id: 'blank-2', content: '' },
]

const ReadingFillInTheBlanksQuestion = () => {
  // eslint-disable-next-line
  const [items, setItems] = useState<Item[]>(initialItems)
  const [sentence, setSentence] = useState<SentencePart[]>(initialSentence)
  const dragItem = useRef<{
    id: string
    source: 'items' | 'sentence'
    itemId?: string
  } | null>(null)

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    id: string,
    source: 'items' | 'sentence',
    itemId?: string,
  ) => {
    dragItem.current = { id, source, itemId }
    e.dataTransfer.effectAllowed = 'move'
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    targetId?: string,
  ) => {
    e.preventDefault()
    if (!dragItem.current || !targetId) return

    const { id, source, itemId } = dragItem.current
    const targetIndex = sentence.findIndex((part) => part.id === targetId)
    if (targetIndex === -1) return // Exit if no target found

    if (
      source === 'items' &&
      sentence[targetIndex].type === 'blank' &&
      sentence[targetIndex].content === ''
    ) {
      const itemToAdd = items.find((item) => item.id === itemId)
      if (itemToAdd) {
        const updatedSentence = sentence.map((part) =>
          part.id === targetId
            ? { ...part, content: itemToAdd.content, itemId: itemToAdd.id }
            : part,
        )
        setSentence(updatedSentence)
      }
    } else if (
      source === 'sentence' &&
      sentence[targetIndex].type === 'blank'
    ) {
      const sourcePart = sentence.find((part) => part.id === id)
      const targetPart = sentence[targetIndex]
      if (sourcePart && targetPart) {
        const updatedSentence = sentence.map((part) => {
          if (part.id === id) return { ...part, content: '', itemId: undefined }
          if (part.id === targetId)
            return {
              ...part,
              content: sourcePart.content,
              itemId: sourcePart.itemId,
            }
          return part
        })
        setSentence(updatedSentence)
      }
    }
  }

  const handleRemoveItem = (index: number) => {
    const updatedSentence = sentence.map((part, idx) =>
      idx === index ? { ...part, content: '', itemId: undefined } : part,
    )
    setSentence(updatedSentence)
  }

  return (
    <div>
      <p className="text-bodym text-danger mt-2 md:mt-8">
        * This question carries marks for Speaking (~25%) and Reading (24%)
      </p>

      <div className="p-8 rounded-xl shadow mt-8">
        <p className="text-h4m text-neutrals-1">
          Read the text and answer the question by selecting all the correct
          responses. More than one response is correct.
        </p>
        <div className="my-4">
          {sentence.map((part, index) =>
            part.type === 'blank' ? (
              <div
                key={part.id}
                onDrop={(e) => handleDrop(e, part.id)}
                onDragOver={handleDragOver}
                draggable={!!part.content}
                onDragStart={(e) =>
                  handleDragStart(e, part.id!, 'sentence', part.itemId)
                }
                className="text-bodyr min-w-[100px] min-h-[40px] text-center content-center mx-2 mt-1 border border-[1px] border-neutrals-2 rounded-lg inline-block bg-white"
              >
                {part.content || <span className="text-placeholder"></span>}
                {part.content && (
                  <span
                    onClick={() => handleRemoveItem(index)}
                    className="text-danger cursor-pointer ml-2"
                    style={{ fontWeight: 'bold' }}
                  >
                    X
                  </span>
                )}
              </div>
            ) : (
              <span key={index} className="text-bodyr">
                {part.content}
              </span>
            ),
          )}
        </div>
        <div
          onDragOver={handleDragOver}
          className="flex gap-4 text-blue-500 border border-[1px] p-8 rounded-md cursor-pointer"
        >
          {items.map((item, idx) => (
            <div
              key={item.id}
              draggable
              onDragStart={(e) => handleDragStart(e, item.id, 'items', item.id)}
              className={`text-bodyr bg-[#F5F5F5] border border-[1px]-[#0000000F] text-neutrals-1 hover:text-white hover:bg-info px-6 py-2 rounded-md cursor-pointer ${!sentence.some((p) => p.itemId === item.id) ? '' : 'hidden'}`}
            >
              {item.content}
            </div>
          ))}
        </div>
        <div className="flex place-content-center mt-8">
          <button className="flex text-bodyr text-white items-center py-2 px-6 bg-success rounded-lg mr-2">
            <img
              src={WeeklyPredictionSubmitIcon}
              alt="submit"
              className="mr-2"
            />
            Submit
          </button>

          <button className="flex text-bodyr text-neutrals-2 items-center p-2 border border-1px-neutrals-2 rounded-lg">
            <img
              src={WeeklyPredictionKeywordIcon}
              alt="keyword"
              className="mr-2"
            />
            Unlock Keywords
          </button>
        </div>
      </div>
      <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
          <p className="hidden md:block text-bodyr text-white">Previous</p>
        </button>
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <p className="hidden md:block text-bodyr text-white">Try Again</p>
          <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
        </button>
        <div>
          <select className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y">
            {Array.from({ length: 20 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <p className="hidden md:block text-bodyr text-white">Next</p>
          <img src={NextArrowSvg} alt="next" className="md:ml-2" />
        </button>
      </div>
    </div>
  )
}

export default ReadingFillInTheBlanksQuestion
