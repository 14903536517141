import axiosInstance from '../configs/axiosConfig'

export function addToCart(
  userId: number,
  productId: number,
  productType: number,
) {
  const data = {
    user_id: userId,
    product_id: productId,
    product_type: productType,
  }

  return axiosInstance.post(`/cart/add`, data)
}

export function getCart(userId: number) {
  const data = {
    user_id: userId,
  }

  return axiosInstance.post(`/cart/get`, data)
}

export function removeCart(cartId: number) {
  return axiosInstance.delete(`/cart/remove`, { data: { cart_id: cartId } })
}

export function checkPromo(
  userId: number,
  cartId: number,
  productId: number,
  productType: number,
  promoCode: string,
) {
  const data = {
    user_id: userId,
    cart_id: cartId,
    product_id: productId,
    product_type: productType,
    promo_code: promoCode,
  }

  return axiosInstance.post(`/cart/check-promo`, data)
}
