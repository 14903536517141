import React from 'react'
import { displaySkillProgressColor } from '../../../utils/utils'

interface Score {
  [key: string]: number
}

const BarChartScoreMock: React.FC<{ score: Score }> = ({ score }) => {
  return (
    <div className="flex-1 md:pr-4">
      <div className="grid gap-y-2">
        {Object.keys(score).map((skill, index) => (
          <div key={index}>
            <div className="flex justify-between mb-1">
              <span>{skill.charAt(0).toUpperCase() + skill.slice(1)}</span>{' '}
            </div>
            <div className="flex my-2 items-center">
              <div
                className="h-6 rounded"
                style={{
                  width: `${(score[skill] / 90) * 100}%`,
                  backgroundColor: displaySkillProgressColor(score[skill]),
                }}
              ></div>
              <span className="ml-2">{score[skill]}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BarChartScoreMock
