import axiosInstance from '../configs/axiosConfig'

export function getYoutubeVideos() {
    return axiosInstance.get(`/media/youtube-videos`)
}

export function getYoutubeVideosByType() {
    return axiosInstance.get(`/media/youtube-videos/types/0`)
}

export function getYoutubeType() {
    return axiosInstance.get(`/media/youtube-type`)
}