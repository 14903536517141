import { displaySkillProgressColor, displaySkillProgressBarWidth } from '../../../utils/utils'
import { ProgessTrackerSectionalSpeaking } from '../../../models/progressTrackerSectional.model'

interface RPSpeakingScoreDetailsSectionProps {
  resultSpeakingDetails: ProgessTrackerSectionalSpeaking
}

const RPSpeakingScoreDetailsSection: React.FC<RPSpeakingScoreDetailsSectionProps> = ({ resultSpeakingDetails }) => {
  let spk9Total = 0
  resultSpeakingDetails.spk_9s.forEach(item => {
    if (item.contet !== '') {
      spk9Total+= Number(item.contet)
    }
  })

  const readAloudInfo = [
    {
      title: 'Pronunciation',
      score: resultSpeakingDetails.spk_5_pronoun,
    },
    {
      title: 'Fluency',
      score: resultSpeakingDetails.spk_5_fluency,
    },
    {
      title: 'Content',
      score: resultSpeakingDetails.spk_5_contet,
    },
    {
      title: 'Total',
      score: resultSpeakingDetails.spk_5_prgrs,
    },
  ]

  const repeatSentenceInfo = [
    {
      title: 'Pronunciation',
      score: resultSpeakingDetails.spk_6_pronoun,
    },
    {
      title: 'Fluency',
      score: resultSpeakingDetails.spk_6_fluency,
    },
    {
      title: 'Content',
      score: resultSpeakingDetails.spk_6_contet,
    },
    {
      title: 'Total',
      score: resultSpeakingDetails.spk_6_prgrs,
    },
  ]

  const describeImageInfo = [
    {
      title: 'Pronunciation',
      score: resultSpeakingDetails.spk_7_pronoun,
    },
    {
      title: 'Fluency',
      score: resultSpeakingDetails.spk_7_fluency,
    },
    {
      title: 'Content',
      score: resultSpeakingDetails.spk_7_contet,
    },
    {
      title: 'Total',
      score: resultSpeakingDetails.spk_7_prgrs,
    },
  ]

  const retellLectureInfo = [
    {
      title: 'Pronunciation',
      score: resultSpeakingDetails.spk_8_pronoun,
    },
    {
      title: 'Fluency',
      score: resultSpeakingDetails.spk_8_fluency,
    },
    {
      title: 'Content',
      score: resultSpeakingDetails.spk_8_contet,
    },
    {
      title: 'Total',
      score: resultSpeakingDetails.spk_8_prgrs,
    },
  ]

  const correctAnswerInfo = [
    {
      title: 'Score',
      score: spk9Total,
    },
  ]

  return (
    <div className="rp-st-speaking-skill-details font-landingPrimary w-full flex flex-col gap-8">
      <div className="speaking-skill-details__read-aloud h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Read Aloud</p>
        <p className="text-h5m">Total</p>
        <div className="speaking-skill-details__read-aloud__detail w-full flex flex-col gap-2 mt-[1rem]">
          {readAloudInfo.map((info, index) => (
            <div
              key={index}
              className={`speaking-skill-details__overall__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${displaySkillProgressBarWidth(info.score)}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="speaking-skill-details__repeat-sentence h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Repeat Sentence</p>
        <p className="text-h5m">Total</p>
        <div className="speaking-skill-details__repeat-sentence__detail w-full flex flex-col gap-2 mt-[1rem]">
          {repeatSentenceInfo.map((info, index) => (
            <div
              key={index}
              className={`speaking-skill-details__repeat-sentence__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${displaySkillProgressBarWidth(info.score)}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="speaking-skill-details__describe-image h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Describe Image</p>
        <p className="text-h5m">Total</p>
        <div className="speaking-skill-details__describe-image__detail w-full flex flex-col gap-2 mt-[1rem]">
          {describeImageInfo.map((info, index) => (
            <div
              key={index}
              className={`speaking-skill-details__describe-image__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${displaySkillProgressBarWidth(info.score)}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="speaking-skill-details__retell-lecture h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Retell Lecture</p>
        <p className="text-h5m">Total</p>
        <div className="speaking-skill-details__retell-lecture__detail w-full flex flex-col gap-2 mt-[1rem]">
          {retellLectureInfo.map((info, index) => (
            <div
              key={index}
              className={`speaking-skill-details__retell-lecture__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${displaySkillProgressBarWidth(info.score)}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="speaking-skill-details__correct-answer h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Answer Short Question</p>
        <p className="text-h5m">Total</p>
        <div className="speaking-skill-details__correct-answer__detail w-full flex flex-col gap-2 mt-[1rem]">
          {correctAnswerInfo.map((info, index) => (
            <div
              key={index}
              className={`speaking-skill-details__retell-lecture__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${displaySkillProgressBarWidth(info.score)}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RPSpeakingScoreDetailsSection
