interface SuccessMessageToastProps {
    message: string
  }
  
  const SuccessMessageToast: React.FC<SuccessMessageToastProps> = ({ message }) => {
    return (
      <div className="fixed top-[12vh] z-[100] lg:right-4 right-0 bg-white p-4 rounded-lg shadow-lg flex items-center space-x-4">
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
          <span className="sr-only">Success icon</span>
        </div>
        <span className="ms-3 text-sm font-normal text-green-600">{message}</span>
      </div>
    )
  }
  
  export default SuccessMessageToast