import React, { useState, useEffect, useRef } from 'react'
import ReadAloudSaveAsSvg from '../../images/Speaking/read-aloud-save-as.svg'
import ReadAloudEditSvg from '../../images/Speaking/read-aloud-edit.svg'
import ReadAloudMarkSvg from '../../images/Speaking/read-aloud-mark.svg'
import ReadAloudArrowSvg from '../../images/Speaking/read-aloud-arrow.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import ProgressBar from '../ProgressBar'

type AnswerShortQuestionProps = {
  setShowScoreBoard: (value: boolean) => void
  showScoreBoard: boolean
}

const AnswerShortQuestion: React.FC<AnswerShortQuestionProps> = ({
  setShowScoreBoard,
  showScoreBoard,
}) => {
  const maxRecordingTime = 10
  const recordingTimeout = 5

  const [initialDelay, setInitialDelay] = useState<null | number>(recordingTimeout)
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [recordingTime, setRecordingTime] = useState(maxRecordingTime)
  const [recordingProgress, setRecordingProgress] = useState(0)
  const audioRef = useRef<HTMLAudioElement>(null)
  const recorderRef = useRef<MediaRecorder | null>(null)

  const startRecording = () => {
    if (!isRecording && !audioBlob) {
      setIsRecording(true)
      setRecordingTime(10)
      setRecordingProgress(0)
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const newRecorder = new MediaRecorder(stream)
          recorderRef.current = newRecorder
          const chunks: BlobPart[] = []

          newRecorder.ondataavailable = (event) => chunks.push(event.data)
          newRecorder.onstop = () => {
            setAudioBlob(new Blob(chunks, { type: 'audio/wav' }))
            setRecordingProgress(100)
          }
          newRecorder.start()
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error)
        })
    }
  }

  const stopRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stop()
      setIsRecording(false)
      setRecordingTime(10)
      setRecordingProgress(100)
    }
  }

  const resetState = () => {
    setInitialDelay(5)
    setIsRecording(false)
    setAudioBlob(null)
    setRecordingTime(10)
    setRecordingProgress(0)
    audioRef.current &&
      (audioRef.current.src =
        'https://ptefilesbucket-stg.s3.amazonaws.com/test_question/0/64351c1c45962.wav')
  }

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (isRecording) {
      intervalId = setInterval(() => {
        setRecordingTime((prevTimer) => {
          let newTime = prevTimer - 1
          setRecordingProgress(((10 - newTime) / 10) * 100)
          if (newTime > 0) {
            return newTime
          } else {
            clearInterval(intervalId)
            stopRecording()
            return 0
          }
        })
      }, 1000)
    }
    return () => clearInterval(intervalId)
  }, [isRecording])

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (initialDelay && initialDelay > 0) {
      timerId = setTimeout(() => setInitialDelay(initialDelay - 1), 1000)
    } else if (initialDelay === 0) {
      audioRef.current?.play()
      setInitialDelay(null)
    }
    return () => clearTimeout(timerId)
  }, [initialDelay])

  return (
    <div>
      <p className="text-bodym text-danger mt-2 md:mt-8">
        * This question carries marks for Speaking (~25%) and Reading ( 24%){' '}
      </p>

      <div className="p-8 rounded-xl shadow mt-8">
        <p className="text-h4m text-neutrals-1">
          You will hear a sentence. Please repeat the sentence exactly as you
          hear it. You will hear the sentence only one.
        </p>

        {!isRecording && !audioBlob && (
          <div className="bg-[#F1EFF2] text-center p-4 rounded-xl w-[50%] mx-auto mt-4">
            {initialDelay ? (
              <p className="text-h4m text-neutrals-1">
                Audio will start in: {initialDelay} seconds
              </p>
            ) : (
              <p className="text-h4m text-neutrals-1">Original Audio</p>
            )}

            <audio
              ref={audioRef}
              src="https://ptefilesbucket-stg.s3.amazonaws.com/test_question/0/64351c1c45962.wav"
              preload="auto"
              controls
              className="my-4 w-full"
            ></audio>

            <button
              onClick={startRecording}
              className="mx-auto flex items-center px-2 md:px-4 py-2 bg-white rounded-xl cursor-pointer hover:scale-105"
            >
              <p className="text-bodyr text-neutrals-1">Record Now</p>
            </button>
          </div>
        )}

        {isRecording && (
          <div className="bg-[#F1EFF2] text-center p-4 rounded-xl w-[50%] mx-auto mt-4">
            <p className="text-h5r mt-4">Timer: {recordingTime} seconds</p>

            <ProgressBar progress={recordingProgress} />

            <button
              onClick={stopRecording}
              className="text-bodyr text-neutrals-1 p-2 mt-2 rounded-lg border border-[1px]-[#D9D9D9] hover:scale-105"
            >
              Stop Recording
            </button>
          </div>
        )}

        <div className="flex items-center pt-4">
          <p className="text-bodym text-neutrals-1">My note:</p>
          <img
            src={ReadAloudSaveAsSvg}
            alt="save as"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudEditSvg}
            alt="edit"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudMarkSvg}
            alt="mark"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
        </div>
        <div
          className="flex items-center justify-center mt-4 cursor-pointer"
          onClick={() => setShowScoreBoard(!showScoreBoard)}
        >
          <img
            src={ReadAloudArrowSvg}
            alt="arrow"
            className={`transition-transform duration-300 ${showScoreBoard ? 'rotate-180' : 'rotate-0'}`}
          />
          <p className="text-bodyr text-info ml-2">Show Score Board</p>
        </div>
      </div>

      <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
          <p className="hidden md:block text-bodyr text-white">Previous</p>
        </button>
        <div className="flex text-white">
          <button
            className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer mx-4"
            onClick={resetState}
          >
            <p className="hidden md:block ext-bodyr text-white">Try Again</p>
            <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
          </button>
        </div>

        <div>
          <select className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y">
            {Array.from({ length: 20 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>

        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <p className="hidden md:block text-bodyr text-white">Next</p>
          <img src={NextArrowSvg} alt="next" className="md:ml-2" />
        </button>
      </div>

      <div className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4">
        <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl text-center">
          <p className="text-h4m">Original Answer</p>
          <p className="text-bodyr">
            What is the collective name for pencil, eraser and ruler?
            <br></br>
            Answer: Stationery
          </p>
        </div>
        <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl text-center">
          <p className="text-h4m">Your Answer</p>
          {audioBlob && (
            <>
              <audio
                controls
                src={URL.createObjectURL(audioBlob)}
                className="my-4 w-full"
              />

              <p className="text-bodyr text-danger">
                Your answer is incorrect!
              </p>
              <p className="text-bodyr">Your transcript: Pencil</p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default AnswerShortQuestion
