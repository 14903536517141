import { displaySkillProgressColor, displaySkillProgressBarWidth } from '../../../utils/utils'
import { ProgessTrackerSectionalWriting } from '../../../models/progressTrackerSectional.model'

interface RPWritingScoreDetailsSectionnProps {
  resultWritingDetails: ProgessTrackerSectionalWriting
}


const RPWritingScoreDetailsSection: React.FC<RPWritingScoreDetailsSectionnProps> = ({ resultWritingDetails }) => {
  const summarizeWrittenTextInfo = [
    {
      title: 'Grammar',
      score: resultWritingDetails.spk_10_grammer,
    },
    {
      title: 'Form',
      score: resultWritingDetails.spk_10_form,
    },
    {
      title: 'Content',
      score: resultWritingDetails.spk_10_contet,
    },
    {
      title: 'Vocabulary',
      score: resultWritingDetails.spk_10_contet,
    },
    {
      title: 'Total',
      score: resultWritingDetails.spk_10_prgrs,
    },
  ]

  const writeEssayInfo = [
    {
      title: 'Grammar',
      score: resultWritingDetails.spk_11_grammer,
    },
    {
      title: 'Spelling',
      score: resultWritingDetails.spk_11_spell,
    },
    {
      title: 'Form',
      score: resultWritingDetails.spk_11_form,
    },
    {
      title: 'Content',
      score: resultWritingDetails.spk_11_contet,
    },
    {
      title: 'Vocabulary',
      score: resultWritingDetails.spk_11_vocab,
    },
    {
      title: 'Linguistic range',
      score: resultWritingDetails.spk_11_vocab,
    },
    {
      title: 'Structure',
      score: resultWritingDetails.spk_11_vocab,
    },
    {
      title: 'Total',
      score: resultWritingDetails.spk_11_prgrs,
    },
  ]

  return (
    <div className="rp-st-writing-skill-details font-landingPrimary w-full flex flex-col gap-8">
      <div className="writing-skill-details__swt h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Summarize Written Text</p>
        <p className="text-h5m">Total</p>
        <div className="writing-skill-details__swt__detail w-full flex flex-col gap-2 mt-[1rem]">
          {summarizeWrittenTextInfo.map((info, index) => (
            <div
              key={index}
              className={`writing-skill-details__swt__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${displaySkillProgressBarWidth(info.score)}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="writing-skill-details__we h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Write Essay</p>
        <p className="text-h5m">Total</p>
        <div className="writing-skill-details__we__detail w-full flex flex-col gap-2 mt-[1rem]">
          {writeEssayInfo.map((info, index) => (
            <div
              key={index}
              className={`writing-skill-details__we__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${(info.score / 90) * 100}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RPWritingScoreDetailsSection
