interface ErrorMessageToastProps {
  message: string
}

const ErrorMessageToast: React.FC<ErrorMessageToastProps> = ({ message }) => {
  return (
    <div className="fixed top-[12vh] z-[100] lg:right-4 right-0 bg-white p-4 rounded-lg shadow-lg flex items-center space-x-4">
      <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
        </svg>
        <span className="sr-only">Error icon</span>
      </div>
      <span className="ms-3 text-sm font-normal text-red-600">{message}</span>
    </div>
  )
}

export default ErrorMessageToast
