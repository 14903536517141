import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import GoogleLoginButton from './GoogleLoginButton'
import ErrorMessageToast from '../../Items/ErrorMessageToast'
import SuccessMessageToast from '../../Items/SuccessMessageToast'
import Path from '../../../routes/Path'
import { useAuth } from '../../../providers/AuthProvider'

const LoginForm: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [displayMsg, setDisplayMsg] = useState(true)
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const { login } = useAuth();
  const navigate = useNavigate()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async(data: any) => {
    try {
      await login(data.email, data.password);
      setIsDisabled(true)
      setSuccessMsg('Login successful!');
      setTimeout(() => {
        setSuccessMsg('')
        navigate(Path.home.path)
      }, 3000)
    } catch (error: any) {
      setIsDisabled(false)
      setErrorMsg(error.message)
    } finally {
      setDisplayMsg(true)

      setTimeout(() => {
        setErrorMsg('')
        setSuccessMsg('')
        setDisplayMsg(false)
      }, 3000)
    }
  }

  return (
    <div className="register-form lg:w-[31.5rem] md:w-2/3 lg:h-fit w-full h-full bg-neutrals-4 rounded-xl p-8">
      <GoogleLoginButton disabled={isDisabled} setDisabled={setIsDisabled}/>
      <hr className="my-[2rem]" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-1">
            Email 
          </label>
          <input
            type="text"
            id="email"
            className="w-full border border-[#D9D9D9] rounded-md px-4 py-2"
            placeholder="Type your email or username here"
            {...register('email', {
              required: 'Please enter your email address!',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address!',
              },
            })}
          />
          {errors.email && (
            <div className="text-red-500 mb-4">{errors.email.message}</div>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-1">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="w-full border border-[#D9D9D9] rounded-md px-4 py-2"
            placeholder="Type your password here"
            {...register('password', {
              required: 'Please enter your password!',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters!',
              },
            })}
          />
          {errors.password && (
            <div className="text-red-500 mb-4">{errors.password.message}</div>
          )}
        </div>
        <div className="mb-4">
          <p className="text-bodyr text-right text-h5r">
            Forgot your password?{' '}
            <Link to="/" className="text-[#00254D]">
              Get help
            </Link>{' '}
          </p>
        </div>
        <button
          type="submit"
          className={`w-full rounded-md p-3.5 text-white ${isDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-info hover:bg-[#1890FF]'}`}
          disabled={isDisabled}
        >
          Log in account
        </button>
        <div className="text-center my-2">
          <Link
            to="/register"
            className="center text-bodyr font-landingPrimary text-info"
          >
            Create new account
          </Link>
        </div>
      </form>
      { displayMsg && successMsg !== '' &&  <SuccessMessageToast message={successMsg} /> }
      { displayMsg && errorMsg !== '' &&  <ErrorMessageToast message={errorMsg} /> }
    </div>
  )
}

export default LoginForm
