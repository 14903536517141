import { useState } from 'react'
import { Link } from 'react-router-dom'
import YourPurchaseSection from '../components/PurchasePage/PurchaseSection'

const PurchasePage = () => {
  const [selectedCategory, setSelectedCategory] = useState('Practice Token')

  // Function to handle the selected category
  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category)
  }

  return (
    <>
      <div className="navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Purchase / {selectedCategory}
        </p>
      </div>
      <div className="w-full lg:min-h-[56rem] h-fit lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <YourPurchaseSection onSelectCategory={handleCategoryChange} />
      </div>
    </>
  )
}

export default PurchasePage
