interface LoadingMessageProps {
    message: string
}
  
const LoadingMessage: React.FC<LoadingMessageProps> = ({ message }) => {
  return (
      <div className="p-4 text-sm text-blue-800 rounded-lg bg-blue-200 border border-blue-600" role="alert">
        <span className="font-medium">{message}</span>
      </div>
  )
}
  
export default LoadingMessage