import { useNavigate } from 'react-router-dom'
import { useState, useRef, useEffect, ChangeEvent } from 'react'
import { ReactComponent as CutIcon } from '../../../images/SectionalTest/st-cut-icon.svg'
import { ReactComponent as CopyIcon } from '../../../images/SectionalTest/st-copy-icon.svg'
import { ReactComponent as PasteIcon } from '../../../images/SectionalTest/st-paste-icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../images/SectionalTest/st-arrow-right-icon.svg'
import { ExtendedQuestion } from '../../../models/question.model'
import { useAuth } from '../../../providers/AuthProvider'
import { remvoveTestInformation } from '../../../utils/utils'
import { doTest3Update, addResultWritingSectional } from '../../../services/sectionalTest.services'
import Path from '../../../routes/Path'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'

const WriteEssaySectional = () => {
  const navigate = useNavigate()
  const [wordsCount, setWordsCount] = useState(0)
  const [timer, setTimer] = useState({ minutes: 20, seconds: 0 })
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(undefined)
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [disabled, setIsDisabled] = useState(false)
  const { userProfile } = useAuth()

  // Ref to store the elapsed time in seconds
  const elapsedTimeRef = useRef(0)

  useEffect(() => {
    setTimeout(() => {
      const reseultData = localStorage.getItem('resultSectionalWPTE')
      if (reseultData) {
        const parsedResultData = JSON.parse(reseultData)
        setQuestion(parsedResultData.mockqestion[0])
        setQuestionNumber(parsedResultData.row)
        setTotalQuestion(Number(parsedResultData.allcount))
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [])

  // Function to start the timer countdown
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (timer.minutes === 0 && timer.seconds === 0) {
      // Timer has reached 00:00, stop the timer
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        elapsedTimeRef.current += 1

        if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 })
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 })
        }
      }, 1000)
    }

    // Cleanup function to clear the interval when component unmounts or timer stops
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timer])

  const formattedTime = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`

  // Function to calculate the total elapsed time in seconds
  const calculateElapsedTime = () => {
    return elapsedTimeRef.current
  }

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const text = e.target.value
    const wordCount = text.trim().split(/\s+/).filter(Boolean).length
    setUserAnswer(text.trim())
    setWordsCount(wordCount)
  }

  const handleSubmitAnswer = async () => {
    setIsDisabled(true)
    const elapsedTime = calculateElapsedTime()

    // const buyId = Number(localStorage.getItem('buyIdWPTESectional')) || 0
    // const testId = Number(localStorage.getItem('testIdWPTESectional')) || 0

    if (question && userProfile && userProfile.userId) {
      try {
        const addResultResponse = await addResultWritingSectional(
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          question.buyId,
          userAnswer
        )
      
        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest3Update(
            question.buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            2,
            String(elapsedTime),
            String(elapsedTime),
            totalQuestion,
            'Skip'
          )
      
          if (getQuestionResponse.data.success) {
            if (getQuestionResponse.data.message === 'Calculate report card fetch successfully') {
              remvoveTestInformation(3)
      
              navigate(Path.reportHistorySectional.writing.path.replace(":buyId", String(getQuestionResponse.data.ubuyid)))
            } else {
              localStorage.setItem('resultSectionalWPTE', JSON.stringify(getQuestionResponse.data.result))
      
              if (getQuestionResponse.data.category_id === '11') {
                navigate(0) // Reload the same page
              }
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        if (error instanceof TypeError) {
          console.error(error.message)
        } else {
          console.error('Something went wrong!')
        }
      }
    } else {
      console.error('Userprofile or question not found!')
    }
  }

  const handleSkip = async () => {
    setIsDisabled(true)
    const elapsedTime = calculateElapsedTime()

    if (question && userProfile && userProfile.userId) {
      try {
        const getQuestionResponse = await doTest3Update(
          question.buyId,
          question.testId,
          userProfile.userId,
          questionNumber,
          2,
          String(elapsedTime),
          String(elapsedTime),
          totalQuestion,
          'Skip'
        )
    
        if (getQuestionResponse.data.success) {
          if (getQuestionResponse.data.message === 'Calculate report card fetch successfully') {
            remvoveTestInformation(3)
    
            navigate(Path.reportHistorySectional.writing.path.replace(":buyId", String(getQuestionResponse.data.ubuyid)))
          } else {
            localStorage.setItem('resultSectionalWPTE', JSON.stringify(getQuestionResponse.data.result))
    
            if (getQuestionResponse.data.category_id === '11') {
              navigate(0) // Reload the same page
            }
          }
        } else {
          console.error(getQuestionResponse.data.message)
        }
      } 
      catch (error) {
        if (error instanceof TypeError) {
          console.error(error.message)
        } else {
          console.error('Something went wrong!')
        }
      }
    } else {
      console.error('Userprofile or question not found!')
    }
  }

  return (
    <div className='min-h-[80vh]'>
    {
      isLoading ? (
        <LoadingMessage message='Loading question...'/> 
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div className="st-we w-full pb-4 shadow-question-card mx-auto font-landingPrimary">
          <div className="st-we__header flex justify-between p-4 mb-4 bg-info rounded-lg text-white text-h5r">
            <p className="st-we__header__title">
              Wrtiting Section - Write Essay (#{question?.id})
            </p>
            <p className="st-we__header__number">({questionNumber + 1} Out of 3)</p>
          </div>
          <div className="st-we__timer w-[6.25rem] h-[3.5rem] bg-primary-2 mb-6 rounded-lg flex justify-center items-center mx-auto">
            <p className="text-h2 text-white">{formattedTime}</p>
          </div>
          <div className="st-we__instruction text-h4m px-4 mb-4">
            {question?.shortTitle}
          </div>
          <div
            className="st-we__content text-h5r px-4 mb-4"
            dangerouslySetInnerHTML={{ __html: question?.question || "Content not available" }}
          ></div>
          <div className="st-we__answer flex flex-col gap-2 px-4 mb-4">
            <label htmlFor="we-answer" className="st-swt__answer__label text-h5b">
              Your answer
            </label>
            <textarea
              className="w-full h-[5rem] text-bodyr border border-1 border-[#D9D9D9] p-2 resize-none"
              placeholder="Write here..."
              onChange={handleTextChange}
            />
          </div>
          <div className="st-we__actions flex gap-2 w-full h-[2.215rem] px-4 mb-4">
            <button className="st-we__actions__copy w-[5rem] h-full text-white text-bodyr bg-primary-5 hover:bg-[#3C638B] rounded-lg flex items-center justify-center gap-2">
              <CopyIcon />
              Copy
            </button>
            <button className="st-we__actions__cut w-[4.375rem] h-full text-white text-bodyr bg-primary-5 hover:bg-[#3C638B] rounded-lg flex items-center justify-center gap-2">
              <CutIcon />
              Cut
            </button>
            <button
              onClick={() =>
                alert('Use Ctrl + v to paste in WINDOWS os and command + v in MAC')
              }
              className="st-we__actions__paste w-[5rem] h-full text-white text-bodyr bg-primary-5 hover:bg-[#3C638B] rounded-lg flex items-center justify-center gap-2"
            >
              <PasteIcon />
              Paste
            </button>
          </div>
          <p className="st-we__words-count text-body px-4 mb-4">
            Words count: {wordsCount}
          </p>
          <div className='w-full flex justify-center gap-4'>
            <button
              onClick={handleSubmitAnswer}
              disabled={disabled || wordsCount === 0}
              className={`w-[8.438rem] h-[2.375rem] ${
                disabled || wordsCount === 0 ? 'bg-gray-400' : 'bg-success'
              } rounded-lg flex gap-2 justify-center items-center text-white text-bodyr`}
            >
              Save and next
              <ArrowRightIcon />
            </button>        
            <button 
              className={`flex items-center px-6 py-2 rounded-lg 
                ${disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
              disabled={disabled}
              onClick={() => handleSkip()}
            >
              <p className="text-bodyr text-white">Skip</p>
            </button>  
          </div>
        </div>
      )
    }
    </div>
  )
}

export default WriteEssaySectional
