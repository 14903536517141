const sampleData = [
  {
    id: 1,
    test: 'Summarize Written Text',
    answer:
      'continental, drift, single, geologic, intra, lighter, rocks, volcanoes, crustal, plate, Ring, Fire,',
    yourAnswer: `The theory of <b style='background-color: aqua;'>continental</b> <b style='background-color: aqua;'>drift</b>, initially proposed by Alfred Wegener in 1912, suggests that Earth's continents were once a <b style='background-color: aqua;'>single</b> landmass that gradually separated, leading to the current seven continents, with the accepted <b style='background-color: aqua;'>plate</b> tectonics theory explaining this movement as a result of shifting slabs or <b style='background-color: aqua;'>plates</b> on the Earth's surface, often causing volcanic activity along <b style='background-color: aqua;'>plate</b> boundaries like the <b style='background-color: aqua;'>Ring</b> of <b style='background-color: aqua;'>Fire</b>, though some <b style='background-color: aqua;'>volcanoes</b>, such as those in the Hawaiian Islands, are associated with <b style='background-color: aqua;'><b style='background-color: aqua;'>intraplate</b></b> volcanic chains`,
    grammar: '2/2',
    content: '2.67/4',
    spelling: '0',
    form: '0/1',
    overallScore: '4.67/7',
  },
  {
    id: 2,
    test: 'Summarize Written Text',
    answer:
      'Fish, prevented, maturity, plastic, particles, indestructible, microbeads, decades, oceans, micro,',
    yourAnswer: `New research has confirmed that <b style='background-color: aqua;'>plastic</b> <b style='background-color: aqua;'>particles</b> entering the world's <b style='background-color: aqua;'>oceans</b> are causing the death of young <b style='background-color: aqua;'>fish</b>, as some of them prefer to consume these tiny <b style='background-color: aqua;'>plastic</b> fragments over their natural food sources, posing a significant threat to <b style='background-color: aqua;'>fish</b> populations and marine ecosystems.`,
    grammar: '2/2',
    content: '1.60/4',
    spelling: '0',
    form: '1/1',
    overallScore: '4.6/7',
  },
  {
    id: 3,
    test: 'Write Essay',
    answer:
      'subject, suitable, future, employment, possibilities, interested, good, grades,',
    yourAnswer: `When selecting a <b style='background-color: aqua;'>subject</b> to study, the debate often centers around choosing one that offers promising <b style='background-color: aqua;'>future</b> <b style='background-color: aqua;'>employment</b> prospects versus one that aligns with personal interests or strengths. While practical considerations are essential, I firmly believe that pursuing a <b style='background-color: aqua;'>subject</b> of interest or one where one can excel is more beneficial in the long run.\r\nFirstly, studying a <b style='background-color: aqua;'>subject</b> one is passionate about significantly enhances motivation and engagement. Enthusiasm for a topic naturally drives curiosity, leading to deeper understanding and retention of knowledge. When students are genuinely <b style='background-color: aqua;'>interested</b> in their studies, they are more likely to participate actively, seek additional resources, and enjoy the learning process. This intrinsic motivation often translates into better academic performance, which can be just as advantageous as selecting a high-demand field.\r\nMoreover, excelling in a <b style='background-color: aqua;'>subject</b> can open various opportunities.`,
    grammar: '2/2',
    content: '4.5/9',
    spelling: '1/2',
    form: '1/2',
    overallScore: '8.5/15',
  },
]

const HistoryDetailsWritingMock = () => {
  // Function to create markup for dangerouslySetInnerHTML
  const createMarkup = (html: string) => {
    return { __html: html }
  }

  return (
    <div className="history-details-writing w-full h-full mt-[1rem] p-2 overflow-x-auto font-landingPrimary">
      <div className="min-w-full inline-block align-middle overflow-hidden">
        <table className="min-w-full table-fixed">
          <thead>
            <tr className="text-bodyr text-neutrals-2 h-[3.75rem]">
              <th className="w-[8rem] p-2" align="left">
                Test
              </th>
              <th className="w-[14rem] p-2" align="left">
                Answer
              </th>
              <th className="w-[20rem] p-2" align="left">
                Your Answer
              </th>
              <th className="w-[5.2rem] p-2" align="left">
                Grammar
              </th>
              <th className="w-[5.2rem] p-2" align="left">
                Content
              </th>
              <th className="w-[5.2rem] p-2" align="left">
                Spelling
              </th>
              <th className="w-[5.2rem] p-2" align="left">
                Form
              </th>
              <th className="w-[5.2rem] p-2" align="left">
                Overall Score
              </th>
            </tr>
          </thead>
          <tbody>
            {sampleData.map((data, index) => (
              <tr
                key={index}
                className={`text-bodyr ${index % 2 === 0 ? 'bg-[#FAFAFA]' : ''}`}
              >
                <td className="p-2 text-bodym rounded-l-xl">{data.test}</td>
                <td className="p-2 text-bodyr">{data.answer}</td>
                <td
                  className="p-2 text-bodyr"
                  dangerouslySetInnerHTML={createMarkup(data.yourAnswer)}
                ></td>
                <td className="p-2 text-bodyr">{data.grammar} </td>
                <td className="p-2 text-bodyr">{data.content} </td>
                <td className="p-2 text-bodyr">{data.spelling} </td>
                <td className="p-2 text-bodyr">{data.form} </td>
                <td className="p-2 text-bodyr rounded-r-xl">
                  {data.overallScore}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default HistoryDetailsWritingMock
