import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import menuIcon from '../images/Sidebar/sidebar-menu.svg'
import homeIcon from '../images/Sidebar/sidebar-home.svg'
import libraryBlackIcon from '../images/Sidebar/sidebar-library.svg'
import libraryWhiteIcon from '../images/Sidebar/sidebar-library-white.svg'
import sectionalTestBlackIcon from '../images/Sidebar/sidebar-sectional-test.svg'
import sectionalTestWhiteIcon from '../images/Sidebar/sidebar-sectional-test-white.svg'
import predictionIcon from '../images/Sidebar/sidebar-prediction.svg'
import mockTestBlackIcon from '../images/Sidebar/sidebar-mock-test.svg'
import mockTestWhiteIcon from '../images/Sidebar/sidebar-mock-test-white.svg'
import communityBlackIcon from '../images/Sidebar/sidebar-community.svg'
import communityWhiteIcon from '../images/Sidebar/sidebar-community-white.svg'
import performanceIcon from '../images/Sidebar/sidebar-performance.svg'
import supportIcon from '../images/Sidebar/sidebar-support.svg'
import sidebarImage from '../images/Sidebar/sidebar-image.png'
import arrowRightBlackIcon from '../images/Sidebar/sidebar-arrow-right.svg'
import arrowRightWhiteIcon from '../images/Sidebar/sidebar-arrow-right-white.svg'
import dotIcon from '../images/Sidebar/sidebar-dot.svg'
import practiceAIIcon from '../images/Sidebar/sidebar-practice-ai.svg'
import buyTokenIcon from '../images/Sidebar/sidebar-buy-token.svg'
import myTokenIcon from '../images/Sidebar/sidebar-my-token.svg'
import readingTestIcon from '../images/Sidebar/sidebar-reading-test.svg'
import listeningTestIcon from '../images/Sidebar/sidebar-listening-test.svg'
import writingTestIcon from '../images/Sidebar/sidebar-writing-test.svg'
import speakingTestIcon from '../images/Sidebar/sidebar-speaking-test.svg'
import analysisIcon from '../images/Sidebar/sidebar-analysis.svg'
import liveChatIcon from '../images/Sidebar/sidebar-live-chat.svg'
import joinUsArrow from '../images/Sidebar/sidebar-join-us-arrow.svg'
import { useAuth } from '../providers/AuthProvider'

type SidebarProps = {
  isCollapsed: boolean
  toggleSidebar: () => void
}

const SideBar = ({ isCollapsed, toggleSidebar }: SidebarProps) => {
  const [nestedVisible, setNestedVisible] = useState<{
    [key: number]: boolean
  }>({})
  const [hideText, setHideText] = useState(false)
  const location = useLocation()
  const { userProfile } = useAuth()

  const toggleCollapse = () => {
    if (!isCollapsed) {
      setHideText(true)
    }
    toggleSidebar()
  }

  useEffect(() => {
    if (!isCollapsed) {
      setTimeout(() => {
        setHideText(false)
      }, 300)
    }
  }, [isCollapsed])

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: {
      nestedItems?: { label: string; link: string }[]
      notification?: string
    },
    index: number,
  ) => {
    if (item.nestedItems) {
      event.preventDefault()
      toggleNestedVisibility(index)
    }
  }

  const toggleNestedVisibility = (index: number) => {
    setNestedVisible((prev) => ({ ...prev, [index]: !prev[index] }))
  }

  const widthClass = isCollapsed ? 'w-20' : 'w-64'

  const sidebarItems = [
    { icon: homeIcon, label: 'PTE Master', link: '/' },

    {
      icon: libraryBlackIcon,
      openIcon: libraryWhiteIcon,
      label: 'Practice',
      link: '/practice',
      nestedItems: [
        {
          label: 'Practice AI',
          icon: practiceAIIcon,
          link: '/practice-ai',
        },
        {
          label: 'Buy Token',
          icon: buyTokenIcon,
          link: '/shop',
        },
        {
          label: 'My Token',
          icon: myTokenIcon,
          link: '/purchase',
        },
      ],
    },
    {
      icon: sectionalTestBlackIcon,
      openIcon: sectionalTestWhiteIcon,
      label: 'Sectional Test',
      link: '/sectional-test',
      nestedItems: [
        {
          label: 'Reading',
          icon: readingTestIcon,
          link: '/sectional-test/start-test-now-4',
        },
        {
          label: 'Listening',
          icon: listeningTestIcon,
          link: '/sectional-test/start-test-now-5',
        },
        {
          label: 'Writing',
          icon: writingTestIcon,
          link: '/sectional-test/start-test-now-3',
        },
        {
          label: 'Speaking',
          icon: speakingTestIcon,
          link: '/sectional-test/start-test-now-2',
        },
        {
          label: 'Test History and Analysis',
          icon: analysisIcon,
          link: '/sectional-test/history',
        },
      ],
    },
    {
      icon: predictionIcon,
      label: 'Weekly Prediction',
      link: '/weekly-prediction',
      notification: '06/11-13/11',
    },
    {
      icon: mockTestBlackIcon,
      openIcon: mockTestWhiteIcon,
      label: 'Mock Test',
      link: '/mock-test',
      nestedItems: [
        {
          label: 'Buy Mock Test',
          icon: buyTokenIcon,
          link: '/shop',
        },
        {
          label: 'My Unlocked Tests',
          icon: myTokenIcon,
          link: '/my-unlocked-test',
        },
        {
          label: 'Test History and Analysis',
          icon: analysisIcon,
          link: '/mock-test/history',
        },
      ],
    },
    {
      icon: communityBlackIcon,
      openIcon: communityWhiteIcon,
      label: 'Community',
      link: '/community',
      nestedItems: [
        // {
        //   label: 'Live Chat',
        //   icon: liveChatIcon,
        //   link: '/live-chat',
        //   notification: '0',
        // },
        {
          label: 'PTE videos',
          icon: liveChatIcon,
          link: '/pte-videos',
        },
        {
          label: 'Join us on Telegram',
          link: '/join-us-on-telegram',
        },
      ],
    },
    {
      icon: performanceIcon,
      label: 'My Performance',
      link: '/my-performance',
    },
    { icon: supportIcon, label: 'Support', link: '/support' },
  ]

  return (
    <div
      className={`inset-y-0 lg:static transition-all duration-300 ease-in-out ${widthClass}`}
    >
      <div className="min-h-screen px-2 pt-1">
        <div
          className={`flex justify-between items-center p-4 pb-6 mb-2 border-b-2 border-gray-100 ${hideText && '!place-content-center'}`}
        >
          <p
            className={`text-h4m text-info truncate transition-opacity duration-300 ${hideText ? 'opacity-0 invisible hidden' : 'opacity-100 visible'}`}
          >
            Welcome, {userProfile?.name}
          </p>
          <img
            src={menuIcon}
            alt="Menu"
            onClick={toggleCollapse}
            className="hover:scale-105 cursor-pointer"
          />
        </div>
        <div>
          {sidebarItems.map((item, index) => (
            <div key={index}>
              <Link
                to={item.nestedItems ? '#' : item.link}
                className={`flex justify-between items-center hover:scale-105 py-4 px-2 ${
                  hideText && '!place-content-center'
                }
                ${nestedVisible[index] ? 'bg-primary-6' : 'hover:bg-gray-100'}
                `}
                onClick={(event) => handleLinkClick(event, item, index)}
              >
                <div className="flex gap-2">
                  <img
                    src={nestedVisible[index] ? item.openIcon : item.icon}
                    alt={item.label}
                  />
                  <p
                    className={`text-bodyr ${nestedVisible[index] ? 'text-white' : 'text-neutral-1'} transition-opacity duration-300 ${hideText ? 'opacity-0 invisible hidden' : 'opacity-100 visible'}`}
                  >
                    {item.label}
                  </p>
                </div>
                {item.nestedItems && !isCollapsed && (
                  <img
                    src={
                      nestedVisible[index]
                        ? arrowRightWhiteIcon
                        : arrowRightBlackIcon
                    }
                    alt="Expand"
                    className={`hover:scale-105 transition-transform duration-300 transform ${hideText ? 'opacity-0 invisible hidden' : 'opacity-100 visible'} ${nestedVisible[index] ? 'rotate-90' : 'rotate-0'}`}
                  />
                )}
                {item.notification && !isCollapsed && (
                  <div
                    className={`bg-danger text-white px-1 rounded-full transition-transform duration-300 transform ${hideText ? 'opacity-0 invisible hidden' : 'opacity-100 visible'}`}
                  >
                    <p className="text-footnote">{item.notification}</p>
                  </div>
                )}
              </Link>
              <div
                style={{
                  maxHeight: nestedVisible[index] ? '500px' : '0',
                  transition: 'max-height 0.3s ease',
                }}
                className="overflow-hidden"
              >
                {item.nestedItems &&
                  nestedVisible[index] &&
                  item.nestedItems.map((nestedItem, nestedIndex) => (
                    <Link
                      to={nestedItem.link}
                      key={nestedIndex}
                      className={`flex justify-between py-2 pl-4 pr-2 hover:px-4 block hover:bg-gray-100 hover:opacity-100 hover:bg-primary-1 hover:scale-105  ${hideText ? 'opacity-0 invisible hidden' : 'opacity-100 visible'}
                      
                      ${location.pathname === nestedItem.link ? 'bg-primary-1 !opacity-100' : nestedItem.icon ? 'opacity-45' : 'opacity-100'}
                      `}
                    >
                      <div className="flex gap-2">
                        {nestedItem.icon && (
                          <img src={nestedItem.icon} alt={nestedItem.label} />
                        )}

                        <p
                          className={`
                            ${
                              nestedItem.icon
                                ? 'text-neutral-1 text-bodyr'
                                : 'text-info text-bodym'
                            }
                            ${index === 1 && nestedIndex === 0 && 'text-danger !text-bodyb'}
                            `}
                        >
                          {nestedItem.label}
                        </p>
                      </div>
                      {nestedItem.icon ? (
                        'notification' in nestedItem &&
                        nestedItem.notification ? (
                          <div className="bg-danger text-white px-3 rounded-full">
                            <p className="text-footnote">
                              {/* {nestedItem.notification} */}
                            </p>
                          </div>
                        ) : (
                          <img
                            src={dotIcon}
                            alt="Dot"
                            className="hover:scale-105"
                          />
                        )
                      ) : (
                        <img
                          src={joinUsArrow}
                          alt="Expand"
                          className="hover:scale-105"
                        />
                      )}
                    </Link>
                  ))}
              </div>
            </div>
          ))}

          {!isCollapsed && (
            <Link to="/last-minute-study">
              <img
                src={sidebarImage}
                alt="Sidebar"
                className="px-2 pt-4 hover:scale-105"
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default SideBar
