export enum QuestionModel {
  ReadAloud = 'Read Aloud',
  RepeatSentences = 'Repeat Sentences',
  DescribeImages = 'Describe Images',
  RetellLectures = 'Re-tell Lectures',
  AnswerShortQuestions = 'Answer Short Questions',
  SummarizeWrittenText = 'Summarize Written Text',
  WriteEssay = 'Write Essay',
  MultipleChoiceChooseSingleAnswer = 'Multiple-choice, choose single answer',
  ReadingMultipleChoiceChooseSingleAnswer = 'Reading: Multiple-choice, choose single answer',
  MultipleChoiceChooseMultipleAnswers = 'Multiple-choice, choose multiple answers',
  ReadingMultipleChoiceChooseMultipleAnswers = 'Reading: Multiple-choice, choose multiple answers',
  ReorderParagraphs = 'Re-order paragraphs',
  ReadingFillInTheBlanks = 'Reading: Fill in the blanks',
  ReadingAndWritingFillInTheBlanks = 'Reading and writing: Fill in the blanks',
  SummarizeSpokenText = 'Summarize Spoken Text',
  FillInTheBlanks = 'Fill in the blanks',
  HighlightCorrectSummary = 'Highlight correct summary',
  SelectMissingWord = 'Select missing word',
  HighlightIncorrectWords = 'Highlight incorrect words',
  WriteFromDictation = 'Write from dictation',
}

export enum SkillName {
  Reading = 'Reading',
  Listening = 'Listening',
  Speaking = 'Speaking',
  Writing = 'Writing',
}

export enum Status {
  TokenRequired = 'Token Required',
  FreeCourse = 'Free Course',
  SoldOut = 'Sold Out',
  Available = 'Available',
}

export enum PracticeType {
  AllQuestions = 'All Questions',
  AttemptedQuestions = 'Attempted Questions',
  NotAttemptedQuestions = 'Not Attempted Questions',
}
export enum Type {
  All = 'All',
  Easy = 'Easy',
  Medium = 'Medium',
  Hard = 'Hard',
}

export interface SpeakingQuestion {
  id: string
  name: string
  description: string
  practiceType: PracticeType
  type: Type
}

export interface Test {
  name: QuestionModel
  zone: SkillName
  amount: number
  status: Status
}

export interface Category {
  name: SkillName
  questionModels: QuestionModel[]
}
