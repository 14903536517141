import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'
import menuIcon from '../images/Sidebar/sidebar-menu.svg'
import homeIcon from '../images/Sidebar/sidebar-home.svg'
import libraryBlackIcon from '../images/Sidebar/sidebar-library.svg'
import libraryWhiteIcon from '../images/Sidebar/sidebar-library-white.svg'
import sectionalTestBlackIcon from '../images/Sidebar/sidebar-sectional-test.svg'
import sectionalTestWhiteIcon from '../images/Sidebar/sidebar-sectional-test-white.svg'
import predictionIcon from '../images/Sidebar/sidebar-prediction.svg'
import mockTestBlackIcon from '../images/Sidebar/sidebar-mock-test.svg'
import mockTestWhiteIcon from '../images/Sidebar/sidebar-mock-test-white.svg'
import communityBlackIcon from '../images/Sidebar/sidebar-community.svg'
import communityWhiteIcon from '../images/Sidebar/sidebar-community-white.svg'
import performanceIcon from '../images/Sidebar/sidebar-performance.svg'
import supportIcon from '../images/Sidebar/sidebar-support.svg'
import arrowRightBlackIcon from '../images/Sidebar/sidebar-arrow-right.svg'
import arrowRightWhiteIcon from '../images/Sidebar/sidebar-arrow-right-white.svg'
// import dotIcon from '../images/Sidebar/sidebar-dot.svg'
import practiceAIIcon from '../images/Sidebar/sidebar-practice-ai.svg'
import buyTokenIcon from '../images/Sidebar/sidebar-buy-token.svg'
import myTokenIcon from '../images/Sidebar/sidebar-my-token.svg'
import readingTestIcon from '../images/Sidebar/sidebar-reading-test.svg'
import listeningTestIcon from '../images/Sidebar/sidebar-listening-test.svg'
import writingTestIcon from '../images/Sidebar/sidebar-writing-test.svg'
import speakingTestIcon from '../images/Sidebar/sidebar-speaking-test.svg'
import analysisIcon from '../images/Sidebar/sidebar-analysis.svg'
import liveChatIcon from '../images/Sidebar/sidebar-live-chat.svg'
// import signoutIcon from '../images/Navbar/navbar-sign-out.svg'
// import settingIcon from '../images/Navbar/navbar-setting.svg'
// import cartIcon from '../images/Navbar/navbar-cart.svg'

import searchIcon from '../images/Navbar/navbar-search.svg'
// import messageIcon from '../images/Navbar/navbar-message.svg'
import barChartIcon from '../images/Navbar/navbar-bar-chart.svg'
// import notificationIcon from '../images/Navbar/navbar-notification.svg'

const MobileSideBar = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [showSearchInput, setShowSearchInput] = useState(false)
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

  const { logout, userProfile } = useAuth()

  const handleSignOut = async () => {
    try {
      await logout()
    } catch (error: any) {
      console.error(error.response?.data?.message)
    }
  }

  const toggleNestedVisibility = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index)
  }

  const sidebarItems = [
    { icon: homeIcon, label: 'PTE Master', link: '/' },

    {
      icon: libraryBlackIcon,
      openIcon: libraryWhiteIcon,
      label: 'Practice',
      link: '/practice',
      nestedItems: [
        {
          label: 'Practice AI',
          icon: practiceAIIcon,
          link: '/practice-ai',
        },
        {
          label: 'Buy Token',
          icon: buyTokenIcon,
          link: '/buy-token',
        },
        {
          label: 'My Token',
          icon: myTokenIcon,
          link: '/purchase',
        },
      ],
    },
    {
      icon: sectionalTestBlackIcon,
      openIcon: sectionalTestWhiteIcon,
      label: 'Sectional Test',
      link: '/sectional-test',
      nestedItems: [
        {
          label: 'Reading',
          icon: readingTestIcon,
          link: '/sectional-test/start-test-now-4',
        },
        {
          label: 'Listening',
          icon: listeningTestIcon,
          link: '/sectional-test/start-test-now-5',
        },
        {
          label: 'Writing',
          icon: writingTestIcon,
          link: '/sectional-test/start-test-now-3',
        },
        {
          label: 'Speaking',
          icon: speakingTestIcon,
          link: '/sectional-test/start-test-now-2',
        },
        {
          label: 'Test History and Analysis',
          icon: analysisIcon,
          link: '/sectional-test/history',
        },
      ],
    },
    {
      icon: predictionIcon,
      label: 'Weekly Prediction',
      link: '/weekly-prediction',
      notification: '06/11-13/11',
    },
    {
      icon: mockTestBlackIcon,
      openIcon: mockTestWhiteIcon,
      label: 'Mock Test',
      link: '/mock-test',
      nestedItems: [
        {
          label: 'My Unlocked Tests',
          icon: myTokenIcon,
          link: '/my-unlock-test',
        },
        {
          label: 'Test History and Analysis',
          icon: analysisIcon,
          link: '/mock-test/history',
        },
      ],
    },
    {
      icon: communityBlackIcon,
      openIcon: communityWhiteIcon,
      label: 'Community',
      link: '/community',
      nestedItems: [
        // {
        //   label: 'Live Chat',
        //   icon: liveChatIcon,
        //   link: '/live-chat',
        //   notification: '0',
        // },
        {
          label: 'PTE videos',
          icon: liveChatIcon,
          link: '/pte-videos',
        },
      ],
    },
    {
      icon: performanceIcon,
      label: 'My Performance',
      link: '/my-performance',
    },
    { icon: supportIcon, label: 'Support', link: '/support' },
    {
      link: '/cart',
      icon: supportIcon,
      label: 'My Cart',
    },
    {
      link: '/user-profile',
      icon: supportIcon,
      label: 'Settings',
    },
    {
      onClick: handleSignOut,
      icon: supportIcon,
      label: 'Sign Out',
    },
  ]

  const navItems = [
    // {
    //   path: '/live-chat',
    //   icon: messageIcon,
    //   alt: 'Messages',
    //   notifications: 9,
    // },
    {
      path: '/analytics',
      icon: barChartIcon,
      alt: 'Analytics',
      notifications: 0,
    },
    // {
    //   path: '/notifications',
    //   icon: notificationIcon,
    //   alt: 'Notifications',
    //   notifications: 3,
    // },
  ]

  return (
    <div
      className={`lg:hidden fixed top-0 right-0 h-full w-64 bg-white shadow-lg transform ease-in-out duration-300 ${
        isMenuOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="bg-white min-h-screen p-2 shadow-sm">
        <div className="flex justify-between items-center p-4 pb-6 mb-2 border-b-2">
          <p className="text-h4m text-info">Welcome, {userProfile?.name}</p>
          <img
            src={menuIcon}
            alt="Menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="hover:scale-105 cursor-pointer"
          />
        </div>

        <div className="flex gap-2 justify-evenly py-2">
          <img
            src={searchIcon}
            alt="Search"
            className="h-6 w-6 text-gray-600 hover:scale-105 cursor-pointer"
            onClick={() => setShowSearchInput(!showSearchInput)}
          />
          {navItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className="hover:scale-105 relative"
            >
              <img src={item.icon} alt={item.alt} className="h-6" />
              {item.notifications > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-0.5">
                  {item.notifications}
                </span>
              )}
            </Link>
          ))}
        </div>

        {showSearchInput && (
          <div className="px-4 py-2">
            <input
              className="p-2 border-2 border-gray-300 rounded-lg hover:border-gray-400"
              placeholder="Search"
              onBlur={() => setShowSearchInput(false)}
            />
          </div>
        )}

        {sidebarItems.map((item, index) => (
          <div key={index}>
            <Link
              to={item.link ? item.link : '#'}
              onClick={() =>
                item.onClick ? item.onClick() : toggleNestedVisibility(index)
              }
              className={`flex justify-between items-center py-4 px-2 cursor-pointer
                ${expandedIndex === index && item.openIcon && 'bg-primary-6 text-white'}`}
            >
              <div className="flex gap-2">
                <img
                  src={
                    expandedIndex === index && item.openIcon
                      ? item.openIcon
                      : item.icon
                  }
                  alt={item.label}
                />
                <p className="text-bodyr text-neutral-1">{item.label}</p>
              </div>
              {item.nestedItems && (
                <img
                  src={
                    expandedIndex === index
                      ? arrowRightWhiteIcon
                      : arrowRightBlackIcon
                  }
                  alt="Expand"
                  className={`transition-transform ${expandedIndex === index ? 'rotate-90' : 'rotate-0'}`}
                />
              )}
              {item.notification && (
                <div className="bg-danger text-white px-1 rounded-full">
                  <p className="text-footnote">{item.notification}</p>
                </div>
              )}
            </Link>
            {item.nestedItems && expandedIndex === index && (
              <div
                style={{
                  maxHeight: '500px',
                  transition: 'max-height 0.3s ease',
                }}
                className="overflow-hidden"
              >
                {item.nestedItems.map((nestedItem, nestedIndex) => (
                  <Link
                    to={nestedItem.link}
                    key={nestedIndex}
                    className="pl-10 block hover:bg-gray-100 p-2"
                  >
                    <div className="flex justify-between">
                      <div className="flex gap-2">
                        <img src={nestedItem.icon} alt={nestedItem.label} />
                        <p className="text-bodyr text-neutral-1">
                          {nestedItem.label}
                        </p>
                      </div>

                      {/* {nestedItem.notification ? (
                        <div className="bg-danger text-white px-3 rounded-full">
                          <p className="text-footnote">
                            {nestedItem.notification}
                          </p>
                        </div>
                      ) : (
                        <img src={dotIcon} alt="Notification" />
                      )} */}
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default MobileSideBar
