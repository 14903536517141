import HeroSection from '../../components/LandingPage/HeroSection'
import FeatureSection from '../../components/LandingPage/FeatureSection'
import AboutSection from '../../components/LandingPage/AboutSection'
import CTASection from '../../components/LandingPage/CTASection'
import HowItWorksSection from '../../components/LandingPage/HowItWorksSection'
import TestimonialsSection from '../../components/LandingPage/TestimonialsSection'

const LandingPage: React.FC = () => {
  return (
    <div className="w-full">
      <HeroSection />
      <FeatureSection />
      <AboutSection />
      <CTASection />
      <HowItWorksSection />
      <TestimonialsSection />
    </div>
  )
}

export default LandingPage
