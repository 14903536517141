import { useState } from 'react'
import Storage from '../../models/storage.model'
import LastMinuteStudyCard from './LastMinuteStudyCard'
import { ReactComponent as DropDownArrowIcon } from '../../images/LastMinuteStudy/lmt-dropdown-arrow-icon.svg'

type SelectCategoryFunction = (category: number) => void

const LastMinuteStudySection: React.FC<{
  onSelectCategory: SelectCategoryFunction
  categories: { name: string; id: number }[]
  storage: Storage[]
}> = ({ onSelectCategory, categories, storage }) => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0].id)
  const [isOpen, setIsOpen] = useState(false)

  const handleItemClick = (item: { id: number; name: string }) => {
    setSelectedCategory(item.id)
    setIsOpen(false)
    onSelectCategory(item.id)
  }

  return (
    <div className="last-minute-study-section">
      <p className="text-h1 font-landingPrimary mb-[0.4rem]">
        Last Minute Study
      </p>
      <hr className="lg:w-[28.188rem] w-full" />
      <div className="lg:hidden lg:mt-[2rem] mt-[1rem] md:w-full w-full relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full h-[2rem] py-[1.2rem] px-[1rem] bg-white border border-1 rounded-lg text-h5r font-landingPrimary flex justify-between items-center"
        >
          {categories.find((category) => category.id === selectedCategory)
            ?.name ?? 'Select Category'}
          <DropDownArrowIcon fill="#00000040" />
        </button>
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white rounded-md shadow-lg">
            {categories.map((category) => (
              <li
                key={category.id}
                onClick={() => handleItemClick(category)}
                className="cursor-pointer px-[12px] py-[5px] text-h5r font-landingPrimary hover:bg-info hover:text-white"
              >
                {category.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <nav className="hidden lg:flex justify-between items-center bg-[#FAFAFA] mt-[1rem] lg:w-[28.188rem] w-full lg:h-[2.875rem] h-[4rem] lg:p-0 p-[0.4rem] rounded-lg text-center">
        <ul className="flex space-x-4 text-h5r font-landingPrimary text-neutrals-2 w-full h-full">
          {categories.map((category) => (
            <li
              key={category.id}
              onClick={() => handleItemClick(category)}
              className={`cursor-pointer flex items-center justify-center flex-grow rounded-lg ${selectedCategory === category.id ? 'text-h5r text-neutrals-4 bg-info' : ''}`}
            >
              {category.name}
            </li>
          ))}
        </ul>
      </nav>
      <div className="last-minute-study-section__list mt-[2rem] flex flex-wrap gap-6">
        {storage.map((item: Storage) => (
          <LastMinuteStudyCard key={item.storageId} storage={item} />
        ))}
      </div>
    </div>
  )
}

export default LastMinuteStudySection
