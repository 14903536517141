import { useEffect, useState } from 'react'
import WeeklyPredictionWhiteArrowSvg from '../images/WeeklyPrediction/weekly-prediction-white-arrow.svg'
import WeeklyPredictionGreyArrowSvg from '../images/WeeklyPrediction/weekly-prediction-grey-arrow.svg'
import { Category, QuestionModel, SkillName } from '../models/QuestionModels'

type QuestionModalAccordionProps = {
  setQuestionType: (value: QuestionModel) => void
}

const categories: Category[] = [
  {
    name: SkillName.Speaking,
    questionModels: [
      QuestionModel.ReadAloud,
      QuestionModel.RepeatSentences,
      QuestionModel.DescribeImages,
      QuestionModel.RetellLectures,
      QuestionModel.AnswerShortQuestions,
    ],
  },
  {
    name: SkillName.Writing,
    questionModels: [
      QuestionModel.SummarizeWrittenText,
      QuestionModel.WriteEssay,
    ],
  },
  {
    name: SkillName.Reading,
    questionModels: [
      QuestionModel.ReadingMultipleChoiceChooseSingleAnswer,
      QuestionModel.ReadingMultipleChoiceChooseMultipleAnswers,
      QuestionModel.ReorderParagraphs,
      QuestionModel.ReadingFillInTheBlanks,
      QuestionModel.ReadingAndWritingFillInTheBlanks,
    ],
  },
  {
    name: SkillName.Listening,
    questionModels: [
      QuestionModel.SummarizeSpokenText,
      QuestionModel.MultipleChoiceChooseMultipleAnswers,
      QuestionModel.FillInTheBlanks,
      QuestionModel.HighlightCorrectSummary,
      QuestionModel.MultipleChoiceChooseSingleAnswer,
      QuestionModel.SelectMissingWord,
      QuestionModel.HighlightIncorrectWords,
      QuestionModel.WriteFromDictation,
    ],
  },
]

const QuestionModalAccordion: React.FC<QuestionModalAccordionProps> = ({
  setQuestionType,
}) => {
  const [activeCategory, setActiveCategory] = useState('')
  const [activeQuestionModel, setActiveQuestionModel] = useState('')

  const handleTabClick = (categoryName: SkillName) => {
    if (activeCategory === categoryName) {
      setActiveCategory('')
      return
    }
    setActiveCategory(categoryName)
  }

  const handleModelClick = (questionModel: string) => {
    setActiveQuestionModel(questionModel)
    setQuestionType(questionModel as QuestionModel)
    localStorage.setItem('questionTypePTE', questionModel)
  }

  useEffect(() => {
    const questionTypePTE = localStorage.getItem('questionTypePTE') || ''
    if (questionTypePTE) {
      categories.forEach((category) => {
        if (
          category.questionModels.includes(questionTypePTE as QuestionModel)
        ) {
          setActiveCategory(category.name)
          setActiveQuestionModel(questionTypePTE)
        }
      })
    }
  }, [])

  return (
    <div>
      {categories.map((category, index) => (
        <div
          key={index}
          className="cursor-pointer border border-[1px]-[#0000000F] my-2 rounded-lg"
        >
          <div
            className={`flex justify-between items-center p-4 rounded-t-lg
              ${activeCategory === category.name ? 'bg-primary-6 text-white' : 'text-neutrals-2'}
              `}
            onClick={() => handleTabClick(category.name)}
          >
            <p className={`text-bodyr`}>{category.name}</p>
            <img
              src={
                activeCategory === category.name
                  ? WeeklyPredictionWhiteArrowSvg
                  : WeeklyPredictionGreyArrowSvg
              }
              className={`transition-transform duration-300 ${activeCategory === category.name ? 'rotate-90' : 'rotate-0'}`}
              alt="Arrow"
            />
          </div>
          <div
            style={{
              maxHeight:
                activeCategory === category.name
                  ? `${category.questionModels.length * 4}rem`
                  : '0',
              overflow: 'hidden',
              transition: 'max-height 0.5s ease',
            }}
          >
            {category.questionModels.map((questionModel, idx) => (
              <p
                key={idx}
                className={`text-bodyr px-4 py-2 border-b-[1px]
                  ${activeQuestionModel === questionModel ? 'bg-info text-white' : 'text-neutrals-1'}
                  `}
                onClick={() => handleModelClick(questionModel)}
              >
                {questionModel}
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default QuestionModalAccordion
