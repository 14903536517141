import { useState, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ReactComponent as CameraIcon } from '../../images/UserProfile/up-camera-icon.svg'
import User from '../../models/user.model'
import { updateUserProfile } from '../../services/user.services'
import { useAuth } from '../../providers/AuthProvider'
import SuccessMessageToast from '../Items/SuccessMessageToast'
import ErrorMessageToast from '../Items/ErrorMessageToast'

interface UpdateProfileProps {
  user: User | undefined
}

const UpdateProfileSection: React.FC<UpdateProfileProps> = ({ user }) => {
  const [profileImage, setProfileImage] = useState<string | null>(null)
  const [imageName, setImageName] = useState<string>('Upload here')
  const [imageFile, setImageFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const { setUserProfile } = useAuth()
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      username: user?.name || '',
      phone: user?.mobile || '',
    },
  })

  useEffect(() => {
    if (user) {
      reset({
        username: user.name,
        phone: user?.mobile,
      })
    }
  }, [user, reset])

  const handleSuccessResponse = (message: string) => {
    setSuccessMsg(message)
    setTimeout(() => {
      setSuccessMsg('')
    }, 2000)
  }
  
  const handleErrorResponse = (message: string) => {
    setErrorMsg(message)
    setTimeout(() => {
      setErrorMsg('')
    }, 2000)
  }

  const onSubmit = async (data: any) => {
    if(user && user.id) {
      try {
        const response = await updateUserProfile(user.id, data.username, data.phone, imageFile || undefined)
        if (response.data.success) {
          handleSuccessResponse(response.data.message)
          if(response.data.userInfo)
          setUserProfile({
            userId: response.data.userInfo.id,
            email: response.data.userInfo.email,
            name: response.data.userInfo.name,
            phone: response.data.userInfo.mobile,
            image: response.data.userInfo.image
          })
        } else {
          handleErrorResponse(response.data.message)
        }
      } catch (error) {
        if (error instanceof Error) {
          handleErrorResponse(error.message)
        } else {
          handleErrorResponse('An unknown error occurred!')
        }
      }
    } else {
      console.log(user)
      handleErrorResponse('Something wrong!')
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setImageName(`${file.name}`)
      setImageFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setProfileImage(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className="change-profile-password__profile-section mx-auto lg:w-[23.438rem] w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="profile-section__title text-h5m">Change profile</p>
        <div className="profile-section__profile-picture mt-[1rem]">
          <label
            htmlFor="fileInput"
            className="profile-picture__title text-bodym"
          >
            Profile picture
          </label>
          <button
            type="button"
            className="profile-picture__upload mt-[1rem] flex items-center gap-4 h-[4.625rem] w-full bg-[#FAFAFA] px-3 rounded-lg border-dashed border-2 border-[#0000000F]"
            onClick={() => fileInputRef.current?.click()}
            style={{ cursor: 'pointer' }}
          >
            <input
              ref={fileInputRef}
              id="fileInput"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <div className="profile-picture__upload__default-image w-[3.125rem] h-[3.125rem] bg-[#D9D9D9] rounded-full flex justify-center items-center overflow-hidden">
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="w-full h-full object-cover rounded-full"
                />
              ) : (
                <CameraIcon />
              )}
            </div>
            <p className="text-bodyr text-neutrals-2">{imageName}</p>
          </button>
        </div>
        <div className="profile-section__username mt-[1rem]">
          <label htmlFor="username" className="username__title text-bodym">
            Username
          </label>
          <input
            type="text"
            id="username"
            placeholder="Type your username here"
            className="username__input mt-[0.4rem] w-full h-[2.375rem] text-bodyr shadow-user-profile-input rounded-lg p-[7px] border border-1 border-[#0000000F]"
            {...register('username', {
              required: 'Your username cannot be empty!',
            })}
          />
          {errors.username && (
            <div className="text-red-500 text-footnote mt-1">
              {errors.username.message}
            </div>
          )}
        </div>
        <div className="profile-section__email mt-[1rem]">
          <label htmlFor="email" className="email__title text-bodym">
            Email
          </label>
          <input
            type="text"
            id="email"
            placeholder="Type your email here"
            defaultValue={user?.email}
            className="email__input mt-[0.4rem] w-full h-[2.375rem] text-bodyr shadow-user-profile-input rounded-lg p-[7px] border border-1 border-[#0000000F] text-[#00000040]"
            disabled
          />
        </div>
        <div className="profile-section__phone mt-[1rem]">
          <label htmlFor="phone" className="phone__title text-bodym">
            Phone number
          </label>
          <input
            type="text"
            id="phone"
            placeholder="+84 123 456 789"
            className="phone__input mt-[0.4rem] w-full h-[2.375rem] text-bodyr shadow-user-profile-input rounded-lg p-[7px] border border-1 border-[#0000000F]"
            {...register('phone', {
              required: 'Please enter your phone number!',
              pattern: {
                value: /^(?:\+?61|0)[2-57-8]\d{8}$/,
                message: 'Invalid phone number!',
              },
            })}
          />
          {errors.phone && (
            <div className="text-red-500 text-footnote mt-1">
              {errors.phone.message}
            </div>
          )}
        </div>
        <div className="profile-section__submit mt-[1rem] flex justify-end w-full">
          <button
            type="submit"
            className="w-[4rem] h-[2.375rem] bg-info hover:bg-[#1890FF] rounded-lg flex justify-center items-center text-white text-bodyr"
          >
            Update
          </button>
        </div>
      </form>
      { successMsg !== '' &&  <SuccessMessageToast message={successMsg} /> }
      { errorMsg !== '' &&  <ErrorMessageToast message={errorMsg} /> }
    </div>
  )
}

export default UpdateProfileSection
