import axios from 'axios'
import { isTokenValid } from '../utils/utils'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})

//set Authorization when logged in
axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('accessToken')
  if (!isTokenValid(token)) {
    config.headers.Authorization = ''
  } else {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

// intercept response and check if token is expired
axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      // clean up local storage
      localStorage.removeItem('accessToken')
      // redirect to login page
      window.location.href = '/login'
    }

    return Promise.reject(error)
  },
)

export default axiosInstance
