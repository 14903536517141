import { ReactComponent as ComparisonIcon } from '../../images/MyPerformance/mp-comparison-icon.svg'
import MyPerformance from '../../models/myPerformance.model'

interface ComparisonProps {
  data: MyPerformance[]
  yourTargetScore: number
}

const ComparisonSection: React.FC<ComparisonProps> = ({
  data,
  yourTargetScore,
}) => {
  let totalAttemptFilter = 0
  let target50Count = 0,
    target65Count = 0,
    target79Count = 0,
    targetYourScoreCount = 0
  for (const test of data) {
    if (parseFloat(test.overalltotal) >= 15) {
      totalAttemptFilter++
    }

    if (parseFloat(test.overalltotal) >= 50) {
      target50Count++
    }
    if (parseFloat(test.overalltotal) >= 65) {
      target65Count++
    }
    if (parseFloat(test.overalltotal) >= 79) {
      target79Count++
    }
    if (parseFloat(test.overalltotal) >= yourTargetScore) {
      targetYourScoreCount++
    }
  }

  const targetScore = [
    {
      title: 'Target 50',
      score: 50,
      countTotal: target50Count,
      passRate: Math.round((target50Count * 100) / totalAttemptFilter),
    },
    {
      title: 'Target 65',
      score: 65,
      countTotal: target65Count,
      passRate: Math.round((target65Count * 100) / totalAttemptFilter),
    },
    {
      title: 'Target 79',
      score: 79,
      countTotal: target79Count,
      passRate: Math.round((target79Count * 100) / totalAttemptFilter),
    },
    {
      title: `My Target ${yourTargetScore}`,
      score: yourTargetScore,
      countTotal: targetYourScoreCount,
      passRate: Math.round((targetYourScoreCount * 100) / totalAttemptFilter),
    },
  ]

  const ComparisonPCScreen = () => {
    return (
      <>
        <div className="hidden lg:block comparison-section__details--pc w-full mt-[1rem]">
          <div className="comparison-section__details__title--lg w-full h-18 mt-4 grid grid-cols-4 gap-2">
            {targetScore.map((target, index) => (
              <div
                key={index}
                className="details__title__item--lg text-h5r text-neutrals-2 text-center flex items-center"
              >
                {target.title}
              </div>
            ))}
          </div>
          <div className="comparison-section__details__value--lg w-full lg:min-h-[7.5rem] h-full mt-[1rem] bg-gray-100 border border-gray-200 rounded-xl grid grid-cols-4 gap-4 px-2">
            {targetScore.map((target, index) => (
              <div
                key={index}
                className="details__value__item--lg  flex items-center"
              >
                {yourTargetScore <= 0 && target.score === yourTargetScore ? (
                  <p className="text-bodyr text-neutrals-1">
                    Please update your target score.
                  </p>
                ) : (
                  <p className="text-bodyr text-neutrals-1">
                    You have attempted a total of{' '}
                    <span className="text-bodym text-info">
                      {totalAttemptFilter} mock test(s)
                    </span>{' '}
                    and achieved{' '}
                    <span className="text-bodym text-info">
                      target {target.score} for {target.countTotal} time(s).
                    </span>{' '}
                    Therefore, you have a pass rate of {target.passRate}% for
                    this target score.
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }

  const ComparisonMobileScreen = () => {
    return (
      <>
        <div className="block lg:hidden comparison-section__details--mb--first w-full mt-[1rem]">
          <div className="comparison-section__details__title--mb--first w-full h-18 mt-4 grid grid-cols-2 gap-4">
            {targetScore.slice(0, 2).map((target, index) => (
              <div
                key={index}
                className="details__title__item--mb--first text-h5r text-neutrals-2 text-center flex items-center px-[0.2rem]"
              >
                {target.title}
              </div>
            ))}
          </div>
          <div className="comparison-section__details__value--mb--first w-full h-full mt-[1rem] bg-gray-100 border border-gray-200 rounded-xl grid grid-cols-2 gap-8 px-2 py-4">
            {targetScore.slice(0, 2).map((target, index) => (
              <div
                key={index}
                className="details__value__item--mb--first  flex items-center"
              >
                <p className="text-bodyr text-neutrals-1">
                  You have attempted a total of{' '}
                  <span className="text-bodym text-info">
                    {totalAttemptFilter} mock test(s)
                  </span>{' '}
                  and achieved{' '}
                  <span className="text-bodym text-info">
                    target {target.score} for {target.countTotal} time(s).
                  </span>{' '}
                  Therefore, you have a pass rate of {target.passRate}% for this
                  target score.
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="block lg:hidden comparison-section__details--mb--second w-full mt-[1rem]">
          <div className="comparison-section__details__title--mb--second w-full h-18 mt-4 grid grid-cols-2 gap-4">
            {targetScore.slice(-2).map((target, index) => (
              <div
                key={index}
                className="details__title__item--mb--second text-h5r text-neutrals-2 text-center flex items-center px-[0.2rem]"
              >
                {target.title}
              </div>
            ))}
          </div>
          <div className="comparison-section__details__value--mb--second w-full h-full mt-[1rem] bg-gray-100 border border-gray-200 rounded-xl grid grid-cols-2 gap-8 px-2 py-4">
            {targetScore.slice(-2).map((target, index) => (
              <div
                key={index}
                className="details__value__item--mb--second  flex items-center"
              >
                {yourTargetScore === 0 && target.score === yourTargetScore ? (
                  <p className="text-bodyr text-neutrals-1">
                    Please update your target score.
                  </p>
                ) : (
                  <p className="text-bodyr text-neutrals-1">
                    You have attempted a total of{' '}
                    <span className="text-bodym text-info">
                      {totalAttemptFilter} mock test(s)
                    </span>{' '}
                    and achieved{' '}
                    <span className="text-bodym text-info">
                      target {target.score} for {target.countTotal} time(s).
                    </span>{' '}
                    Therefore, you have a pass rate of {target.passRate}% for
                    this target score.
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="my-performance__comparison-section w-full font-landingPrimary lg:mt-[2rem] mt-[1rem]">
      <div className="comparison-section__title flex gap-2 items-center lg:mb-[2rem] mb-[1rem]">
        <ComparisonIcon />
        <p className="text-h4m">Comparison</p>
      </div>
      {/* PC screen */}
      <ComparisonPCScreen />
      {/* Mobile screen */}
      <ComparisonMobileScreen />
    </div>
  )
}

export default ComparisonSection
