import SupportCard from './SupportCard'

const SupportSection = () => {
  const items = [
    {
      question: 'Which browser is most suitable for this website?',
      answer:
        'Only works best on google chrome, download the latest version at https://www.google.com/chrome/',
    },
    {
      question: 'Do i need a strong Internet connection?',
      answer:
        'Our website uses the latest cutting-edge technology, means that with average internet speed you can still practice without any delay in scoring.',
    },
    {
      question: 'What does easy, normal and hard in mock tests mean?',
      answer:
        'Easy is easier than the real exam, it is good for first time test takers who just wants to experience the exam. Normal is exactly the same difficulty level in the exam. Hard is harder than the real exam, question wise.',
    },
    {
      question: 'Are all questions in Mock Tests real exam materials?',
      answer: 'Yes',
    },
    {
      question:
        "How accurate is the scoring algorithms compared to Pearson's original test?",
      answer:
        "It would be a lie to tell you its 100% similar, but we are confident at 99% similar to Pearson's requirements, this system is the closest alternative you can get, it is a lot faster in result delivery and allow you to check your mistakes easily by going through our analysis.",
    },
  ]

  return (
    <div className="support-section font-landingPrimary">
      <p className="support-section__title text-h1 font-landingPrimary mb-[0.4rem]">
        Frequently Asked Questions
      </p>
      <div className="support-section__questions mt-[2rem] w-full flex flex-wrap gap-x-4 gap-y-6">
        {items.map((item, index) => (
          <SupportCard
            key={index}
            question={item.question}
            answer={item.answer}
          />
        ))}
        <div className="support-section__questions__card bg-neutrals-4 rounded-lg py-[1.5rem] px-[1rem] shadow-support-card lg:w-[22rem] w-full lg:h-[16.125rem] h-full">
          <p className="questions__card__question text-h5m">
            I clicked submitted but the scoring function does not work?
          </p>
          <div className="questions__card__answer mt-[0.6rem] ml-[1rem]">
            <ol className="list-decimal">
              <li className="text-bodyr">
                Make sure you only use Google Chrome
              </li>
              <li className="text-bodyr">
                Make sure you allow Microphone permission
              </li>
              <li className="text-bodyr">
                Make sure you use a good pair of headphone
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportSection
