import { useState, useEffect } from 'react'
import logo from '../images/logo.svg'
import searchIcon from '../images/Navbar/navbar-search.svg'
import shoppingIcon from '../images/Navbar/navbar-shopping-cart.svg'
// import messageIcon from '../images/Navbar/navbar-message.svg'
import barChartIcon from '../images/Navbar/navbar-bar-chart.svg'
// import notificationIcon from '../images/Navbar/navbar-notification.svg'
import arrowDownIcon from '../images/Navbar/navbar-arrow-down.svg'
import menuIcon from '../images/Navbar/navbar-menu.svg'
import signoutIcon from '../images/Navbar/navbar-sign-out.svg'
import settingIcon from '../images/Navbar/navbar-setting.svg'
import cartIcon from '../images/Navbar/navbar-cart.svg'
import { Link, useLocation } from 'react-router-dom'
import MobileSideBar from './MobileSideBar'
import { useNavigate } from 'react-router-dom'
import Path from '../routes/Path'
import { useAuth } from '../providers/AuthProvider'

const NavBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const { logout, userProfile } = useAuth()

  const navItems = [
    // {
    //   path: '/live-chat',
    //   icon: messageIcon,
    //   alt: 'Messages',
    //   notifications: 0,
    // },
    {
      path: '/analytics',
      icon: barChartIcon,
      alt: 'Analytics',
      notifications: 0,
    },
    // {
    //   path: '/notifications',
    //   icon: notificationIcon,
    //   alt: 'Notifications',
    //   notifications: 0,
    // },
  ]

  const handleSignOut = async () => {
    try {
      await logout()
    } catch (error: any) {
      console.error(error.response?.data?.message)
    }
  }

  const handleNavigate = (path: string) => {
    setIsDropdownOpen(false) // Close the dropdown
    navigate(path)
  }

  const dropdownItems = [
    {
      path: Path.cart.path,
      icon: cartIcon,
      alt: 'Cart icon',
      label: 'My Cart',
    },
    {
      path: Path.userProfile.path,
      icon: settingIcon,
      alt: 'Setting icon',
      label: 'Settings',
    },
    {
      onClick: handleSignOut,
      icon: signoutIcon,
      alt: 'Signout icon',
      label: 'Sign Out',
    },
  ]

  useEffect(() => {
    setIsDropdownOpen(false) // Close the dropdown
  }, [location])

  return (
    <div className="sticky top-0 left-0 w-full bg-white z-40 px-4 py-4">
      <div className="flex justify-between items-center">
        <Link to={Path.home.path}>
          <img src={logo} alt="Logo" />
        </Link>

        <div className="hidden lg:flex items-center w-1/3 py-1 pl-4 border-2 border-gray-300 rounded-lg hover:border-gray-400">
          <img
            src={searchIcon}
            alt="Search"
            className="h-6 w-6 text-gray-600 mr-2 hover:scale-105"
          />
          <input
            className="flex-grow h-9 px-2 border-none outline-none"
            placeholder="Search"
          />
        </div>

        <div className="hidden lg:flex items-center gap-4">
          <Link
            to="/cart"
            className="relative pr-4 py-2 border-r-2 border-[#0000000F]"
          >
            <img
              src={shoppingIcon}
              alt="Shopping"
              className="h-6 hover:scale-105"
            />
          </Link>
          <div className="flex gap-4 pr-4 py-2 border-r-1 border-gray-100 items-center">
            {navItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className="hover:scale-105 relative"
              >
                <img src={item.icon} alt={item.alt} className="h-6" />
                {item.notifications > 0 && (
                  <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-0.5">
                    {item.notifications}
                  </span>
                )}
              </Link>
            ))}
          </div>

          <div className="flex gap-4 items-center relative">
            <Link to="/user-profile" className="hover:scale-105">
              <img
                src={userProfile?.image}
                className="w-[40px] h-[40px] rounded-full"
                alt="Profile"
              />
            </Link>
            <div>
              <p className="text-bodym">{userProfile?.name}</p>
              <p className="text-footnote">{userProfile?.email}</p>
            </div>
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="focus:outline-none"
            >
              <img
                src={arrowDownIcon}
                alt="Arrow down"
                className="pl-2 hover:bg-gray-100"
              />
            </button>
            {isDropdownOpen && (
              <div className="absolute right-1 top-12 w-[15rem] bg-white border border-gray-300 rounded-md shadow-lg">
                {dropdownItems.map((item, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      item.onClick ? item.onClick() : handleNavigate(item.path)
                    }
                    className="block w-full text-bodyr px-4 py-2 hover:bg-[#F0F7FF] flex items-center gap-2"
                  >
                    <img src={item.icon} alt={item.alt} />
                    {item.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="lg:hidden flex items-center gap-4">
          <Link to="/user-profile" className="hover:scale-105">
            <img
              src={userProfile?.image}
              className="w-[40px] h-[40px] rounded-full"
              alt="Profile"
            />
          </Link>
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <img
              src={menuIcon}
              alt="Menu"
              className="h-6 w-6 hover:scale-105"
            />
          </button>
        </div>

        {/* Mobile sidebar menu */}
        <MobileSideBar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      </div>
    </div>
  )
}

export default NavBar
