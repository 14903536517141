import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as FooterLocationIcon } from '../../images/Landingpage/footer-location-icon.svg'
import { ReactComponent as FooterPhoneIcon } from '../../images/Landingpage/footer-phone-icon.svg'
import PteMasterLogo2 from '../../images/Landingpage/pte-master-logo-2.png'

const Footer: React.FC = () => {
  const academicList = [
    { destination: '/', title: 'PTE Academic Speaking Test' },
    { destination: '/', title: 'PTE Academic Reading Test' },
    { destination: '/', title: 'PTE Academic Writing Test' },
    { destination: '/', title: 'PTE Academic Listening Test' },
  ]

  const featureList = [
    { destination: '/', title: 'Blog' },
    { destination: '/', title: 'Pricing' },
    { destination: '/', title: 'About Us' },
    { destination: '/', title: 'Contact Us' },
    { destination: '/', title: 'Testimoni' },
  ]

  return (
    <footer className="w-full lg:h-[19.125rem] md:h-fit bg-primary-6 lg:px-[5rem] px-[4rem] lg:py-0 py-[1rem] flex flex-wrap justify-between items-center gap-y-4">
      <div className="lg:w-[17.25rem] w-full">
        <img src={PteMasterLogo2} alt="PTE Master Logo" />
        <p className="text-bodyr text-primary-2 font-landingPrimary">
          PTE Master provides PTE training course to all students who wish to
          achieve a high score. We started our journey in 2016 and have helped
          thousands of students to achieve their desire score in short amount of
          time.
        </p>
      </div>
      <div className="lg:w-[20rem] w-full flex justify-between">
        <div className="lg:w-[11.375rem]">
          <p className="font-sans font-semibold text-base text-neutrals-4 mb-[0.6rem]">
            Company
          </p>
          {academicList.map((academic, index) => (
            <p
              key={index}
              className="font-landingPriamry text-bodyr text-primary-2 py-[0.5rem]"
            >
              <Link to={academic.destination}>{academic.title}</Link>
            </p>
          ))}
        </div>
        <div className="lg:w-[4.625rem]">
          <p className="font-sans font-semibold text-base text-neutrals-4 mb-[0.6rem]">
            Company
          </p>
          {featureList.map((feature, index) => (
            <p
              key={index}
              className="font-landingPriamry text-bodyr text-primary-2 py-[0.5rem]"
            >
              <Link to={feature.destination}>{feature.title}</Link>
            </p>
          ))}
        </div>
      </div>
      <div className="lg:w-[17.5rem] w-full text-neutrals-4 lg:mb-[6rem]">
        <p className="text-h4m font-sans">Get in touch</p>
        <div className="flex items-center gap-x-3 py-[1rem]">
          <FooterLocationIcon fill="white" width={40} />
          <p className="text-bodyr text-neutrals-4 font-landingPrimary">
            Level 36 Gateway, 1 Macquarie Place Sydney NSW 2000 Sydney,
            Australia
          </p>
        </div>
        <div className="flex gap-x-2">
          <FooterPhoneIcon fill="white" />
          <p className="text-bodyr text-neutrals-4 font-landingPrimary">
            Contact Number :- 0411 703 756
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
