import { displaySkillProgressColor } from '../../utils/utils'
import ProgressTracker from '../../models/progressTracker.model';

interface PracticeSkillProgressOverallSectionProps {
  setSelectedSkill: React.Dispatch<React.SetStateAction<number>>;
  progressTrackerInfo: ProgressTracker
}

const PracticeSkillProgressOverallSection: React.FC<PracticeSkillProgressOverallSectionProps> = ({ setSelectedSkill, progressTrackerInfo }) => {
  const radius = 64
  const circumference = 2 * Math.PI * radius
  const strokeValCalculation = (score: number) => {
    return (score / 90) * circumference
  }

  const overallScores = [
    {
      id: 1,
      skill: 'Speaking',
      score: Number(progressTrackerInfo.sprgrs), // "sprgrs": "32",
    },
    {
      id: 2,
      skill: 'Writing',
      score: Number(progressTrackerInfo.wprgrs), // "wprgrs": "90.00",
    },
    {
      id: 3,
      skill: 'Reading',
      score: Number(progressTrackerInfo.rprgrs), // "rprgrs": 0,
    },
    {
      id: 4,
      skill: 'Listening',
      score: Number(progressTrackerInfo.lprgrs), //  "lprgrs": "90.00",
    },
  ]

  return (
    <div className="practice-performance__skill-progress-overall w-full bg-[#101116] lg:p-0 py-4 lg:h-[14.651rem] h-full rounded-[1.75rem] my-[2rem] grid lg:grid-cols-4 grid-cols-2 gap-4">
      {overallScores.map((score, index) => (
        <div
          key={index}
          className="relative flex flex-col gap-2 justify-center items-center"
        >
          <p className="text-h5m mb-2 text-white">{score.skill}</p>
          <button className="relative flex justify-center items-center" onClick={() => setSelectedSkill(score.id)}>
            <svg
              width="141"
              height="141"
              viewBox="0 0 141 141"
              className="transform -rotate-90"
            >
              <circle
                cx="70.5"
                cy="70.5"
                r={radius}
                fill="none"
                stroke="#252525"
                strokeWidth="13"
              />
              <circle
                cx="70.5"
                cy="70.5"
                r={radius}
                fill="none"
                stroke={displaySkillProgressColor(score.score)}
                strokeWidth="13"
                strokeDasharray={circumference}
                strokeDashoffset={
                  circumference - strokeValCalculation(score.score)
                }
                strokeLinecap="round"
              />
            </svg>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <span className="text-h1 text-white font-semibold">
                {score.score}
              </span>
            </div>
          </button>
        </div>
      ))}
    </div>
  )
}

export default PracticeSkillProgressOverallSection
