interface MenuItem {
  title: string
  path: string
}

const Path = {
  landing: {
    main: { title: 'Landing page', path: '/' } as MenuItem,
    register: { title: 'Register page', path: '/register' } as MenuItem,
    login: { title: 'Login page', path: '/login' } as MenuItem,
    activateAccount: { title: 'Activate Account Page', path: '/activate-account'}
  },
  home: { title: 'Home Page', path: '/home' } as MenuItem,
  weeklyPrediction: {
    title: 'Weekly Prediction',
    path: '/weekly-prediction',
  } as MenuItem,
  weeklyPredictionCourse: {
    title: 'Weekly Prediction Course',
    path: '/weekly-prediction/course',
  } as MenuItem,
  readAloud: {
    title: 'Read Aloud',
    path: '/weekly-prediction/speaking/read-aloud',
  } as MenuItem,
  retellLecture: {
    title: 'Retell Lecture',
    path: '/weekly-prediction/speaking/retell-lecture',
  } as MenuItem,
  repeatSentence: {
    title: 'Repeat Sentence',
    path: '/weekly-prediction/speaking/repeat-sentence',
  } as MenuItem,
  describeImage: {
    title: 'Describe Image',
    path: '/weekly-prediction/speaking/describe-image',
  } as MenuItem,
  answerShortQuestion: {
    title: 'Answer Short Question',
    path: '/weekly-prediction/speaking/answer-short-question',
  } as MenuItem,
  multipleChoiceSingleAnswer: {
    title: 'Multiple Choice Single Answer',
    path: '/weekly-prediction/reading/multiple-choice-single-answer',
  } as MenuItem,
  multipleChoiceMultipleAnswer: {
    title: 'Multiple Choice Multiple Answer',
    path: '/weekly-prediction/reading/multiple-choice-multiple-answer',
  } as MenuItem,
  reorderParagraph: {
    title: 'Reorder Paragraph',
    path: '/weekly-prediction/reading/reorder-paragraph',
  } as MenuItem,
  fillInTheBlank: {
    title: 'Fill In The Blank',
    path: '/weekly-prediction/reading/fill-in-the-blanks',
  } as MenuItem,
  readingFillInTheBlank: {
    title: 'Reading Fill In The Blank',
    path: '/weekly-prediction/reading/reading-fill-in-the-blanks',
  } as MenuItem,
  summarizeWrittenText: {
    title: 'Summarize Written Text',
    path: '/weekly-prediction/writing/summarize-written-text',
  } as MenuItem,
  writeEssay: {
    title: 'Write Essay',
    path: '/weekly-prediction/writing/write-essay',
  } as MenuItem,
  summarizeSpokenText: {
    title: 'Summarize Spoken Text Listening',
    path: '/weekly-prediction/listening/summarize-spoken-text',
  } as MenuItem,
  fillInTheBlanks: {
    title: 'Fill In The Blanks Listening',
    path: '/weekly-prediction/listening/fill-in-the-blanks',
  } as MenuItem,
  highlightCorrectSummary: {
    title: 'Highlight Correct Summary Listening',
    path: '/weekly-prediction/listening/highlight-correct-summary',
  } as MenuItem,
  highlightIncorrectWords: {
    title: 'Highlight Incorrect Words Listening',
    path: '/weekly-prediction/listening/highlight-incorrect-words',
  } as MenuItem,
  multipleChoiceChooseMultipleAnswers: {
    title: 'Multiple Choice Choose Multiple Answers Listening',
    path: '/weekly-prediction/listening/multiple-choice-choose-multiple-answers',
  } as MenuItem,
  multipleChoiceChooseSingleAnswer: {
    title: 'Multiple Choice Choose Single Answer Listening',
    path: '/weekly-prediction/listening/multiple-choice-choose-single-answer',
  } as MenuItem,
  selectMissingWord: {
    title: 'Select Missing Word Listening',
    path: '/weekly-prediction/listening/select-missing-word',
  } as MenuItem,
  writeFromDictation: {
    title: 'Write From Dictation Listening',
    path: '/weekly-prediction/listening/write-from-dictation',
  } as MenuItem,
  shop: { title: 'Shop Page', path: '/shop' } as MenuItem,
  myUnlockedTests: {
    title: 'My Unlocked Tests Page',
    path: '/my-unlocked-test',
  } as MenuItem,
  lastMinuteStudy: {
    title: 'Last Minute Study Page',
    path: '/last-minute-study',
  } as MenuItem,
  community: {
    pteVideos: { title: 'PTE Videos Page', path: '/pte-videos' } as MenuItem,
  },
  sectionalTest: {
    readAloud: {
      title: 'Read Aloud Sectional Test Page',
      path: '/sectional-test/speaking/read-aloud',
    },
    repeatSentence: {
      title: 'Repeat Sentence Sectional Test Page',
      path: '/sectional-test/speaking/repeat-sentence',
    },
    describeImage: {
      title: 'Describe Image Sectional Test Page',
      path: '/sectional-test/speaking/describe-image',
    },
    retellLecture: {
      title: 'Retell Lecture Sectional Test Page',
      path: '/sectional-test/speaking/retell-lecture',
    },
    answerShortQuestion: {
      title: 'Answer Short Question Sectional Test Page',
      path: '/sectional-test/speaking/answer-short-question',
    },
    readingMultipleChoiceSingleAnswer: {
      title: 'Reading Multiple Choice Single Answer Sectional Test Page',
      path: '/sectional-test/reading/multiple-choice-single-answer',
    },
    readingMultipleChoiceMultipleAnswer: {
      title: 'Reading Multiple Choice Multiple Answer Sectional Test Page',
      path: '/sectional-test/reading/multiple-choice-multiple-answer',
    },
    reorderParagraph: {
      title: 'Reorder Paragraph Sectional Test Page',
      path: '/sectional-test/reading/reorder-paragraph',
    },
    readingAndWritingFillInTheBlanks: {
      title: 'Fill In The Blanks Sectional Test Page',
      path: '/sectional-test/reading/fill-in-the-blanks',
    },
    readingFillInTheBlanks: {
      title: 'Reading Fill In The Blanks Sectional Test Page',
      path: '/sectional-test/reading/reading-fill-in-the-blanks',
    },
    summarizeWrittenText: {
      title: 'Summarize Written Text Sectional Test Page',
      path: '/sectional-test/writing/summarize-written-text',
    },
    writeEssay: {
      title: 'Write Essay Sectional Test Page',
      path: '/sectional-test/writing/write-essay',
    },
    summarizeSpokenText: {
      title: 'Summarize Spoken Text Sectional Test Page',
      path: '/sectional-test/listening/summarize-spoken-text',
    },
    multipleChoiceChooseMultipleAnswers: {
      title: 'Multiple Choice Choose Multiple Answers Sectional Test Page',
      path: '/sectional-test/listening/multiple-choice-choose-multiple-answers',
    },
    fillInTheBlanks: {
      title: 'Fill In The Blanks Sectional Test Page',
      path: '/sectional-test/listening/fill-in-the-blanks',
    },
    highlightCorrectSummary: {
      title: 'Highlight Correct Summary Sectional Test Page',
      path: '/sectional-test/listening/highlight-correct-summary',
    },
    multipleChoiceChooseSingleAnswer: {
      title: 'Multiple Choice Choose Single Answer Sectional Test Page',
      path: '/sectional-test/listening/multiple-choice-choose-single-answer',
    },
    selectMissingWord: {
      title: 'Select Missing Word Sectional Test Page',
      path: '/sectional-test/listening/select-missing-word',
    },
    highlightIncorrectWords: {
      title: 'Highlight Incorrect Words Sectional Test Page',
      path: '/sectional-test/listening/highlight-incorrect-words',
    },
    writeFromDictation: {
      title: 'Write From Dictation Sectional Test Page',
      path: '/sectional-test/listening/write-from-dictation',
    },
  },
  mockTest: {
    summarizeWrittenText: {
      title: 'Summarize Written Text Mock Test Page',
      path: '/mock-test/writing/summarize-written-text',
    },
    writeEssay: {
      title: 'Write Essay Mock Test Page',
      path: '/mock-test/writing/write-essay',
    },
    summarizeSpokenText: {
      title: 'Summarize Spoken Text Mock Test Page',
      path: '/mock-test/listening/summarize-spoken-text',
    },
    multipleChoiceChooseMultipleAnswers: {
      title: 'Multiple Choice Choose Multiple Answers Mock Test Page',
      path: '/mock-test/listening/multiple-choice-choose-multiple-answers',
    },
    fillInTheBlanks: {
      title: 'Fill In The Blanks Mock Test Page',
      path: '/mock-test/listening/fill-in-the-blanks',
    },
    highlightCorrectSummary: {
      title: 'Highlight Correct Summary Mock Test Page',
      path: '/mock-test/listening/highlight-correct-summary',
    },
    multipleChoiceChooseSingleAnswer: {
      title: 'Multiple Choice Choose Single Answer Mock Test Page',
      path: '/mock-test/listening/multiple-choice-choose-single-answer',
    },
    selectMissingWord: {
      title: 'Select Missing Word Mock Test Page',
      path: '/mock-test/listening/select-missing-word',
    },
    highlightIncorrectWords: {
      title: 'Highlight Incorrect Words Mock Test Page',
      path: '/mock-test/listening/highlight-incorrect-words',
    },
    writeFromDictation: {
      title: 'Write From Dictation Mock Test Page',
      path: '/mock-test/listening/write-from-dictation',
    },
    readAloud: {
      title: 'Read Aloud Mock Test Page',
      path: '/mock-test/speaking/read-aloud',
    },
    repeatSentence: {
      title: 'Repeat Sentence Mock Test Page',
      path: '/mock-test/speaking/repeat-sentence',
    },
    describeImage: {
      title: 'Describe Image Mock Test Page',
      path: '/mock-test/speaking/describe-image',
    },
    retellLecture: {
      title: 'Retell Lecture Mock Test Page',
      path: '/mock-test/speaking/retell-lecture',
    },
    answerShortQuestion: {
      title: 'Answer Short Question Mock Test Page',
      path: '/mock-test/speaking/answer-short-question',
    },
    readingMultipleChoiceSingleAnswer: {
      title: 'Reading Multiple Choice Single Answer Mock Test Page',
      path: '/mock-test/reading/multiple-choice-single-answer',
    },
    readingMultipleChoiceMultipleAnswer: {
      title: 'Reading Multiple Choice Multiple Answer Mock Test Page',
      path: '/mock-test/reading/multiple-choice-multiple-answer',
    },
    reorderParagraph: {
      title: 'Reorder Paragraph Mock Test Page',
      path: '/mock-test/reading/reorder-paragraph',
    },
    readingAndWritingFillInTheBlanks: {
      title: 'Fill In The Blanks Mock Test Page',
      path: '/mock-test/reading/fill-in-the-blanks',
    },
    readingFillInTheBlanks: {
      title: 'Reading Fill In The Blanks Mock Test Page',
      path: '/mock-test/reading/reading-fill-in-the-blanks',
    },
  },
  systemRequirement: {
    sectional: {
      speaking: {
        title: 'Sectional Speaking System Requirement Page',
        path: '/sectional-test/start-test-now-2',
      } as MenuItem,
      writing: {
        title: 'Sectional Writing System Requirement Page',
        path: '/sectional-test/start-test-now-3',
      } as MenuItem,
      reading: {
        title: 'Sectional Reading System Requirement Page',
        path: '/sectional-test/start-test-now-4',
      } as MenuItem,
      listening: {
        title: 'Sectional Listening System Requirement Page',
        path: '/sectional-test/start-test-now-5',
      } as MenuItem,
    },
    mock: {
      title: 'Mock Test System Requirement Page',
      path: '/mock-test/start-test-now-1',
    } as MenuItem,
  },
  yourPurchase: { title: 'Purchase Page', path: '/purchase' } as MenuItem,
  purchaseSucceed: { title: 'Purchase Status Succeed Page', path: '/purchase/succeed' } as MenuItem,
  cart: { title: 'Cart Page', path: '/cart' } as MenuItem,
  myPerformance: {
    title: 'My Performance Page',
    path: '/my-performance',
  } as MenuItem,
  practicePerformance: {
    title: 'Practice Performance Page',
    path: '/practice-performance',
  } as MenuItem,
  // liveChat: {
  //   title: 'Live Chat Page',
  //   path: '/live-chat',
  // } as MenuItem,
  support: {
    title: 'Support Page',
    path: '/support',
  } as MenuItem,
  userProfile: {
    title: 'User Profile Page',
    path: '/user-profile',
  } as MenuItem,
  historyDetailsMock: {
    title: 'Mock Test History Details Page',
    path: '/mock-test/history-details',
  },
  testHistoryMock: {
    title: 'Mock Test - Test History Page',
    path: '/mock-test/history',
  },
  testHistorySectional: {
    title: 'Sectional Test - Test History Page',
    path: '/sectional-test/history',
  },
  reportHistorySectional: {
    speaking: {
      title: 'Report History Speaking Sectional Page',
      path: '/sectional-test/report-history-speaking/:buyId',
    },
    writing: {
      title: 'Report History Writing Sectional Page',
      path: '/sectional-test/report-history-writing/:buyId',
    },
    listening: {
      title: 'Report History Listening Sectional Page',
      path: '/sectional-test/report-history-listening/:buyId',
    },
    reading: {
      title: 'Report History Reading Sectional Page',
      path: '/sectional-test/report-history-reading/:buyId',
    },
  },
}

export default Path
