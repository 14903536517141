import React, { useState, useEffect, ChangeEvent } from 'react'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import WeeklyPredictionKeywordIcon from '../../images/WeeklyPrediction/weekly-prediction-keyword.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import ReadAloudSaveAsSvg from '../../images/Speaking/read-aloud-save-as.svg'
import ReadAloudEditSvg from '../../images/Speaking/read-aloud-edit.svg'
import ReadAloudMarkSvg from '../../images/Speaking/read-aloud-mark.svg'
import ReadAloudArrowSvg from '../../images/Speaking/read-aloud-arrow.svg'

type SummarizeWrittenTextProps = {
  setShowScoreBoard: (value: boolean) => void
  showScoreBoard: boolean
}

const question = {
  audio: 'https://ptefilesbucket-stg.s3.amazonaws.com/test_question/0/',
  category_id: '11',
  created_at: '2023-04-02 17:50:29',
  description: '',
  dynamic_column_count: '0',
  frequency: '49639',
  highly_repeated: '17',
  id: '396',
  image: '',
  material_type: '1',
  my_attampt_sort: '0',
  question: '<p>In some countries, the birth rate is slowing, the problem of an aging population, what is the causes and effects of this issue? How to solve this problem?</p>\r\n',
  question_quality_sort: '0',
  question_type_sort: '0',
  random_order: '0',
  sequence_category_id: '0',
  short_title: 'You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judge on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.',
  test_id: '0',
}

const WriteEssayQuestion: React.FC<SummarizeWrittenTextProps> = ({
  setShowScoreBoard,
  showScoreBoard,
}) => {
  const [timer, setTimer] = useState({ minutes: 20, seconds: 0 })
  const [wordsCount, setWordsCount] = useState(0)

  // Function to start the timer countdown
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (timer.minutes === 0 && timer.seconds === 0) {
      // Timer has reached 00:00, stop the timer
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 })
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 })
        }
      }, 1000)
    }

    // Cleanup function to clear the interval when component unmounts or timer stops
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timer])

  const formattedTime = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const text = e.target.value
    const wordCount = text.trim().split(/\s+/).filter(Boolean).length
    setWordsCount(wordCount)
  }

  return (
    <div>
      <p className="text-bodym text-danger mt-2">
        * This question carries marks for Writing only (~18%)
      </p>
      <div className="p-8 rounded-xl shadow mt-8">
        <div className="w-[6.25rem] h-[3.5rem] bg-primary-2 mb-6 rounded-lg flex justify-center items-center mx-auto">
          <p className="text-h2 text-white">{formattedTime}</p>
        </div>
        <p className="text-h4m text-neutrals-1">
          {question.short_title}
        </p>
        <div 
          className="text-h5r text-neutrals-1 pt-4 mb-4"
          dangerouslySetInnerHTML={{ __html: question.question }}
        >
        </div>
        <div className="flex flex-col gap-2 mb-4">
          <label htmlFor="swt-answer" className="text-h5b">
            Your answer
          </label>
          <textarea
            className="w-full h-[10rem] text-bodyr border border-1 border-[#D9D9D9] p-2 resize-none"
            placeholder="Write here..."
            onChange={handleTextChange}
          />
        </div>
        <p className="text-body mb-4">
          Words count: {wordsCount}
        </p>
        <div className="flex items-center mb-4">
          <p className="text-bodym text-neutrals-1">My note:</p>
          <img
            src={ReadAloudSaveAsSvg}
            alt="save as"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudEditSvg}
            alt="edit"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudMarkSvg}
            alt="mark"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
        </div>
        <div className="w-[90%] lg:w-[60%] mb-4 mx-auto">
          <div className="flex place-content-center	mt-8">
            <button className="flex text-bodyr text-white items-center py-2 px-6 bg-success rounded-lg mr-2">
              <img
                src={WeeklyPredictionSubmitIcon}
                alt="submit"
                className="mr-2"
              />
              Submit
            </button>

            <button className="flex text-bodyr text-neutrals-2 items-center p-2 border border-1px-neutrals-2 rounded-lg">
              <img
                src={WeeklyPredictionKeywordIcon}
                alt="keyword"
                className="mr-2"
              />
              Unlock Keywords
            </button>
          </div>
        </div>
        <div
          className="flex items-center justify-center mt-[2rem] cursor-pointer"
          onClick={() => setShowScoreBoard(!showScoreBoard)}
        >
          <img
            src={ReadAloudArrowSvg}
            alt="arrow"
            className={`transition-transform duration-300 ${showScoreBoard ? 'rotate-180' : 'rotate-0'}`}
          />

          <p className="text-bodyr text-info ml-2">Show Score Board</p>
        </div>
      </div>

      <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
          <p className="hidden md:block text-bodyr text-white">Previous</p>
        </button>
        <button 
          className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
          onClick={() => window.location.reload()}
        >
          <p className="hidden md:block text-bodyr text-white">Try Again</p>
          <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
        </button>
        <div>
          <select className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y">
            {Array.from({ length: 20 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <p className="hidden md:block text-bodyr text-white">Next</p>
          <img src={NextArrowSvg} alt="next" className="md:ml-2" />
        </button>
      </div>
    </div>
  )
}

export default WriteEssayQuestion
