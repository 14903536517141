import React from 'react'

type ProgressBarProps = {
  progress: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div className="audio-section__progress-bar px-4 2xl:w-[26rem] w-full h-[2.5rem] border border-2 border-gray-400 rounded-lg flex justify-center items-center mt-4 mx-auto">
      <div className="w-full h-[0.375rem] rounded-[0.625rem] bg-[#D9D9D9] relative">
        <div
          style={{
            width: `${progress}%`,
            backgroundColor: '#262626',
            height: '100%',
            borderRadius: '10px',
            transition: 'width 0.1s linear',
          }}
        />
        <div
          style={{
            left: `calc(${progress}% - 0.5rem)`,
            transition: 'left 0.1s linear',
          }}
          className="absolute top-1/2 w-4 h-4 bg-[#262626] rounded-full transform -translate-y-1/2"
        />
      </div>
    </div>
  )
}

export default ProgressBar
