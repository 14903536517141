interface SuccessMessageProps {
    message: string
}
    
const SuccessMessage: React.FC<SuccessMessageProps> = ({ message }) => {
    return (
      <div className="p-4 text-sm text-green-800 rounded-lg bg-green-200 border border-green-600" role="alert">
        <span className="font-medium">{message}</span>
      </div>
    )
}
    
export default SuccessMessage