import SectionalAnswerShortQuestion from '../../../components/SectionalTest/Speaking/AnswerShortQuestion'

const SectionalAnswerShort = () => {
  return (
    <div>
      <SectionalAnswerShortQuestion />
    </div>
  )
}

export default SectionalAnswerShort
