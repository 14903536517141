import React, { useEffect, useState } from 'react'
import MyUnlockedTestsSection from '../components/MyUnlockedTestsPage/MyUnlockedTestsSection'
import { getMyUnlockedTest } from '../services/purchase.services'
import { useAuth } from '../providers/AuthProvider'
import Test from '../models/test.model'

const MyUnlockedTestsPage = () => {
  const [tests, setTests] = useState<Test[]>([])
  const [mockCredits, setMockCredits] = useState<number>(0)
  const { userProfile } = useAuth()

  useEffect(() => {
    if (userProfile?.userId) {
      getMyUnlockedTest(userProfile.userId)
        .then((response) => {
          setTests(response.data.test)
          setMockCredits(response.data.mockCredits)
        })
        .catch((error) => {
          console.error('Error fetching tests:', error)
        })
    }
  }, [userProfile])

  return (
    <>
      <div className="navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          Mock Test / My Unlocked Tests
        </p>
      </div>
      <div className="w-full lg:min-h-[56rem] h-fit lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <MyUnlockedTestsSection tests={tests} mockCredits={mockCredits} />
      </div>
    </>
  )
}

export default MyUnlockedTestsPage
