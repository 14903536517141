import axiosInstance from '../configs/axiosConfig'

export function dashboard(userId: number) {
  const data = {
    user_id: userId,
  }

  return axiosInstance.post(`/users/dashboard`, data)
}

export function getVerifiedUsers() {
  return axiosInstance.get(`/users/verified`)
}

export function getLastMinuteStudy(userId: number, categoryId: number) {
  const data = {
    user_id: userId,
    category: categoryId,
  }

  return axiosInstance.post(`/users/last-minute-study`, data)
}

export function applyStudentCode(userId: number, promo: string) {
  const data = {
    user_id: userId,
    promo: promo,
  }

  return axiosInstance.post(`/users/check-coupon-student`, data)
}

export function changePassword(userId: number, newPassword: string) {
  const data = {
    userId: userId,
    newPassword: newPassword.trim(),
  }

  return axiosInstance.post(`/users/change-password`, data)
}

export function getUserDetails(userId: number) {
  return axiosInstance.get(`users/${userId}`)
}

export function updateUserProfile(userId: number, username: string, mobile: string, userImage?: File) {
  const formData = new FormData()
  formData.append('id', String(userId))
  formData.append('username', username)
  formData.append('usermobile', mobile)

  if (userImage) {
    formData.append('image', userImage)
  }

  return axiosInstance.put(`/users/${userId}/update-user-profile`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function setTargetScore(userId: number, targetScore: number, targetTestDate: string) {
  const data = {
    user_id: userId,
    target_score: targetScore,
    target_test_date: targetTestDate,
  }

  return axiosInstance.post(`/users/set-target-score`, data)
}