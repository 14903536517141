import React, { useState, useEffect, useRef, useCallback } from 'react'
import ReadAloudSaveAsSvg from '../../images/Speaking/read-aloud-save-as.svg'
import ReadAloudEditSvg from '../../images/Speaking/read-aloud-edit.svg'
import ReadAloudMarkSvg from '../../images/Speaking/read-aloud-mark.svg'
import ReadAloudArrowSvg from '../../images/Speaking/read-aloud-arrow.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'

type ReadAloudQuestionProps = {
  setShowScoreBoard: (value: boolean) => void
  showScoreBoard: boolean
}

const ReadAloudQuestion: React.FC<ReadAloudQuestionProps> = ({
  setShowScoreBoard,
  showScoreBoard,
}) => {
  const maxRecordingTime = 40
  const recordingTimeout = 40

  const [remainingTime, setRemainingTime] = useState(recordingTimeout)
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [recordingTime, setRecordingTime] = useState(maxRecordingTime)
  const recorderRef = useRef<MediaRecorder | null>(null)
  const [progress, setProgress] = useState(0)

  const startRecording = useCallback(async () => {
    setIsRecording(true)
    setRecordingTime(40)
    setProgress(0)
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const newRecorder = new MediaRecorder(stream)
      recorderRef.current = newRecorder
      const chunks: BlobPart[] = []

      newRecorder.ondataavailable = (event) => {
        chunks.push(event.data)
      }

      newRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' })
        setAudioBlob(blob)
      }

      newRecorder.start()
    } catch (error) {
      console.error('Error accessing microphone:', error)
      setIsRecording(false)
    }
  }, [])

  const stopRecording = useCallback(() => {
    if (recorderRef.current) {
      recorderRef.current.stop()
      setIsRecording(false)
      setRecordingTime(40)
      setProgress(100)
    }
  }, [recorderRef, setIsRecording, setRecordingTime, setProgress])

  const handleSkip = () => {
    if (!isRecording && remainingTime > 0) {
      startRecording()
      setRemainingTime(0)
    }
  }

  const resetState = () => {
    setIsRecording(false)
    setAudioBlob(null)
    setRecordingTime(40)
    setRemainingTime(40)
    setProgress(0)
  }

  useEffect(() => {
    if (remainingTime > 0) {
      const timerId = setTimeout(() => {
        setRemainingTime(remainingTime - 1)
      }, 1000)
      return () => clearTimeout(timerId)
    } else if (remainingTime === 0) {
      startRecording()
    }
  }, [remainingTime, startRecording])

  useEffect(() => {
    if (isRecording && recordingTime > 0) {
      const intervalId = setInterval(() => {
        setRecordingTime(recordingTime - 1)
        setProgress(((40 - recordingTime + 1) / 40) * 100)
      }, 1000)
      return () => clearInterval(intervalId)
    } else if (recordingTime <= 0 && isRecording) {
      stopRecording()
    }
  }, [isRecording, recordingTime, stopRecording])

  return (
    <div>
      {!isRecording && !audioBlob && (
        <div className="block md:flex justify-between">
          <div className="flex text-center items-center mb-2 md:mb-0">
            <p className="text-h5m text-neutrals-2 mr-2">Beginning in:</p>
            <p className="text-h3m text-neutrals-1">{remainingTime} seconds</p>
          </div>
          <button
            onClick={handleSkip}
            className="text-bodyr text-neutrals-1 p-2 rounded-lg border border-[1px]-[#D9D9D9] hover:scale-105"
          >
            Skip to Test
          </button>
        </div>
      )}

      <p className="text-bodym text-danger mt-2 md:mt-8">
        * This question carries marks for Speaking (~25%) and Reading (24%)
      </p>

      <div className="p-8 rounded-xl shadow mt-8">
        <p className="text-h4m text-neutrals-1">
          Look at the text below. In 40 seconds, you must read this text aloud
          as naturally and clearly as possible. You have 40 seconds to read
          aloud.
        </p>
        <p className="text-h5r text-neutrals-1 pt-4">
          Modern buildings have to achieve certain performance requirements, at
          least to satisfy those of building codes, to provide a safe, healthy,
          and comfortable environment. However, these conditioned environments
          demand resources in energy and materials, which are both limited in
          supply, to build and operate
        </p>
        {isRecording && (
          <div className="bg-[#F1EFF2] text-center p-4 rounded-xl w-[50%] mx-auto mt-4">
            <p className="text-h5r mt-4">Timer: {recordingTime} seconds</p>
            <div className="audio-section__progress-bar px-4 2xl:w-[26rem] w-full h-[2.5rem] border border-2 border-gray-400 rounded-lg flex justify-center items-center mt-4 mx-auto">
              <div className="w-full h-[0.375rem] rounded-[0.625rem] bg-[#D9D9D9] relative">
                <div
                  style={{
                    width: `${progress}%`,
                    backgroundColor: '#262626',
                    height: '100%',
                    borderRadius: '10px',
                    transition: 'width 0.1s linear',
                  }}
                />
                <div
                  style={{
                    left: `calc(${progress}% - 0.5rem)`,
                    transition: 'left 0.1s linear',
                  }}
                  className="absolute top-1/2 w-4 h-4 bg-[#262626] rounded-full transform -translate-y-1/2"
                />
              </div>
            </div>
            <button
              onClick={stopRecording}
              className="text-bodyr text-neutrals-1 p-2 mt-2 rounded-lg border border-[1px]-[#D9D9D9] hover:scale-105"
            >
              Stop
            </button>
          </div>
        )}
        <div className="flex items-center pt-4">
          <p className="text-bodym text-neutrals-1">My note:</p>
          <img
            src={ReadAloudSaveAsSvg}
            alt="save as"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudEditSvg}
            alt="edit"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudMarkSvg}
            alt="mark"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
        </div>
        <div
          className="flex items-center justify-center mt-4 cursor-pointer"
          onClick={() => setShowScoreBoard(!showScoreBoard)}
        >
          <img
            src={ReadAloudArrowSvg}
            alt="arrow"
            className={`transition-transform duration-300 ${showScoreBoard ? 'rotate-180' : 'rotate-0'}`}
          />

          <p className="text-bodyr text-info ml-2">Show Score Board</p>
        </div>
      </div>

      <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
          <p className="hidden md:block text-bodyr text-white">Previous</p>
        </button>
        <button
          className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
          onClick={resetState}
        >
          <p className="hidden md:block text-bodyr text-white">Try Again</p>
          <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
        </button>
        <div>
          <select className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y">
            {Array.from({ length: 20 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <p className="hidden md:block text-bodyr text-white">Next</p>
          <img src={NextArrowSvg} alt="next" className="md:ml-2" />
        </button>
      </div>

      <div className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4">
        <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
          <p className="text-h4m">Original Audio</p>
          <audio
            controls
            src="https://ptefilesbucket-stg.s3.amazonaws.com/test_question/0/643e4140af378.wav"
            className="my-4 w-full"
          />
          <p>
            Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
            consectetur, adipisci velit, sed quNeque porro quisquam est, qui
            dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed
            quNeque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
            consectetur, adipisci velit, sed qu
          </p>
        </div>
        <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
          <p className="text-h4m">Your Audio</p>
          {audioBlob && (
            <>
              <audio
                controls
                src={URL.createObjectURL(audioBlob)}
                className="my-4 w-full"
              />
              <p>
                Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit, sed quNeque porro quisquam est, qui
                dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
                sed quNeque porro quisquam est, qui dolorem ipsum quia dolor sit
                amet, consectetur, adipisci velit, sed qu
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReadAloudQuestion
