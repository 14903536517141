import { useState, useEffect } from 'react'
import { dashboard, getVerifiedUsers } from '../services/user.services'
import HeroSection from '../components/HomePage/HeroSection'
import RecentVideo from '../components/HomePage/RecentVideo'
import TopStudentList from '../components/HomePage/TopStudentList'
import { useAuth } from '../providers/AuthProvider'
import TopTenStudents from '../models/topTenStudents.model'
import VerifiedUsers from '../models/verifiedUsers.model'
import YoutubeVideoType from '../models/youtubeVideoType.model'
import YoutubeVideo from '../models/youtubeVideo.model'
import {
  getYoutubeType,
  getYoutubeVideosByType,
} from '../services/media.services'

const HomePage = () => {
  const [topTenStudents, setTopTenStudents] = useState<TopTenStudents[]>([])
  const [verifiedUsers, setVerifiedUsers] = useState<VerifiedUsers[]>([])
  const [youtubeVideoTypes, setYoutubeVideoTypes] = useState<
    YoutubeVideoType[]
  >([])
  const [youtubeVideos, setYoutubeVideos] = useState<YoutubeVideo[]>([])
  const { userProfile } = useAuth()

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (userProfile) {
        try {
          const response = await dashboard(userProfile.userId)
          const topTenStudentsData = response.data.getTopTen.map(
            (student: any) => ({
              name: student.name,
              image: student.image,
              testName: student.testname,
              testDescription: student.testdescription,
              userId: student.user_id,
              overallTotal: student.overalltotal,
              scoreSpeakingSection: student.score_speakinig_section,
              scoreListeningSection: student.score_listening_section,
              scoreWritingSection: student.score_writing_section,
              scoreReadingSection: student.score_reading_section,
            }),
          )
          setTopTenStudents(topTenStudentsData)
          localStorage.setItem(
            'predictionDate',
            JSON.stringify(response.data.predictionDate),
          )
        } catch (error) {
          console.error('Error fetching dashboard data:', error)
        }
      }
    }

    fetchDashboardData()
  }, [userProfile])

  useEffect(() => {
    const fetchVerifiedUsers = async () => {
      try {
        const response = await getVerifiedUsers()
        if (response.data.success) {
          const verifiedUserData = response.data.verifiedUser.map(
            (user: any) => ({
              userId: user.userId,
              image: user.image,
              name: user.name,
              adminUpload: user.adminUpload,
              score: Number(user.score),
            }),
          )
          setVerifiedUsers(verifiedUserData)
        }
      } catch (error) {
        console.error('Error fetching verified users:', error)
      }
    }

    fetchVerifiedUsers()
  }, [])

  useEffect(() => {
    const fetchYoutubeData = async () => {
      try {
        const youtubeTypeResponse = await getYoutubeType()
        setYoutubeVideoTypes(youtubeTypeResponse.data.youtubeInfo)

        const youtubeVideosResponse = await getYoutubeVideosByType()
        setYoutubeVideos(youtubeVideosResponse.data.resultInfo[0].videos)
      } catch (error) {
        console.error('Error fetching YouTube data:', error)
      }
    }

    fetchYoutubeData()
  }, [])

  return (
    <div className="w-full items-center justify-center bg-white rounded-l-lg">
      <HeroSection verifiedUsers={verifiedUsers} />
      <TopStudentList topTenStudents={topTenStudents} />
      <RecentVideo
        youtubeVideoTypes={youtubeVideoTypes}
        youtubeVideos={youtubeVideos}
      />
    </div>
  )
}

export default HomePage
