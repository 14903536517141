import { Link } from "react-router-dom"
import MyPerformanceSection from "../components/MyPerformancePage/MyPerformanceSection"

const MyPerformancePage = () => {
    return (
        <>
            <div className="navigation my-[1rem]">
                <p className="text-bodyr font-landingPrimary text-neutrals-2">
                    <Link to="/homepage">Home</Link> / My Performance
                </p>
            </div>
            <div className="w-full lg:min-h-[56rem] min-h-[30rem] p-[1rem] bg-white rounded-l-lg">
                <MyPerformanceSection/>
            </div>
      </>
    )
}

export default MyPerformancePage