import axiosInstance from '../configs/axiosConfig'

export function getShopData(userId: number) {
  const data = {
    user_id: userId,
  }

  return axiosInstance.post(`/shop/shop-data`, data)
}

export function getTokenPacksMock(userId: number) {
  const data = {
    user_id: userId,
  }

  return axiosInstance.post(`/shop/token-packs-mock`, data)
}

export function getShopDeals(userId: number) {
  const data = {
    user_id: userId,
  }

  return axiosInstance.post(`/shop/shop-deals`, data)
}

export function checkoutStripe(userId: number, paymentMethodId: string) {
  const data = {
    userId: userId,
    paymentMethodId: paymentMethodId,
  }

  return axiosInstance.post(`/shop/checkout-stripe`, data)
}
