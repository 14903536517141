import React from 'react'
import { Link } from 'react-router-dom'
import Vector from '../../images/Landingpage/vector.png'
import TestimonialsAvatar from '../../images/Landingpage/testimonials-avatar.png'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import { Autoplay } from 'swiper/modules'

const TestimonialsSection: React.FC = () => {
  const swiperContents = () => {
    return (
      <div className="lg:h-[20rem] h-fit bg-white lg:px-[1rem] px-[0.6rem] lg:py-[2rem] py-[0.6rem] rounded-xl flex">
        <div className="w-1/6">
          <img
            src={TestimonialsAvatar}
            alt="User Avatar"
            width={60}
            height={60}
          />
        </div>
        <div className="w-5/6 mr-[1rem] lg:px-[0.4rem] px-[0.2rem]">
          <p className="text-h5b text-[#1D4ED8] font-landingLogo">TOM PANSON</p>
          <p className="text-footnote text-[#AAAAAA] font-landingPrimary my-[0.6rem]">
            May 13, 2019 by Silene Cox
          </p>
          <p className="text-bodyr text-neutrals-1 font-landingPrimary">
            “PTE Master is no doubt the best PTE training centre not just in
            Sydney, Melbourne but in entire Australia. My friend and I all got
            79 from trainer Ady Bui and Julia MN. I was broke as hell as I quit
            my job to focus on my PTE training. Ady Bui told me don't worry
            about the tuition fee, just pay back whenever I can, and just keep
            coming to class and learn. Within 2 months I got 79 and it wasn't
            that hard. With the platform and materials available online, you can
            study anywhere anytime.”
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="testimonials-section w-full lg:h-[71.75rem] h-full bg-[#FFF7E6] lg:py-0 py-[2rem]">
      <div className="testimonials-section__title w-full lg:h-[25rem] lg:px-[4rem] px-[4rem] h-full flex flex-wrap justify-between items-center gap-y-4">
        <div className="testimonials-section__title__main font-landingLogo lg:w-[25.3rem]">
          <p className="text-base font-normal leading-[3.75rem]">
            Testimonials
          </p>
          <p className="text-6xl font-bold leading-[3.75rem] text-[#FA8C16]">
            Listen to our customers
          </p>
        </div>
        <div className="testimonials-section__title__button">
          <Link
            to="/"
            className="font-bodyr font-landingPrimary text-white rounded-xl bg-[#FA8C16] px-6 py-4 flex items-center justify-center hover:scale-105"
          >
            <img src={Vector} alt="vector icon" className="mr-2" />
            See more reviews here
          </Link>
        </div>
      </div>
      <div className="testimonials-section__swiper w-full lg:h-[46.75rem] h-full lg:py-0 py-[2rem] relative">
        <div className="w-full lg:h-[18.625rem] h-full landing-swiper">
          <Swiper
            autoHeight={true}
            loop={true}
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>{swiperContents}</SwiperSlide>
            <SwiperSlide>{swiperContents}</SwiperSlide>
            <SwiperSlide>{swiperContents}</SwiperSlide>
            <SwiperSlide>{swiperContents}</SwiperSlide>
            <SwiperSlide>{swiperContents}</SwiperSlide>
            <SwiperSlide>{swiperContents}</SwiperSlide>
          </Swiper>
        </div>
        <div className="w-full lg:h-[18.625rem] h-full lg:mt-[3rem] mt-[1rem] landing-swiper">
          <Swiper
            autoHeight={true}
            loop={true}
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>{swiperContents}</SwiperSlide>
            <SwiperSlide>{swiperContents}</SwiperSlide>
            <SwiperSlide>{swiperContents}</SwiperSlide>
            <SwiperSlide>{swiperContents}</SwiperSlide>
            <SwiperSlide>{swiperContents}</SwiperSlide>
            <SwiperSlide>{swiperContents}</SwiperSlide>
          </Swiper>
        </div>
        {/* Add blur effect */}
        <div className="landing-swiper-blur left-0"></div>
        <div className="landing-swiper-blur right-0"></div>
      </div>
    </div>
  )
}

export default TestimonialsSection
