import { Link } from 'react-router-dom'
import Path from '../../../routes/Path'
import ReportHistoryListeningSectionalSection from '../../../components/SectionalTest/ReportHistory/ReportHistoryListeningSectionalSection'

const ReportHistoryListeningSectionalPage = () => {
  return (
    <>
      <div className="navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to={Path.home.path}>Home</Link> / Sectional Test /{' '}
          <Link to={Path.testHistorySectional.path}>
            Test History & Analysis
          </Link>{' '}
          / Report History Listening
        </p>
      </div>
      <div className="w-full min-h-[80vh] lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <ReportHistoryListeningSectionalSection />
      </div>
    </>
  )
}

export default ReportHistoryListeningSectionalPage
