type SupportCardProps = {
  question: string
  answer: string
}

const SupportCard: React.FC<SupportCardProps> = ({ question, answer }) => {
  return (
    <div className="support-section__questions__card bg-neutrals-4 rounded-lg py-[1.5rem] px-[1rem] shadow-support-card lg:w-[22rem] w-full lg:h-[16.125rem] h-full">
      <p className="questions__card__question text-h5m">{question}</p>
      <p className="questions__card__answer text-bodyr mt-[0.6rem]">{answer}</p>
    </div>
  )
}

export default SupportCard
