import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import ReadAloudSaveAsSvg from '../../images/Speaking/read-aloud-save-as.svg'
import ReadAloudEditSvg from '../../images/Speaking/read-aloud-edit.svg'
import ReadAloudMarkSvg from '../../images/Speaking/read-aloud-mark.svg'
import AudioSectional from '../SectionalTest/AudioSectional'

const question = {
  audio: 'https://ptefilesbucket-stg.s3.amazonaws.com/test_question/0/5d12c57739fce.mp3',
  category_id: '19',
  created_at: '2023-04-02 17:50:29',
  description: `When density is high enough our natural <span class="drog-box" id="div1" contenteditable="true" rel="reaction" rel2="wwffsjererafdsfra"> </span><span class="display_blank2258 g g-h">reaction</span> to the closeness of all those vehicles behind us is to slow down. This <span class="drog-box" id="div2" contenteditable="true" rel="reduces" rel2="wwffsjererafdsfra"> </span><span class="display_blank2258 g g-h">reduces</span> the flow and speed, and increases the <span class="drog-box" id="div3" contenteditable="true" rel="density" rel2="wwffsjererafdsfra"> </span><span class="display_blank2258 g g-h">density</span> , which can cause even more slowness. Eventually the whole thing grinds down to a low speed.\r\n\r\nDensity is increased by people joining a road from on-ramps, and this causes vehicles to slow down, i.e. people are generally bad at <span class="drog-box" id="div4" contenteditable="true" rel="merging" rel2="wwffsjererafdsfra"> </span><span class="display_blank2258 g g-h">merging</span> at speed. Then some people who have been held up in the left-hand lane move to the middle lane which causes the same problem in the middle <span class="drog-box" id="div5" contenteditable="true" rel="lane" rel2="wwffsjererafdsfra"> </span><span class="display_blank2258 g g-h">lane</span> – traffic backs up. Then some people dive from the middle lane into the overtaking lane and that slows down.`,
  dynamic_column_count: '0',
  frequency: '51316',
  highly_repeated: '0',
  id: '1345',
  image: '',
  material_type: '1',
  my_attampt_sort: '0',
  question: '',
  question_quality_sort: '0',
  question_type_sort: '0',
  random_order: '0',
  sequence_category_id: '19',
  short_title: 'You will hear a recording. Type the missing words in each blank.',
  test_id: '0',
}

const FillInTheBlanksQuestion = () => {
  const [showCorrectWords, setShowCorrectWords] = useState(false);
  const navigate = useNavigate();

  const handleReload = () => {
    navigate(0); // this reloads the current route
  };

    const handleToggleVisibility = () => {
      setShowCorrectWords(prevState => !prevState);
    };

    const formattedParagraph = () => {
      let inputCount = 0;

      // Split the updated text by <span class="drog-box"...></span> elements
      const drogBoxRegex = /(<span class="drog-box"[^>]*>[^<]*<\/span>)/g;
      const parts = question.description.split(drogBoxRegex);

      return parts.map((part, index) => {
          // Check if the part matches the <span class="drog-box"...></span> pattern
          if (drogBoxRegex.test(part)) {
              inputCount++;
              return (
                  <span key={index} className="inline-flex items-center mx-1">
                      <span className="w-5 h-5 flex items-center justify-center rounded-full bg-info text-white text-xs">
                          {inputCount}
                      </span>
                      <input
                          type="text"
                          maxLength={20}
                          className="inline-block w-[8rem] h-[2rem] mx-1 p-1 rounded-lg border border-2 border-[#D9D9D9]"
                      />
                  </span>
              );
          }

          // Replace <span class="display_blank2258 g g-h">...</span> with conditionally visible <span>...</span>
          const displayBlankRegex = /<span class="display_blank2258 g g-h">([^<]*)<\/span>/g;
          const replacedPart = part.replace(displayBlankRegex, (match, p1) => {
              return `<span class="font-bold text-green-400 transition-opacity duration-500 ${!showCorrectWords ? 'hidden' : ''}">${p1}</span>`;
          });

          return <span key={index} dangerouslySetInnerHTML={{ __html: replacedPart }} />;
      });
  };
  

  return (
    <div>
      <p className="text-bodym text-danger mt-2">
        * This question carries marks for Listening (~8%) and Writing (~7%)
      </p>
      <div className="p-8 rounded-xl shadow mt-8">
        <p className="text-h4m text-neutrals-1 mb-[2rem]">
          {question.short_title}
        </p>
        <AudioSectional audioUrl={question.audio}/>
        <div className="text-bodyr mb-4">
          {formattedParagraph()}
        </div>
        <div className="flex items-center mb-4">
          <p className="text-bodym text-neutrals-1">My note:</p>
          <img
            src={ReadAloudSaveAsSvg}
            alt="save as"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudEditSvg}
            alt="edit"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudMarkSvg}
            alt="mark"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
        </div>
        <div className="w-[90%] lg:w-[60%] mb-4 mx-auto">
          <div className="flex place-content-center	mt-8">
            <button 
              className="flex text-bodyr text-white items-center py-2 px-6 bg-success rounded-lg mr-2"
              onClick={handleToggleVisibility}
            >
                <img
                  src={WeeklyPredictionSubmitIcon}
                  alt="submit"
                  className="mr-2"
                />
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
          <p className="hidden md:block text-bodyr text-white">Previous</p>
        </button>
        <button 
          className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
          onClick={handleReload}
        >
          <p className="hidden md:block text-bodyr text-white">Try Again</p>
          <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
        </button>
        <div>
          <select className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y">
            {Array.from({ length: 20 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <p className="hidden md:block text-bodyr text-white">Next</p>
          <img src={NextArrowSvg} alt="next" className="md:ml-2" />
        </button>
      </div>
    </div>
  )
}

export default FillInTheBlanksQuestion
