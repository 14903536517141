import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
// import 'swiper/css/scrollbar';
import 'swiper/css/navigation'
import { Scrollbar, Autoplay } from 'swiper/modules'
import AboutAvatar from '../../images/Landingpage/about-avatar.png'
import AboutRealPteStudyMaterialIcon from '../../images/Landingpage/about-real-pte-study-material.png'
import AboutMocktestWithScoreBoardIcon from '../../images/Landingpage/about-mocktest-with-scoreboard.png'
import AboutPTETipsAndTricksIcon from '../../images/Landingpage/about-pte-tips-and-tricks.png'
import AboutImagesSlide1 from '../../images/Landingpage/about-image-slide-1.png'

import '../../styles/Landingpage/custom-swiper-scrollbar.css'

const AboutSection: React.FC = () => {
  const aboutFirstList = [
    {
      icon: AboutRealPteStudyMaterialIcon,
      title: 'REAL PTE STUDY MATERIAL',
      description:
        'Through our vast network and connections, we can collect the latest and rarest questions that have recently appeared in the real pte exam so you learn and memorize.',
    },
    {
      icon: AboutMocktestWithScoreBoardIcon,
      title: 'MOCK TEST WITH SCORECARD',
      description:
        "It's highly recommended to know where your theoretical score is by taking our ai pte academic mock tests. They allow you to test your knowledge and boost your confidence.",
    },
    {
      icon: AboutPTETipsAndTricksIcon,
      title: 'PTE TIPS AND TRICKS',
      description:
        "We provide the best PTE templates for students to apply in different modules such as Describe Image, Retell Lecture, Summarize Written Text, Essay and Summarize Spoken Text. This helps to boost student's score in PTE Academic Speaking, Writing, Reading and Listening test.",
    },
  ]

  const imageSlides = [
    {
      src: AboutImagesSlide1,
      alt: 'About Image Slide 1',
      title: 'REAL PTE STUDY MATERIAL',
      description:
        'Improve and validate your knowledge with our accurate mock test.',
    },
    {
      src: AboutImagesSlide1,
      alt: 'About Image Slide 1',
      title: 'MOCK TEST WITH SCORECARD',
      description:
        'Improve and validate your knowledge with our accurate mock test.',
    },
    {
      src: AboutImagesSlide1,
      alt: 'About Image Slide 1',
      title: 'PTE TIPS AND TRICKS',
      description:
        'Improve and validate your knowledge with our accurate mock test.',
    },
  ]

  return (
    <div className="hero-section">
      <div className="hero-section__first px-4 lg:py-[8rem] py-[4rem] flex justify-center items-center bg-[#E6F7FF]">
        <div className="hero-section__first__profile flex lg:w-3/5 w-full lg:space-x-[2rem]">
          <div className="hero-section__first__profile__left font-landingPrimary w-2/4 flex flex-col justify-center items-center">
            <img
              src={AboutAvatar}
              alt="Avatar of the CEO"
              className="lg:w-[14.813rem] md:w-[10rem] w-[8rem] lg:h-[14.813rem] md:h-[10rem] h-[8rem]"
            />
            <p className="text-h4m text-neutrals-1 py-2 text-center">
              Mr. Ady Nguyen
            </p>
            <p className="text-bodyr text-neutrals-1 text-center">
              CEO of PTE Master
            </p>
          </div>
          <div className="hero-section__first__profile__right lg:w-full w-full lg:pl-[5rem] pl-[2rem]">
            <p className="text-bodyb text-[#1890FF] font-landingPrimary">
              ABOUT US
            </p>
            <p className="font-bold font-landingPrimary text-neutrals-1 text-[3rem] lg:leading-[5rem] leading-[4rem]">
              Our Story
            </p>
            <p className="text-bodyr text-neutrals-1 text-justify">
              PTE Master provides AI PTE academic training course to all
              students who wish to achieve a high pte score. We started our
              journey in 2016 and have helped thousands of students to achieve
              their desire score in short amount of time.
            </p>
            <p className="text-bodyr text-neutrals-1 text-justify mt-2">
              With enormous experiences in tackling the PTE exam, PTE MASTER has
              successfully become the first center in AI Based PTE Academic
              Australia to develop a fully automated platform that allows
              students to take a real pte mock test and receive result almost
              instantly.
            </p>
            <p className="text-bodyr text-neutrals-1 text-justify mt-2">
              All algorithms are built based on the real mathematic calculations
              as per the standard of PTE Academic.
            </p>
          </div>
        </div>
      </div>
      <div className="hero-section__second flex flex-wrap py-[4rem]">
        <div className="hero-section__second__left xl:w-1/2 w-full lg:py-4 py-2">
          <p className="second__left__title text-3xl font-landingLogo font-bold leading-10 lg:ml-[10rem] mx-[2rem] lg:mr-[8rem]">
            How <span className="text-info">PTE Master</span> helps you achieve
            your desire score in PTE academic?
          </p>
          {aboutFirstList.map((item, index) => (
            <div
              key={index}
              className="second__left__details mt-[2rem] lg:ml-[10rem] mx-[2rem] lg:mr-[8rem] flex gap-x-4"
            >
              <div className="second__left__details__icon w-1/8">
                <img src={item.icon} alt={item.icon} />
              </div>
              <div className="second__left__details__description w-full font-landingPrimary">
                <p className="text-bodyb">{item.title}</p>
                <p className="text-bodyr text-neutrals-2">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="hero-section__second__right xl:w-1/2 w-full mt-[2rem]">
          <Swiper
            modules={[Autoplay, Scrollbar]}
            spaceBetween={50}
            scrollbar={{ draggable: true }}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            slidesPerView={1}
            className="xl:w-[30rem] w-3/4"
          >
            {imageSlides.map((slide, index) => (
              <SwiperSlide key={index}>
                <img
                  src={slide.src}
                  alt={slide.alt}
                  className="w-full h-full"
                />
                <p className="text-center text-h4m text-primary-5 font-landingPrimary mt-4">
                  {slide.title}
                </p>
                <p className="text-center text-bodyr text-neutrals-2">
                  {slide.description}
                </p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default AboutSection
