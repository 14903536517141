import React, { useState } from 'react'
import { PracticeType, Type } from '../../models/QuestionModels'
import { Link } from 'react-router-dom'
import WeeklyPredictionSaveAsSvg from '../../images/WeeklyPrediction/weekly-prediction-save-as.svg'
import WeeklyPredictionEditSvg from '../../images/WeeklyPrediction/weekly-prediction-edit.svg'
import WeeklyPredictionMarkSvg from '../../images/WeeklyPrediction/weekly-prediction-mark.svg'

interface AnswerShortQuestion {
  id: string
  question: string
  answer: string
  description: string
  practiceType: PracticeType
  type: Type
}

const questions: AnswerShortQuestion[] = [
  {
    id: '#0001',
    question: 'Q: What type of plant is mint?',
    answer: 'A: Herb',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.AllQuestions,
    type: Type.Easy,
  },
  {
    id: '#0002',
    question: 'Q: What type of plant is mint?',
    answer: 'A: Mentha',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.AttemptedQuestions,
    type: Type.Medium,
  },
  {
    id: '#0003',
    question: 'Q: What type of plant is mint?',
    answer: 'A: Mentha',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.NotAttemptedQuestions,
    type: Type.Hard,
  },
  {
    id: '#0004',
    question: 'Q: What type of plant is mint?',
    answer: 'A: Mentha',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.AllQuestions,
    type: Type.Hard,
  },
  {
    id: '#0005',
    question: 'Q: What type of plant is mint?',
    answer: 'A: Mentha',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.AttemptedQuestions,
    type: Type.Easy,
  },
  {
    id: '#0006',
    question: 'Q: What type of plant is mint?',
    answer: 'A: Mentha',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.NotAttemptedQuestions,
    type: Type.Medium,
  },
  {
    id: '#0007',
    question: 'Q: What type of plant is mint?',
    answer: 'A: Mentha',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.AttemptedQuestions,
    type: Type.Medium,
  },
  {
    id: '#0008',
    question: 'Q: What type of plant is mint?',
    answer: 'A: Mentha',
    description:
      'This question has been practiced 398918 time(s) by other students',
    practiceType: PracticeType.NotAttemptedQuestions,
    type: Type.Hard,
  },
]

const AnswerShortQuestionList: React.FC = () => {
  const [visibleQuestions, setVisibleQuestions] = useState(6)
  const [hoveredQuestion, setHoveredQuestion] =
    useState<AnswerShortQuestion | null>(null)

  const showMoreQuestions = () => {
    setVisibleQuestions((prev) => prev + 8)
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 min-[2000px]:grid-cols-3 gap-8">
      {questions.slice(0, visibleQuestions).map((question, index) => (
        <Link
          to="/weekly-prediction/speaking/answer-short-question"
          key={index}
          className="col-span-1 cursor-pointer rounded-lg hover:shadow-lg"
          onMouseEnter={() => setHoveredQuestion(question)}
          onMouseLeave={() => setHoveredQuestion(null)}
        >
          <div className="border border-[1px]-[#D9D9D9] p-4 rounded-lg">
            <div
              className={`min-h-[300px] content-center px-8 rounded-lg
            ${
              index % 5 === 0
                ? 'bg-info'
                : index % 5 === 1
                  ? 'bg-success'
                  : index % 5 === 2
                    ? 'bg-warning'
                    : index % 5 === 3
                      ? 'bg-danger'
                      : 'bg-[#722ED1]'
            }
            `}
            >
              <p className="text-h1 text-white">{question.question}</p>
              {hoveredQuestion === question && (
                <p className="text-h1 text-white pt-2">{question.answer}</p>
              )}
            </div>

            <div className="flex text-footnote pt-2">
              <p className=" text-white bg-[#1890FF] px-2 mr-2 rounded-full">
                {question.practiceType}
              </p>
              <p className="text-white bg-danger px-2 mr-2 rounded-full">
                {question.type}
              </p>
              <p className="text-neutrals-2 bg-[#F0F0F0] px-2 mr-2 rounded-full">
                Sort By
              </p>
              <p className="text-neutrals-2">{question.id}</p>
            </div>
            <p className="text-footnote text-[#8C8C8C] pt-2">
              {question.description}
            </p>
            <div className="flex justify-end mt-2">
              <img
                src={WeeklyPredictionSaveAsSvg}
                alt="save"
                className="mr-2 hover:scale-105"
              />
              <img
                src={WeeklyPredictionEditSvg}
                alt="edit"
                className="mr-2 hover:scale-105"
              />
              <img
                src={WeeklyPredictionMarkSvg}
                alt="mark"
                className="hover:scale-105"
              />
            </div>
          </div>
        </Link>
      ))}
      {visibleQuestions < questions.length && (
        <button
          onClick={showMoreQuestions}
          className="w-full col-span-full text-bodyr text-white py-2 rounded-sm"
          style={{
            background: 'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
          }}
        >
          Show More...
        </button>
      )}
    </div>
  )
}

export default AnswerShortQuestionList
